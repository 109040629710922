import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import {
	IconBuildingSkyscraper,
	IconFileInvoice,
	IconPuzzle,
	IconUsers,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BrandSettingsIcon } from '../../Common/images/BrandSettings.svg';
import { ReactComponent as InvoiceIcon } from '../../Common/images/invoices.svg';
import { ReactComponent as EditIcon } from '../../Common/images/UserEdit.svg';
import Translations from '../../Common/Translate.utils';
import { showDiamondFeaturePopup } from '../../Containers/Routes/Routes.reducers';
import { RootState } from '../../store';
import AccountInfoDialog from '../AccountInfoDialog/AccountInfoDialog';
import BillingDialog from '../BillingDialog/BillingDialog';
import BrandingDialog from '../BrandingDialog/BrandingDialog';
import DiamondIcon from '../DiamondIcon/DiamondIcon';
import InvoicesDialog from '../InvoicesDialog/InvoicesDialog';
import ProfileDialog from '../ProfileDialog/ProfileDialog';
import SetupIntegrationDialog from '../SetupIntegrationDialog/SetupIntegrationDialog';
import TeamManagementDialog from '../TeamManagementDialog/TeamManagementDialog';
import styles from './AccountSettings.module.css';
import {
	AccountSettingsDialogReducerType,
	hideAccountSettingsPopup,
	showAccountSettingsPopup,
} from './AccountSettings.reducer';

const dialogSelector = (state: RootState): AccountSettingsDialogReducerType =>
	get(state, 'accountSettingsDialog') as AccountSettingsDialogReducerType;
const allowEditingBrandSelector = (state: RootState) =>
	get(state, 'routerContainer.currentFloSpace.flospacePreference.brandingEnabled');

const AccountSettingsDialog = () => {
	const {
		PROFILE_ACCOUNT_SETTINGS,
		PROFILE_POPUP_TITLE_2_TEXT,
		PROFILE_POPUP_TITLE_TEXT,
		PROFILE_TEAM_TITLE_TEXT,
		PROFILE_SUBSCRIPTION_TITLE_TEXT,
		PROFILE_INVOICES_TITLE_TEXT,
		PROFILE_ACCOUNT_INFO_TITLE_TEXT,
		PROFILE_SETUP_INTEGRATION_TITLE_TEXT,
	} = Translations;
	const { visible, floId, defaultActiveTab, onClose } = useSelector(dialogSelector);
	const allowEditingBrand = useSelector(allowEditingBrandSelector);
	const [activeTab, setActiveTab] = useState<Number>(1);
	const canUserUpdateSettings = useSelector((state) =>
		get(state, 'routerContainer.currentFloSpace.canUserUpdateSettings', false)
	);
	const diamondFeaturesMap = useSelector((state) =>
		get(state, 'routerContainer.diamondFeaturesMap')
	);
	const brandingDiamondFeature = get(diamondFeaturesMap, 'branding');
	const scriptConfiguration = get(diamondFeaturesMap, 'script_share');

	const dispatch = useDispatch();

	const handleClose = useCallback(() => {
		onClose?.();
		dispatch(hideAccountSettingsPopup(''));
	}, [floId, onClose]);

	const handleOnTabClick = useCallback(
		(tabVal: number, e?: React.MouseEvent | React.KeyboardEvent) => {
			if (tabVal == 7 && get(scriptConfiguration, 'diamondEnabled')) {
				dispatch(
					showDiamondFeaturePopup({
						featureInfo: scriptConfiguration,
						buttonClicked: 'SCRIPT_CONFIGURE_BTN_CLICKED',
					})
				);
			} else {
				e?.preventDefault();
				setActiveTab(tabVal);
			}
		},
		[activeTab, floId, scriptConfiguration]
	);

	useEffect(() => {
		if (defaultActiveTab ?? false) {
			handleOnTabClick(defaultActiveTab);
			dispatch(
				showAccountSettingsPopup({
					visible: true,
					onClose,
					defaultActiveTab: null,
				})
			);
		}
	}, [defaultActiveTab, handleOnTabClick]);

	const brandingButtonClick = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			if (get(brandingDiamondFeature, 'diamondEnabled')) {
				dispatch(
					showDiamondFeaturePopup({
						featureInfo: brandingDiamondFeature,
						buttonClicked: 'BRANDING_BTN_CLICKED',
					})
				);
			} else {
				handleOnTabClick(2, e);
			}
		},
		[handleOnTabClick, brandingDiamondFeature]
	);

	if (!visible) return <span />;

	return (
		<Dialog
			open={visible}
			onClose={handleClose}
			classes={{
				container: styles.dialogContainer,
				paper: styles.dialogWrapper,
				root: styles.dialogRoot,
			}}
		>
			<Grid
				container
				className={styles.cardBody}
				justifyContent="center"
				alignItems="flex-start"
			>
				<Grid item className={styles.sidebar}>
					<Typography variant="h2" className={styles.heading}>
						{PROFILE_ACCOUNT_SETTINGS}
					</Typography>
					<Box>
						<Button
							className={classNames({
								[styles.btn]: true,
								[styles.active]: activeTab === 1,
							})}
							onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
								handleOnTabClick(1, e)
							}
						>
							<EditIcon className={styles.btnIcon} />
							{PROFILE_POPUP_TITLE_TEXT}
						</Button>
						<Button
							className={classNames({
								[styles.btn]: true,
								[styles.active]: activeTab === 6,
							})}
							onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
								handleOnTabClick(6, e)
							}
						>
							<IconBuildingSkyscraper size={16} className={styles.teamIcon} />
							{PROFILE_ACCOUNT_INFO_TITLE_TEXT}
						</Button>
						{/*{canUserUpdateSettings && (*/}
						<Fragment>
							<div className={styles.hr}></div>
							{allowEditingBrand && (
								<Button
									className={classNames({
										[styles.btn]: true,
										[styles.brandingBtnWithDiamond]: get(
											brandingDiamondFeature,
											'diamondEnabled'
										),
										[styles.active]: activeTab === 2,
									})}
									onClick={brandingButtonClick}
								>
									<BrandSettingsIcon className={styles.btnIcon} />
									{PROFILE_POPUP_TITLE_2_TEXT}
									{get(brandingDiamondFeature, 'diamondEnabled') && (
										<div className={styles.diamondIconWrapper}>
											<DiamondIcon image={get(brandingDiamondFeature, 'diamondImage')} />
										</div>
									)}
								</Button>
							)}
							<Button
								className={classNames({
									[styles.btn]: true,
									[styles.active]: activeTab === 3,
								})}
								onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
									handleOnTabClick(3, e)
								}
							>
								<IconUsers className={styles.teamIcon} size={16} />
								{PROFILE_TEAM_TITLE_TEXT}
							</Button>
							<Button
								className={classNames({
									[styles.btn]: true,
									[styles.active]: activeTab === 7,
								})}
								onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
									handleOnTabClick(7, e)
								}
							>
								<IconPuzzle className={styles.teamIcon} size={16} />
								{PROFILE_SETUP_INTEGRATION_TITLE_TEXT}
								{get(scriptConfiguration, 'diamondEnabled') && (
									<div className={styles.diamondIconWrapper}>
										<DiamondIcon image={get(brandingDiamondFeature, 'diamondImage')} />
									</div>
								)}
							</Button>
							<Button
								className={classNames({
									[styles.btn]: true,
									[styles.active]: activeTab === 4,
								})}
								onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
									handleOnTabClick(4, e)
								}
							>
								<IconFileInvoice className={styles.billingIcon} />
								{PROFILE_SUBSCRIPTION_TITLE_TEXT}
							</Button>
							<Button
								className={classNames({
									[styles.btn]: true,
									[styles.active]: activeTab === 5,
								})}
								onClick={(e: React.MouseEvent | React.KeyboardEvent) =>
									handleOnTabClick(5, e)
								}
							>
								<InvoiceIcon className={styles.billingIcon} />
								{PROFILE_INVOICES_TITLE_TEXT}
							</Button>
						</Fragment>
						{/*)}*/}
					</Box>
				</Grid>
				<Grid item className={styles.rightSection}>
					{activeTab === 1 && <ProfileDialog />}
					{/*{canUserUpdateSettings && (*/}
					<Fragment>
						{activeTab === 2 && <BrandingDialog />}
						{activeTab === 3 && <TeamManagementDialog />}
						{activeTab === 4 && <BillingDialog />}
						{activeTab === 5 && <InvoicesDialog />}
						{activeTab === 6 && <AccountInfoDialog />}
						{activeTab === 7 && <SetupIntegrationDialog />}
					</Fragment>
					{/*)}*/}
				</Grid>
			</Grid>
		</Dialog>
	);
};

export default AccountSettingsDialog;
