import { Box, Button, Tooltip } from '@mui/material';
import {
	IconCheck as ConfirmSvg,
	IconCopy,
	IconInfoCircleFilled,
	IconPlus,
	IconTrash,
	IconX as CancelSvg,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { get, noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { END_USER_SCRIPT_SRC } from '../../Common/Common.env';
import Translations from '../../Common/Translate.utils';
import {
	addDomainForScript,
	deleteDomainForScript,
	updateDomainForScript,
} from '../../Containers/Routes/Routes.reducers';
import { DomainsListItemType } from '../../Containers/Routes/Routes.types';
import { showDialogAction } from '../Dialog/Dialog.reducer';
import { setNotification } from '../Notification/Toasts.reducers';
import styles from './SetupIntegrationDialog.module.css';

const DomainListItem = ({
	data,
	deleteDomain,
	updateDomain,
}: {
	data: DomainsListItemType;
	deleteDomain: (domainItem: DomainsListItemType) => void;
	updateDomain: (
		domainItem: DomainsListItemType,
		newDomain: string,
		cb: () => void
	) => void;
}) => {
	const [domain, setDomain] = useState<string>('');
	const [isEdited, setEdited] = useState(false);

	useEffect(() => {
		setDomain(get(data, 'domain'));
	}, [setDomain, get(data, 'domain')]);

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setDomain(e.target.value);
			setEdited(true);
		},
		[setDomain, setEdited]
	);

	const onApply = useCallback(() => {
		updateDomain(data, domain, () => {
			setEdited(false);
		});
	}, [setEdited, updateDomain, domain, data]);

	const onCancel = useCallback(() => {
		setDomain(get(data, 'domain'));
		setEdited(false);
	}, [setEdited, setDomain, data]);

	return (
		<li
			className={classNames(styles.domainsListItem, { [styles.isEdited]: isEdited })}
			key={get(data, 'domainId')}
		>
			<input className={styles.domainInput} value={domain} onChange={onChange} />
			{isEdited ? (
				<>
					<Button
						className={classNames(styles.domainActionButton, styles.applyButton)}
						onClick={onApply}
					>
						<ConfirmSvg size={16} className={styles.domainsListItemIcon}></ConfirmSvg>
					</Button>
					<Button
						className={classNames(styles.domainActionButton, styles.cancelButton)}
						onClick={onCancel}
					>
						<CancelSvg size={16} className={styles.domainsListItemIcon}></CancelSvg>
					</Button>
				</>
			) : (
				<Button className={styles.domainActionButton} onClick={() => deleteDomain(data)}>
					<IconTrash size={16} className={styles.domainsListItemIcon}></IconTrash>
				</Button>
			)}
		</li>
	);
};

const SetupIntegrationDialog = () => {
	const dispatch = useDispatch();

	const {
		PROFILE_SETUP_INTEGRATION_COPY_TOAST_MESSAGE,
		DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_TITLE_TEXT,
		DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BODY_TEXT,
		DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BUTTON_TEXT,
	} = Translations;
	const flospaceInfo = useSelector((state) =>
		get(state, 'routerContainer.currentFloSpace')
	);
	const allowedDomains = get(
		flospaceInfo,
		'flospaceIntegrationScript.allowedDomains',
		[]
	);
	const [newDomain, setNewDomain] = useState<string>('');
	const newDomainChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setNewDomain(e.target.value);
		},
		[setNewDomain]
	);

	const addDomainClick = useCallback(() => {
		dispatch(
			addDomainForScript({
				domain: newDomain,
				cb: () => {
					setNewDomain('');
				},
			})
		);
	}, [newDomain, setNewDomain]);

	const deleteDomain = useCallback((domain: DomainsListItemType) => {
		dispatch(
			showDialogAction({
				variant: 'small',
				header: DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_TITLE_TEXT,
				body: DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BODY_TEXT,
				showCancel: false,
				showConfirm: true,
				confirmButtonType: 'delete',
				confirmButtonText: DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BUTTON_TEXT,
				onConfirm: () => {
					dispatch(deleteDomainForScript(domain));
				},
			})
		);
	}, []);

	const updateDomain = useCallback(
		(domainItem: DomainsListItemType, domainValue: string, cb: () => void) => {
			if (!domainValue) {
				deleteDomain(domainItem);
				cb();
			} else {
				dispatch(
					updateDomainForScript({
						...domainItem,
						domain: domainValue,
						cb,
					})
				);
			}
		},
		[deleteDomain]
	);

	const loader =
		useSelector((state) => get(state, 'loaders.setupIntegrationDialog', 0) > 0) || true;

	const scriptTag = `<script defer="defer" src="${END_USER_SCRIPT_SRC}" id="${get(
		flospaceInfo,
		'id'
	)}"></script>`;

	const onCopyClick = useCallback(() => {
		navigator.clipboard.writeText(scriptTag);
		dispatch(
			setNotification({
				message: PROFILE_SETUP_INTEGRATION_COPY_TOAST_MESSAGE,
				canShow: true,
				type: 'success',
			})
		);
	}, [scriptTag]);

	const {
		ACCOUNT_SETUP_INTEGRATION_SCRIPT_TITLE_TEXT,
		ACCOUNT_SETUP_INTEGRATION_DOMAIN_TITLE_TEXT,
		PROFILE_SETUP_INTEGRATION_TITLE_TEXT,
		ACCOUNT_SETUP_INTEGRATION_DESCRIPTION,
	} = Translations;

	const domainsEmpty = allowedDomains.length === 0;
	return (
		<div className={styles.wrapper}>
			<Box id="responsive-dialog-title" className={styles.title}>
				{PROFILE_SETUP_INTEGRATION_TITLE_TEXT}
			</Box>
			<div className={styles.content}>
				<Box className={styles.descriptionWrapper}>
					<IconInfoCircleFilled size={26} className={styles.infoIcon} />
					<div className={styles.description}>
						{ACCOUNT_SETUP_INTEGRATION_DESCRIPTION}
					</div>
				</Box>
				<Box className={styles.sectionWrapper}>
					<Box className={styles.sectionTitle}>
						{ACCOUNT_SETUP_INTEGRATION_DOMAIN_TITLE_TEXT}
					</Box>
					<ul className={styles.domainsList}>
						{allowedDomains.map((domainItem: DomainsListItemType) => (
							<DomainListItem
								key={get(domainItem, 'domainId')}
								data={domainItem}
								updateDomain={updateDomain}
								deleteDomain={deleteDomain}
							/>
						))}
						<li
							className={classNames(styles.domainsListItem, {
								[styles.disabledDomainsListItem]: newDomain.length === 0,
							})}
						>
							<input
								className={styles.domainInput}
								value={newDomain}
								onChange={newDomainChange}
								placeholder="Enter Domain"
							/>
							<Button
								className={styles.domainActionButton}
								onClick={newDomain.length === 0 ? noop : addDomainClick}
							>
								<IconPlus size={16}></IconPlus>
							</Button>
						</li>
					</ul>
				</Box>
				<Box className={styles.sectionWrapper}>
					<Box className={styles.sectionTitle}>
						{ACCOUNT_SETUP_INTEGRATION_SCRIPT_TITLE_TEXT}
					</Box>
					<div className={styles.scriptTagWrapper}>
						<div className={styles.scriptTag}>{scriptTag}</div>
						<Tooltip
							title={domainsEmpty ? 'Add atleast one domain to copy script' : ''}
							arrow
							classes={{
								popper: styles.tooltip,
							}}
						>
							<Button
								className={classNames(styles.copyButton, {
									[styles.disabled]: domainsEmpty,
								})}
								onClick={domainsEmpty ? noop : onCopyClick}
							>
								<IconCopy size={18}></IconCopy>
							</Button>
						</Tooltip>
					</div>
				</Box>
			</div>
			{/*{*/}
			{/*	loader && <Loader nodelay wrapperHeight={411} wrapperWidth={718} wrapperClass={styles.loader}/>*/}
			{/*}*/}
		</div>
	);
};

export default SetupIntegrationDialog;
