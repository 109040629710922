import { max } from 'lodash';
import videojs from 'video.js';

const defaults = {};
const imageHalfWidth = 80;
// Cross-compatibility for Video.js 5 and 6.
const registerPlugin = videojs.registerPlugin || videojs.plugin;

/**
 * Function to invoke when the player is ready.
 *
 * This is a great place for your plugin to initialize itself. When this
 * function is called, the player will have its DOM and child components
 * in place.
 *
 * @function onPlayerReady
 * @param    {Player} player
 *           A Video.js player object.
 *
 * @param    {Object} [options={}]
 *           A plain object containing options for the plugin.
 */
// @ts-ignore
const onPlayerReady = (player, options) => {
	player.addClass('vjs-vtt-thumbnails');
	player.vttThumbnails = new vttThumbnailsPlugin(player, options);
};

/**
 * A video.js plugin.
 *
 * In the plugin function, the value of `this` is a video.js `Player`
 * instance. You cannot rely on the player being in a "ready" state here,
 * depending on how the plugin is invoked. This may or may not be important
 * to you; if not, remove the wait for "ready"!
 *
 * @function vttThumbnails
 * @param    {Object} [options={}]
 *           An object of options left to the plugin author to define.
 */
// @ts-ignore
const vttThumbnails = function (options) {
	// @ts-ignore
	this.ready(() => {
		// @ts-ignore
		onPlayerReady(this, videojs.mergeOptions(defaults, options));
	});
};

/**
 * VTT Thumbnails class.
 *
 * This class performs all functions related to displaying the vtt
 * thumbnails.
 */
class vttThumbnailsPlugin {
	/**
	 * Plugin class constructor, called by videojs on
	 * ready event.
	 *
	 * @function  constructor
	 * @param    {Player} player
	 *           A Video.js player object.
	 *
	 * @param    {Object} [options={}]
	 *           A plain object containing options for the plugin.
	 */
	// @ts-ignore
	constructor(player, options) {
		// @ts-ignore
		this.vPlayer = player;
		// @ts-ignore
		this.options = options;
		this.listenForDurationChange();
		this.initializeThumbnails();
		// @ts-ignore
		this.registeredEvents = {};
		return this;
	}

	// @ts-ignore
	src(source) {
		this.resetPlugin();
		// @ts-ignore
		this.options.src = source;
		this.initializeThumbnails();
	}

	detach() {
		this.resetPlugin();
	}

	resetPlugin() {
		// @ts-ignore
		this.thumbnailHolder &&
			// @ts-ignore
			this.thumbnailHolder.parentNode.removeChild(this.thumbnailHolder);
		// @ts-ignore
		this.progressBar &&
			// @ts-ignore
			this.progressBar.removeEventListener(
				'mouseenter',
				// @ts-ignore
				this.registeredEvents.progressBarMouseEnter
			);
		// @ts-ignore
		this.progressBar &&
			// @ts-ignore
			this.progressBar.removeEventListener(
				'mouseleave',
				// @ts-ignore
				this.registeredEvents.progressBarMouseLeave
			);
		// @ts-ignore
		this.progressBar &&
			// @ts-ignore
			this.progressBar.removeEventListener(
				'mousemove',
				// @ts-ignore
				this.registeredEvents.progressBarMouseMove
			);
		// @ts-ignore
		delete this.registeredEvents.progressBarMouseEnter;
		// @ts-ignore
		delete this.registeredEvents.progressBarMouseLeave;
		// @ts-ignore
		delete this.registeredEvents.progressBarMouseMove;
		// @ts-ignore
		delete this.progressBar;
		// @ts-ignore
		delete this.vttData;
		// @ts-ignore
		delete this.thumbnailHolder;
		// @ts-ignore
		delete this.lastStyle;
	}

	listenForDurationChange() {
		// @ts-ignore
		this.vPlayer.on('durationchange', () => {});
	}

	/**
	 * Bootstrap the plugin.
	 */
	initializeThumbnails() {
		// @ts-ignore
		if (!this.options.src) {
			return;
		}
		const baseUrl = this.getBaseUrl();
		// @ts-ignore
		const url = this.getFullyQualifiedUrl(this.options.src, baseUrl);
		this.getVttFile(url).then((data) => {
			// @ts-ignore
			this.vttData = this.processVtt(data);
			// @ts-ignore
			this.setupThumbnailElement();
		});
	}

	/**
	 * Builds a base URL should we require one.
	 *
	 * @returns {string}
	 */
	getBaseUrl() {
		return [
			window.location.protocol,
			'//',
			window.location.hostname,
			window.location.port ? ':' + window.location.port : '',
			window.location.pathname,
		]
			.join('')
			.split(/([^\/]*)$/gi)
			.shift();
	}

	/**
	 * Grabs the contents of the VTT file.
	 *
	 * @param url
	 * @returns {Promise}
	 */
	// @ts-ignore
	getVttFile(url) {
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();
			req.withCredentials = process.env.REACT_APP_ENABLE_HLS_CREDENTIALS === 'true';
			// @ts-ignore
			req.data = {
				resolve: resolve,
			};
			req.addEventListener('load', this.vttFileLoaded);
			req.open('GET', url);
			req.send();
		});
	}

	/**
	 * Callback for loaded VTT file.
	 */
	vttFileLoaded() {
		// @ts-ignore
		this.data.resolve(this.responseText);
	}

	// @ts-ignore
	setupThumbnailElement(data) {
		// @ts-ignore
		const mouseDisplay = this.vPlayer.$('.vjs-mouse-display');
		// @ts-ignore
		this.progressBar = this.vPlayer.$('.vjs-progress-control');
		const thumbHolder = document.createElement('div');
		thumbHolder.setAttribute('class', 'vjs-vtt-thumbnail-display');
		// @ts-ignore
		this.progressBar.appendChild(thumbHolder);
		// @ts-ignore
		this.thumbnailHolder = thumbHolder;
		if (mouseDisplay) {
			mouseDisplay.classList.add('vjs-hidden');
		}
		// @ts-ignore
		this.registeredEvents.progressBarMouseEnter = () => {
			return this.onBarMouseenter();
		};
		// @ts-ignore
		this.registeredEvents.progressBarMouseLeave = () => {
			return this.onBarMouseleave();
		};
		// @ts-ignore
		this.progressBar.addEventListener(
			'mouseenter',
			// @ts-ignore
			this.registeredEvents.progressBarMouseEnter
		);
		// @ts-ignore
		this.progressBar.addEventListener(
			'mouseleave',
			// @ts-ignore
			this.registeredEvents.progressBarMouseLeave
		);
	}

	onBarMouseenter() {
		// @ts-ignore
		this.mouseMoveCallback = (e) => {
			this.onBarMousemove(e);
		};
		// @ts-ignore
		this.registeredEvents.progressBarMouseMove = this.mouseMoveCallback;
		// @ts-ignore
		this.progressBar.addEventListener(
			'mousemove',
			// @ts-ignore
			this.registeredEvents.progressBarMouseMove
		);
		this.showThumbnailHolder();
	}

	onBarMouseleave() {
		// @ts-ignore
		if (this.registeredEvents.progressBarMouseMove) {
			// @ts-ignore
			this.progressBar.removeEventListener(
				'mousemove',
				// @ts-ignore
				this.registeredEvents.progressBarMouseMove
			);
		}
		this.hideThumbnailHolder();
	}

	// @ts-ignore

	getXCoord(bar, mouseX) {
		const rect = bar.getBoundingClientRect();
		const docEl = document.documentElement;
		return max([0, mouseX - (rect.left + (window.pageXOffset || docEl.scrollLeft || 0))]);
	}

	// @ts-ignore

	onBarMousemove(event) {
		this.updateThumbnailStyle(
			// @ts-ignore
			this.getXCoord(this.progressBar, event.clientX),
			// @ts-ignore
			this.progressBar.offsetWidth
		);
	}

	// @ts-ignore
	getStyleForTime(time) {
		// @ts-ignore
		for (let i = 0; i < this.vttData.length; ++i) {
			// @ts-ignore
			let item = this.vttData[i];
			if (time >= item.start && time < item.end) {
				return item.css;
			}
		}
		// @ts-ignore
		const duration = this.vPlayer.duration();
		if (time > duration * 0.8 && time <= duration) {
			try {
				// @ts-ignore
				return this.vttData[this.vttData.length - 1].css;
			} catch (e) {
				console.warn(e);
			}
		}
	}

	showThumbnailHolder() {
		// @ts-ignore
		this.thumbnailHolder.style.opacity = '1';
	}

	hideThumbnailHolder() {
		// @ts-ignore
		this.thumbnailHolder.style.opacity = '0';
	}

	// @ts-ignore
	updateThumbnailStyle(x, width) {
		// @ts-ignore
		const duration = this.vPlayer.duration();
		const time = (1 - (width - x) / width) * duration;
		const currentStyle = this.getStyleForTime(time);
		if (!currentStyle) {
			return this.hideThumbnailHolder();
		}

		const xPos = (1 - (width - x) / width) * width;
		const nearRightEdge = xPos - imageHalfWidth > 0;
		const nearToLeftEdge = xPos > width - imageHalfWidth;
		const positionIfCloserToRight = nearToLeftEdge ? width - imageHalfWidth : xPos;
		const pos = nearRightEdge ? positionIfCloserToRight : imageHalfWidth;
		// @ts-ignore
		this.thumbnailHolder.style.transform = 'translateX(' + pos + 'px)';
		// @ts-ignore
		this.thumbnailHolder.style.marginLeft = '-' + parseInt(currentStyle.width) / 2 + 'px';

		// @ts-ignore
		if (this.lastStyle && this.lastStyle === currentStyle) {
			return;
		}
		// @ts-ignore
		this.lastStyle = currentStyle;

		for (let style in currentStyle) {
			if (currentStyle.hasOwnProperty(style)) {
				// @ts-ignore
				this.thumbnailHolder.style[style] = currentStyle[style];
			}
		}
	}

	// @ts-ignore
	processVtt(data) {
		// @ts-ignore
		const processedVtts = [];
		const vttDefinitions = data.split(/[\r\n][\r\n]/i);
		// @ts-ignore
		vttDefinitions.forEach((vttDef) => {
			if (
				vttDef.match(
					/([0-9]{2}:)?([0-9]{2}:)?[0-9]{2}(.[0-9]{3})?( ?--> ?)([0-9]{2}:)?([0-9]{2}:)?[0-9]{2}(.[0-9]{3})?[\r\n]{1}.*/gi
				)
			) {
				let vttDefSplit = vttDef.split(/[\r\n]/i);
				let vttTiming = vttDefSplit[0];
				let vttTimingSplit = vttTiming.split(/ ?--> ?/i);
				let vttTimeStart = vttTimingSplit[0];
				let vttTimeEnd = vttTimingSplit[1];
				let vttImageDef = vttDefSplit[1];
				let vttCssDef = this.getVttCss(vttImageDef);

				processedVtts.push({
					start: this.getSecondsFromTimestamp(vttTimeStart),
					end: this.getSecondsFromTimestamp(vttTimeEnd),
					css: vttCssDef,
				});
			}
		});
		// @ts-ignore
		return processedVtts;
	}

	// @ts-ignore
	getFullyQualifiedUrl(path, base) {
		if (path.indexOf('//') >= 0) {
			// We have a fully qualified path.
			return path;
		}
		if (base.indexOf('//') === 0) {
			// We don't have a fully qualified path, but need to
			// be careful with trimming.
			return [base.replace(/\/$/gi, ''), this.trim(path, '/')].join('/');
		}
		if (base.indexOf('//') > 0) {
			// We don't have a fully qualified path, and should
			// trim both sides of base and path.
			return [this.trim(base, '/'), this.trim(path, '/')].join('/');
		}

		// If all else fails.
		return path;
	}

	// @ts-ignore
	getPropsFromDef(def) {
		const imageDefSplit = def.split(/#xywh=/i);
		const imageUrl = imageDefSplit[0];
		const imageCoords = imageDefSplit[1];
		const splitCoords = imageCoords.match(/[0-9]+/gi);
		return {
			x: splitCoords[0],
			y: splitCoords[1],
			w: splitCoords[2],
			h: splitCoords[3],
			image: imageUrl,
		};
	}

	// @ts-ignore
	getVttCss(vttImageDef) {
		const cssObj = {};

		// If there isn't a protocol, use the VTT source URL.
		let baseSplit;
		// @ts-ignore
		if (this.options.src.indexOf('//') >= 0) {
			// @ts-ignore
			baseSplit = this.options.src.split(/([^\/]*)$/gi).shift();
		} else {
			// @ts-ignore
			baseSplit = this.getBaseUrl() + this.options.src.split(/([^\/]*)$/gi).shift();
		}

		vttImageDef = this.getFullyQualifiedUrl(vttImageDef, baseSplit);

		if (!vttImageDef.match(/#xywh=/i)) {
			// @ts-ignore
			cssObj.backgroundImage = 'url("' + vttImageDef + '")';
			return cssObj;
		}

		const imageProps = this.getPropsFromDef(vttImageDef);
		// @ts-ignore
		cssObj.background =
			'url("' +
			imageProps.image +
			'") no-repeat -' +
			imageProps.x +
			'px -' +
			imageProps.y +
			'px';
		// @ts-ignore
		cssObj.width = imageProps.w + 'px';
		// @ts-ignore
		cssObj.height = imageProps.h + 'px';

		return cssObj;
	}

	// @ts-ignore
	doconstructTimestamp(timestamp) {
		const splitStampMilliseconds = (timestamp || '').split('.');
		const timeParts = splitStampMilliseconds[0] || '';
		const timePartsSplit = timeParts.split(':');
		return {
			milliseconds: parseInt(splitStampMilliseconds[1]) || 0,
			seconds: parseInt(timePartsSplit.pop()) || 0,
			minutes: parseInt(timePartsSplit.pop()) || 0,
			hours: parseInt(timePartsSplit.pop()) || 0,
		};
	}

	// @ts-ignore
	getSecondsFromTimestamp(timestamp) {
		const timestampParts = this.doconstructTimestamp(timestamp);
		return parseInt(
			// @ts-ignore
			timestampParts.hours * (60 * 60) +
				timestampParts.minutes * 60 +
				timestampParts.seconds +
				timestampParts.milliseconds / 1000,
			10
		);
	}

	// @ts-ignore
	trim(str, charlist) {
		let whitespace = [
			' ',
			'\n',
			'\r',
			'\t',
			'\f',
			'\x0b',
			'\xa0',
			'\u2000',
			'\u2001',
			'\u2002',
			'\u2003',
			'\u2004',
			'\u2005',
			'\u2006',
			'\u2007',
			'\u2008',
			'\u2009',
			'\u200a',
			'\u200b',
			'\u2028',
			'\u2029',
			'\u3000',
		].join('');
		let l = 0;
		let i = 0;
		str += '';
		if (charlist) {
			whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1');
		}
		l = str.length;
		for (i = 0; i < l; i++) {
			if (whitespace.indexOf(str.charAt(i)) === -1) {
				str = str.substring(i);
				break;
			}
		}
		l = str.length;
		for (i = l - 1; i >= 0; i--) {
			if (whitespace.indexOf(str.charAt(i)) === -1) {
				str = str.substring(0, i + 1);
				break;
			}
		}
		return whitespace.indexOf(str.charAt(0)) === -1 ? str : '';
	}
}

// Register the plugin with video.js.
registerPlugin('vttThumbnails', vttThumbnails);

export default vttThumbnails;
