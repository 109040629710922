export const appName = 'Floik';
export const cancelButtonText = 'Cancel';
export const confirmButtonText = 'Confirm';
export const defaultDropdownSelectText = 'Select';
export const page404ContentTitle = 'Ooops! You weren’t <br/> supposed to see this';
export const page404ContentText =
	'The page you are looking for no longer exist.<br/>Please refresh the page and remember: you haven’t seen anything';
export const page404HomeButton = 'Go to homepage';
export const SECURITY_PAGE_LOGIN_BTN = 'Secure your account';
export const SECURITY_PAGE_DESC =
	'We have detected unusual activity on your account. To maintain security, please review your recent activity';
export const SECURITY_PAGE_TITLE = 'Account Security Alert';
export const page500ContentTitle = 'Ooops! You weren’t <br/> supposed to see this';
export const page500ContentText =
	'The page you are looking for no longer exist.<br/>Please refresh the page and remember: you haven’t seen anything';
export const page500HomeButton = 'Go to homepage';
export const POP_UP_402_LOGGED_IN_USER_TITLE_TEXT = 'Upgrade your plan';
export const POP_UP_402_FINGER_PRINT_USER_SUB_TITLE_TEXT =
	'Oh ho! Looks like you have exhausted usage. Please login to unlock capabilities';
export const PAGE_400_CONTENT_TITLE = 'Oops there seems to be some issue.';
export const PAGE_403_CONTENT_TITLE =
	'Sorry, this Flo has either been deleted or your access has changed';
export const PAGE_403_CONTENT_TITLE_FLO =
	'Sorry, this Flo has either been deleted or your access has changed';
export const PAGE_403_CONTENT_TITLE_WORKSPACE =
	'Sorry, this Workspace has either been deleted or your access has changed';
export const PAGE_403_CONTENT_TITLE_INSIGHTS =
	'Sorry, this has either been deleted or your access has changed';
export const PAGE_403_REDIRECT_BTN_TEXT = 'Go to Home page';
export const PAGE_400_REDIRECT_BTN_TEXT = 'Go to Home page';
export const PAGE_403_REDIRECT_BTN_TEXT_FLO = 'Go to Home page';
export const PAGE_403_REDIRECT_BTN_TEXT_WORKSPACE = 'Go to Home page';
export const PAGE_403_REDIRECT_BTN_TEXT_INSIGHTS = 'Go to Home page';
export const HOME_PAGE_HEADER_TXT = 'Create Flo';
export const HEADER_CREATE_FLO_BUTTON_TOOLTIP = 'Create Flo';
export const HEADER_AVATAR_ICON_TOOLTIP = 'My account';
export const HEADER_LOGIN_BUTTON_TEXT = 'Login';
export const HEADER_LOGIN_BUTTON_TOOLTIP = 'Login';
export const CREATE_FLO_BTN_TEXT = 'Capture new recording';
export const MY_WORKSPACE_TITLE = 'My workspace';
export const INSIGHTS_PAGE_TITLE_TXT = 'Flo Insights';
export const INSIGHTS_TITLE = 'Insights';
export const INSIGHTS_TAB_1_TEXT = 'Basic Insights';
export const INSIGHTS_TAB_2_TEXT = 'Flo Insights';
export const INSIGHTS_EMPTY_TEXT =
	'Please publish a Flo to know CTA clicks, comments and views on your Flos';
export const PROFILE_MENU_EDIT_TEXT = 'Edit profile';
export const PROFILE_ACCOUNT_SETTINGS = 'Account settings';
export const PROFILE_MENU_LOGOUT_TEXT = 'Logout';
export const PROFILE_MENU_THEME_ICON_TEXT = 'Change theme';
export const PROFILE_POPUP_TITLE_TEXT = 'Personal info';
export const PROFILE_ACCOUNT_INFO_TITLE_TEXT = 'Account info';
export const PROFILE_BILLING_TITLE_TEXT = 'Billing';
export const PROFILE_SUBSCRIPTION_TITLE_TEXT = 'Subscription';
export const PROFILE_INVOICES_TITLE_TEXT = 'Invoices';
export const PROFILE_INVOICES_DOWNLOAD_BUTTON_TEXT = 'Invoice';
export const PROFILE_INVOICES_RECEIPT_DOWNLOAD_BUTTON_TEXT = 'Receipt';
export const PROFILE_TEAM_TITLE_TEXT = 'Team';
export const PROFILE_SETUP_INTEGRATION_TITLE_TEXT = 'Setup integration';
export const PROFILE_SETUP_INTEGRATION_COPY_TOAST_MESSAGE =
	'Script tag copied to clipboard.';
export const DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_TITLE_TEXT = 'Delete domain';
export const DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BODY_TEXT =
	'Are you sure you want to delete this domain?';
export const DELETE_SETUP_INTEGRATION_DOMAIN_POPUP_BUTTON_TEXT = 'Delete domain';
export const PROFILE_BILLING_PAYMENT_PENDING_TEXT = 'Waiting for the payment from bank';
export const PROFILE_BILLING_PAYMENT_FAILED_TEXT =
	'%UPGRADE% failed: Please try again  or contact support for assistance';
export const PROFILE_BILLING_PAYMENT_PAGE_BUTTON_TEXT = 'Go to payment page';
export const PROFILE_BILLING_RETRY_PAYMENT_BUTTON_TEXT = 'Retry payment';
export const PROFILE_BILLING_BEST_PLAN_TEXT = 'You are currently in the best plan!';
export const PROFILE_POPUP_LOGGED_IN_SUB_TITLE_TEXT = 'You are currently logged in as ';
export const PROFILE_POPUP_GUEST_SUB_TITLE_TEXT = 'You have not signed in';
export const PROFILE_POPUP_NAME_TITLE_TEXT = 'Name';
export const PROFILE_POPUP_INPUT_BOX_PLACEHOLDER_TEXT = 'Enter your name';
export const PROFILE_POPUP_AVATAR_TITLE_TEXT = 'Choose an avatar';
export const PROFILE_POPUP_CLOSE_BUTTON_TEXT = 'Close';
export const PROFILE_POPUP_SKIP_BUTTON_TEXT = 'Skip';
export const PROFILE_POPUP_SAVE_BUTTON_TEXT = 'Save changes';
export const PROFILE_POPUP_TITLE_2_TEXT = 'Branding';
export const ACCOUNT_NAME_TITLE_TEXT = 'Account owner details';
export const ACCOUNT_PLAN_NAME_TITLE_TEXT = 'Account plan';
export const ACCOUNT_CURRENT_USER_ROLE_TITLE = 'Your role';
export const ACCOUNT_SETUP_INTEGRATION_SCRIPT_TITLE_TEXT = 'Header script';
export const ACCOUNT_SETUP_INTEGRATION_DOMAIN_TITLE_TEXT = 'Domain accesses';
export const ACCOUNT_SETUP_INTEGRATION_DESCRIPTION =
	"To integrate the following script, simply copy and paste it into the header section of your website's HTML. This will allow you to enhance its functionality seamlessly.";
export const PROFILE_USER_ROLE_TITLE = 'Role';
export const PROFILE_BRANDING_LOGO_SUB_TXT = 'Customize your formats with your logo';
export const PROFILE_BRANDING_LOGO_TXT = 'Logo';
export const PROFILE_BRANDING_LOGO_REMOVE_TXT = 'Remove';
export const PROFILE_BRANDING_LOGO_TITLE_TXT = 'Select your logo';
export const PROFILE_BRANDING_LOGO_FORMAT_TXT = 'PNG . 250x150';
export const PROFILE_BRANDING_LOGO_TITLE_2_TXT = 'Customize your formats with your logo';
export const PROFILE_BRANDING_COLOR_TITLE_TXT = 'Color';
export const PROFILE_BRANDING_COLOR_SUB_TXT =
	'Set your brand color to personalise the end user view';
export const PROFILE_BRANDING_COLOR_PREVIEW_TXT = 'Preview';
export const PROFILE_FLOIK_BRANDING_TITLE_TXT = 'Floik branding';
export const PROFILE_FLOIK_BRANDING_DESC_TXT =
	'Toggle the visibility of "Powered by Floik" on your Flos';
export const PROFILE_FLOIK_BRANDING_TOGGLE_BTN_TXT = 'Show Powered by Floik';
export const PROFILE_BRANDING_LOGO_FILE_FORMAT_ERR_TXT = 'Only PNG file supported.';
export const PROFILE_INVOICES_COLUMN_1_HEADING = 'Date';
export const PROFILE_INVOICES_COLUMN_2_HEADING = 'Base plan';
export const PROFILE_INVOICES_COLUMN_3_HEADING = 'Amount paid';
export const WELCOME_PAGE_TITLE_TEXT = 'Floik';
export const WELCOME_PAGE_SUB_TITLE_TEXT = 'Welcome to Floik';
export const FLO_PAGE_EMPTY_SCREEN_TITLE_TEXT =
	'Create your first Flo and collaborate asynchronously!';
export const FLO_PUBLISH_BUTTON_TEXT = 'Publish now';
export const FLO_PUBLISHING_BUTTON_TEXT = 'Publishing';
export const EDIT_FLO_HEADER_GENERATE_BTN_TXT = 'Generate';
export const FLO_PAGE_EMPTY_SCREEN_SUB_TITLE_TEXT = '';
export const FLO_PAGE_EMPTY_SCREEN_CREATE_FLO_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Get faster and contextual feedback now!';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Get faster and contextual feedback now!';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_TITLE_TEXT = 'No shared Flos';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_CLOSED_TITLE_TEXT =
	'You dont have any closed Flos. \nContinue collaborating on your active Flos.';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_SUB_TITLE_TEXT =
	'Say bye to scheduling woes. Collaborate asynchronously.';
export const INBOX_DRAWER_ALL_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_MY_FLO_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_SHARED_WITH_ME_TAB_EMPTY_SCREEN_BUTTON_TEXT = 'Create Flo';
export const INBOX_DRAWER_TAB_1_TEXT = 'All Flos';
export const INBOX_DRAWER_TAB_2_TEXT = 'My Flos';
export const INBOX_DRAWER_TAB_3_TEXT = 'Shared with me';
export const INBOX_DRAWER_SHOW_CLOSED_FLO_TOGGLE_TEXT = 'Show closed Flos';
export const INBOX_FLO_COUNT = 'Total Flos: ';
export const INBOX_DRAWER_FLO_ROW_FILM_STRIP_ICON_TOOLTIP = 'Duration';
export const INBOX_DRAWER_FLO_ROW_CREATED_BY_COLUMN_TEXT = 'Created by';
export const INBOX_DRAWER_FLO_ROW_CREATED_ON_COLUMN_TEXT = 'Created on';
export const INBOX_DRAWER_FLO_ROW_COMMENT_COUNT_SINGULAR_ICON_TOOLTIP = 'comment added';
export const INBOX_DRAWER_FLO_ROW_COMMENT_COUNT_PLURAL_ICON_TOOLTIP = 'comments added';
export const INBOX_DRAWER_FLO_ROW_DECISION_COUNT_SINGULAR_ICON_TOOLTIP = 'decision made';
export const INBOX_DRAWER_FLO_ROW_DECISION_COUNT_PLURAL_ICON_TOOLTIP = 'decisions made';
export const INBOX_DRAWER_FLO_ROW_FLO_STATUS_COLUMN_TEXT = '';
export const INBOX_DRAWER_FLO_ROW_PRIVACY_ICON_TOOLTIP = '';
export const INBOX_DRAWER_FLO_ROW_SHARE_ICON_TOOLTIP = 'Share Flo';
export const INBOX_DRAWER_FLO_ROW_DELETE_ICON_TOOLTIP = 'Delete Flo';
export const INBOX_DRAWER_FLO_ROW_LEAVE_ICON_TOOLTIP = 'Leave Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_SETTINGS_TEXT = 'Settings';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_SHARE_FLO_TEXT = 'Share Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_DELETE_FLO_TEXT = 'Delete Flo';
export const INBOX_DRAWER_FLO_ROW_ACTION_MENU_LEAVE_FLO_TEXT = 'Leave Flo';
export const INBOX_DRAWER_PAGINATION_ICON_TEXT = 'Load more';
export const SHARE_POPUP_TITLE_TEXT = 'Share Flo';
export const SHARE_FOLDER_POPUP_TITLE_TEXT = 'Share Folder';
export const SHARE_POPUP_SUB_TITLE_TEXT = 'Public link';
export const SHARE_POPUP_SUB_HEADING_TEXT = 'Anyone with the link can view';

export const SHARE_POPUP_SKIP_INACTIVITY_LABEL = 'Swift Play';
export const SHARE_POPUP_SHARE_WITH_COLLABORATOR_TEXT = 'Share with collaborators';
export const SHARE_POPUP_SHARE_WITH_COLLABORATORS = 'Collaborators';
export const SHARE_POPUP_EMBED_SUBHEADING_TXT =
	'You can embed flos in hundreds of platforms!';

export const SHARE_POPUP_EMBED_CODE_TXT = 'Embed code';
export const SHARE_POPUP_COPY_LINK_BUTTON_TEXT = 'Copy link';
export const SHARE_POPUP_COPIED_LINK_BUTTON_TEXT = 'Copied';
export const SHARE_POPUP_SHARE_EMAIL_BUTTON_TEXT = 'Add';
export const SHARE_POPUP_EMAIL_DUPLICATE_WARNING_TEXT = 'This email is already added.';
export const SHARE_POPUP_USERS_LIST_HEADING = 'People with access';
export const SHARE_POPUP_PRIVATE_EMBED_WARNING =
	'Since the share option is set to private, the embedded flow will not be available for some users.';
export const SHARE_POPUP_SHARE_EMAIL_PLACEHOLDER_TEXT = 'Enter email...';
export const SHARE_POPUP_USER_REVOKE_BUTTON_TEXT = 'Revoke';
export const SHARE_POPUP_EMBED_COPIED_BUTTON_TEXT = 'Copied';
export const SHARE_POPUP_EMBED_BUTTON_TEXT2 = 'Copy code';
export const SHARE_POPUP_SHARE_SUB_TITLE_1_TEXT = 'Share settings';
export const SHARE_POPUP_PUBLIC_ACCESS_RADIO_BUTTON_TEXT =
	'Allow access to only those who have commented';
export const SHARE_POPUP_PRIVATE_FLO_RADIO_BUTTON_TEXT = 'Allow access only to me'; // Get rid of it;
export const SHARE_POPUP_SEND_LINK_BTN_TEXT = 'Share link';
export const SHARE_POPUP_SENDING_LINK_BTN_TEXT = 'Sending';
export const SHARE_POPUP_ADD_COLLAB_BTN_TEXT = 'Add';
export const SHARE_POPUP_ADDING_COLLAB_BTN_TEXT = 'Adding';
export const SHARE_POPUP_MAIL_INPUT_BOX_PLACEHOLDER_TEXT = 'Enter email addresses';
export const SHARE_POPUP_COLLABORATOR_PROFILE_TOOLTIP = 'Share Flo';
export const SHARE_POPUP_PRIVATE_FLO_TEXT =
	'*Your flo is private, use above settings to make it public';
export const SETTINGS_POPUP_TITLE_TEXT = 'Settings';
export const CLONE_FLO_POPUP_TITLE_TEXT = 'Clone Flo?';
export const CLONE_FLO_POPUP_SUB_TITLE_TEXT = 'Are you sure you want to clone this Flo?';
export const SETTINGS_POPUP_FLO_SUB_TITLE_1_TEXT = 'Flo status';
export const SETTINGS_POPUP_FLO_CLOSE_RADIO_BUTTON_TEXT = 'Mark Flo as closed';
export const DELETE_FLO_POPUP_TITLE_TEXT = 'Delete Flo?';
export const DELETE_FOLDER_POPUP_TITLE_TEXT = 'Delete folder?';
export const DELETE_FLO_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to delete this Flo permanently?';
export const MOVE_TO_POPUP_TITLE_TEXT = 'Move to';
export const MOVE_TO_POPUP_CONFIRM_BUTTON_TEXT = 'Move here';

export const DELETE_FOLDER_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to delete this Folder permanently?';
export const DELETE_FLO_POPUP_DELETE_FLO_BUTTON_TEXT = 'Delete Flo';
export const DELETE_FOLDER_POPUP_DELETE_FLO_BUTTON_TEXT = 'Delete folder';

export const DELETE_FLO_STEP_POPUP_TITLE_TEXT = 'Delete step?';
export const DELETE_FLO_STEP_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to delete this step permanently?';

export const DELETE_FLO_STEP_EXCEPTION_POPUP_TITLE_TEXT = 'Delete step?';
export const DELETE_FLO_STEP_EXCEPTION_POPUP_SUB_TITLE_TEXT =
	'The last item on the list cannot be deleted';

export const DELETE_FLO_STEP_POPUP_DELETE_FLO_BUTTON_TEXT = 'Delete Step';

export const FLO_STATUS_POPUP_CLOSED_TITLE_TEXT = 'Reopen this Flo for feedback';
export const FLO_STATUS_POPUP_ACTIVE_TITLE_TEXT = 'Close this Flo';
export const FLO_STATUS_POPUP_ACTIVE_BTN_TEXT = 'Confirm';
export const FLO_STATUS_POPUP_CLOSED_BTN_TEXT = 'Close Flo';
export const FLO_STATUS_POPUP_CLOSED_SUB_TITLE_TEXT =
	'Yay! Looks like you got all the feedback you were looking for. \n Closing this Flo will disable comment access for all collaborators. ';
export const FLO_STATUS_POPUP_ACTIVE_SUB_TITLE_TEXT =
	'Oh! Looks like this needs some more discussion. \nChanging status of this Flo to Active state will enable comment access for all collaborators.';
export const LEAVE_FLO_POPUP_TITLE_TEXT = 'Leave Flo?';
export const UNPUBLISH_FLO_POPUP_TITLE_TEXT = 'Unpublish Flo?';
export const UNPUBLISH_FLO_POPUP_FLO_BUTTON_TEXT = 'Unpublish Flo';
export const UNPUBLISH_FLO_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to unpublish this Flo?';
export const LEAVE_FOLDER_POPUP_TITLE_TEXT = 'Leave Folder?';
export const LEAVE_FLO_POPUP_SUB_TITLE_TEXT = 'Are you sure you want to leave this Flo?';
export const LEAVE_FOLDER_POPUP_SUB_TITLE_TEXT =
	'Are you sure you want to leave this Folder?';
export const LEAVE_FLO_POPUP_LEAVE_FLO_BUTTON_TEXT = 'Leave Flo';
export const LEAVE_FOLDER_POPUP_LEAVE_FLO_BUTTON_TEXT = 'Leave Folder';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_INACTIVE_ANNOTATION_ICON_TOOLTIP =
	'Click to add Annotations';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_ACTIVE_ANNOTATION_ICON_TOOLTIP =
	'Click to hide annotations';
export const FLO_PAGE_TOOL_BAR_MORE_OPTIONS_TOOLTIP = 'More options';
export const FLO_PAGE_TOOL_BAR_SHARE_FLO_TOOLTIP = 'Share Flo';
export const FLO_PAGE_TOOL_BAR_RECTANGLE_ANNOTATION_ICON_TOOLTIP =
	'Add rectangle annotation';
export const FLO_PAGE_TOOL_BAR_SHARE_ICON_TOOLTIP = 'Share Flo';
export const FLO_PAGE_TOOL_BAR_STATUS_DEFAULT = 'Flo status';
export const FLO_PAGE_TOOL_BAR_STATUS_ACTIVE = 'Open for comments';
export const FLO_PAGE_TOOL_BAR_STATUS_CLOSED = 'Closed for collaboration';
export const FLO_PAGE_TOOL_BAR_STATUS_DRAFT = 'Draft';
export const MOUSE_CLICK_DISABLE = 'Disable mouse click';
export const MOUSE_CLICK_ENABLE = 'Enable mouse click';
export const FLO_PAGE_TOOL_BAR_STATUS_PROCESSING = 'Processing';
export const GUIDE_STEP_DESCRIPTION_PLACEHOLDER = 'Enter a description...';
export const GUIDE_MANUAL_STEP_DEFAULT_TITLE = 'This is a sample title';
export const GUIDE_ADD_IMAGE_ICON_TOOLTIP_TEXT = 'Add slide';
export const GUIDE_REPLACE_IMAGE_ICON_TOOLTIP_TEXT = 'Replace slide';

export const FLO_PAGE_TOOL_BAR_SETTINGS_ICON_TOOLTIP = 'Flo settings';
export const FLO_PAGE_TOOL_BAR_FLO_STATUS_TEXT = '';
export const FLO_PAGE_TOOL_BAR_ELLIPSIS_MENU_DELETE_FLO_TEXT = 'Delete Flo';
export const FLO_PAGE_TOOL_BAR_ELLIPSIS_MENU_LEAVE_FLO_TEXT = 'Leave Flo';
export const FLO_PAGE_VIDEO_CONTROL_BAR_PLAY_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_PAUSE_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_SPEAKER_ICON_TOOLTIP = '';
export const FLO_PAGE_VIDEO_CONTROL_BAR_RESOLUTION_SELECTION_TOOLTIP = '';
export const ORIGINAL_VOICE = 'Original voice';
export const SYNTHESISED_AI_VOICE = 'AI voice';
export const PUBLIC_SHARE_LINK = 'Public';
export const PRIVATE_SHARE_LINK = 'Private';
export const NO_VOICE = 'No voice';
export const FLO_PAGE_VIDEO_CONTROL_TITLE = 'Voice-over';
export const FLO_PAGE_AI_VOICE_GENERATING = 'Generating...';
export const FLO_PAGE_AI_VOICE_GENERATE_NOW = 'Generate now';
export const FLO_PAGE_AI_VOICE_REGENERATE = 'Regenerate for selected voice';
export const FLO_PAGE_AI_VOICE_NO_SCRIPT = 'No script available';
export const FLO_PAGE_VIDEO_CONTROL_OPTION_AI_VOICE_PRESENTER_VIDEO_NOTICE =
	'For AI voice, presenter video will be disabled.';
export const FLO_PAGE_PROCESSING_TITLE = 'Almost there';
export const FLO_PAGE_PROCESSING_SUB_TITLE =
	'We are processing your flo. This might take few seconds.';
export const COMMENTS_SECTION_TAB_1_TEXT = 'Discussion';
export const COMMENTS_SECTION_TAB_2_TEXT = 'Outcomes'; //Remove this;
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SINGULAR_COUNT_TITLE_TEXT = 'comment';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_PLURAL_COUNT_TITLE_TEXT = 'comments';
export const COMMENTS_SECTION_COMMENTS_TAB_AVATAR_ICON_TOOLTIP = '';
export const COMMENTS_SECTION_COMMENTS_TAB_VIDEO_TIME_TOOLTIP =
	'Click to seek to video time';
export const COMMENTS_SECTION_COMMENTS_TAB_DATE_TIME_TOOLTIP = '';
export const COMMENTS_SECTION_COMMENTS_TAB_DECISION_ICON_TOOLTIP = 'Mark it as decision';
export const COMMENTS_SECTION_COMMENTS_TAB_RESOLVED_ICON_TOOLTIP = 'Mark it as resolved';
export const COMMENTS_SECTION_COMMENTS_TAB_MORE_ICON_TOOLTIP = 'More options';
export const COMMENTS_SECTION_COMMENTS_TAB_REPLY_NOW_TEXT = 'Reply now';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_INPUT_BOX_PLACEHOLDER_TEXT =
	'Ask a question or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_REPLY_INPUT_BOX_PLACEHOLDER_TEXT =
	'Reply to a comment or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SEND_BUTTON_TOOLTIP =
	'Ask a question or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_SEND_REPLY_BUTTON_TOOLTIP =
	'Reply to a comment or share feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_EMPTY_SCREEN_TITLE_TEXT =
	'Click anywhere on the video to ask a question or give feedback';
export const COMMENTS_SECTION_COMMENTS_TAB_COMMENT_INPUT_LEAVE_WARNING =
	'Complete the pending comment';

export const GENERAL_ERRORS_SCREEN_RESOLUTION_TEXT =
	'Use full screen for better experience';
export const GENERAL_TAB_TEXT = '';

export const NEW_FLO_TITLE_TEXT = 'Recording settings';
export const NEW_FLO_SCREEN_OPTION_TEXT = 'Screen only';
export const NEW_FLO_CAMERA_OPTION_TEXT = 'Camera only';
export const NEW_FLO_SCREEN_CAMERA_OPTION_TEXT = 'Screen + camera';
export const NEW_FLO_SCREENSHOT_OPTION_TEXT = 'Screenshot';
export const NEW_FLO_CONTINUE_BTN_TEXT = 'Resume';
export const NEW_FLO_CONTINUE_BTN_TOOLTIP = 'Resume recording';
export const NEW_FLO_PAUSE_BTN_TEXT = 'Pause';
export const NEW_FLO_PAUSE_BTN_TOOLTIP = 'Pause recording';
export const NEW_FLO_END_BTN_TEXT = 'Done';
export const NEW_FLO_END_BTN_TOOLTIP = 'Finish recording';
export const NEW_FLO_NAME_INPUT_MANDATORY_PLACEHOLDER = 'Enter Flo name';
export const NEW_FLO_NAME_INPUT_PLACEHOLDER = 'Flo name (optional)';
export const NEW_FLO_SAVE_BTN_TEXT = 'Save Flo';
export const NEW_FLO_DISCARD_BTN_TEXT = 'Delete';
export const EDIT_FLO_DISCARD_BTN_TEXT = 'Discard';
export const NEW_FLO_START_FLO_BTN_TEXT = 'Start recording';
export const NEW_FLO_DISCARD_POP_UP_TITLE_TEXT = 'Delete this Flo?';
export const NEW_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT =
	'Are you sure you want to permanently delete this recording?';
export const NEW_FLO_DISCARD_POP_UP_BTN_TYPE = 'delete';
export const NEW_FLO_DISCARD_POP_UP_BTN_TEXT = 'Delete';

export const EDIT_FLO_DISCARD_POP_UP_TITLE_TEXT = 'Discard the changes?';
export const EDIT_FLO_DISCARD_POP_UP_SUB_TITLE_TEXT =
	'Are you sure you want to discard changes?';
export const EDIT_FLO_DISCARD_POP_UP_BTN_TYPE = 'delete';
export const EDIT_FLO_DISCARD_POP_UP_BTN_TEXT = 'Discard';

export const NEW_FLO_PROCESS_FLO_LOADER_TITLE_TEXT = 'Uploading';
export const NEW_FLO_SAVE_FLO_LOADER_TITLE_TEXT = 'Saving the Flo';
export const NEW_FLO_DISCARD_FLO_LOADER_TITLE_TEXT = 'Discarding the Flo';
export const NEW_FLO_PROCESS_FLO_LOADER_SUB_TITLE_TEXT = 'This might take few seconds';
export const NEW_FLO_SAVE_FLO_LOADER_SUB_TITLE_TEXT = 'This might take a few seconds';
export const NEW_FLO_DISCARD_FLO_LOADER_SUB_TITLE_TEXT = 'This might take a few seconds';
export const NEW_FLO_PERMISSION_TEXT = 'Choose your devices';
export const NEW_FLO_PERMISSIONS_POP_UP_TITLE_TEXT = 'Permission needed';
export const NEW_FLO_PERMISSIONS_POP_UP_SUB_TITLE_TEXT =
	'Allow Floik browser permissions to create a Flo';
export const NEW_FLO_RECORDING_TITLE_TEXT = 'Recording';
export const NEW_FLO_PAUSED_TITLE_TEXT = 'Paused';
export const NEW_FLO_SCREEN_SHARE_ENDED_ALERT =
	"'Recording stopped because screen share has been ended'";
export const NEW_FLO_PERMISSIONS_ERROR_POP_UP_TITLE_TEXT = 'Error';
export const NEW_FLO_PERMISSIONS_ERROR_POP_UP_SUB_TITLE_TEXT =
	'Failed to gain access Microphone / Camera, please enable to create flo.';

export const NEW_FLO_COUNTDOWN_TITLE = 'Recording starts in';
export const NEW_FLO_RESUME_COUNTDOWN_TITLE = 'Recording resumes in';
export const NEW_FLO_COUNTDOWN_SUB_TITLE = 'Take a deep breath';
export const HEADER_CREATED_BY_PREFIX_TEXT = 'by';

export const FLO_PAGE_VIDEO_DURATION = 'Time';
export const FLO_PAGE_VIDEO_PLAYBACK_RATE = 'Speed';
export const FLO_PAGE_VIDEO_QUALITY = 'Qaulity';
export const FLO_PAGE_VIDEO_VOLUME = 'Volume';
export const FLO_PAGE_VIDEO_NO_AUDIO = 'No audio available';
export const FLO_PAGE_VIDEO_PLAY = 'Play';
export const FLO_PAGE_VIDEO_PAUSE = 'Pause';
export const FLO_PAGE_ADD_CTA = 'Add Call to action';
export const FLO_PAGE_UPDATE_CTA = 'Update Call to action';
export const FLO_PAGE_UPDATE_TITLE_POPUP_TXT = 'Title';
export const FLO_PAGE_UPDATE_DESCRIPTION_POPUP_TXT = 'Description';
export const FLO_PAGE_UPDATE_BTN_POPUP_TXT = 'Save changes';
export const FLO_PAGE_POPUP_DESC_PLACEHOLDER_TXT = 'Add description';
export const FLO_PAGE_INTERACTION_DELETE_HEADING = 'Delete interaction?';
export const FLO_PAGE_INTERACTION_DELETE_CLICK = 'Delete click?';
export const FLO_PAGE_INTERACTION_DELETE_HOTSPOT = 'Delete hotspot?';
export const FLO_PAGE_INTERACTION_DELETE_IMAGE = 'Delete image?';
export const FLO_PAGE_ELEMENT_DELETE_INTERACTION_TOOLTIP = 'Delete interaction';
export const FLO_PAGE_ELEMENT_DELETE_CLICK_TOOLTIP = 'Delete click';
export const FLO_PAGE_ELEMENT_DELETE_HOTSPOT_TOOLTIP = 'Delete hotspot';
export const FLO_PAGE_ELEMENT_DELETE_IMAGE_TOOLTIP = 'Delete image';
export const FLO_PAGE_INTERACTION_DELETE_BODY =
	'Are you sure you want to delete this interaction?';
export const FLO_PAGE_CROP_DELETE_HEADING = 'Delete crop?';
export const FLO_PAGE_CROP_DELETE_BODY = 'Are you sure you want to delete this crop?';

export const FLO_PAGE_CTA_TOOLTIP_OUTSIDE_RANGE = 'Interaction is outside selected range';
export const FLO_PAGE_HOTSPOT_TOOLTIP_OUTSIDE_RANGE = 'Hotspot is outside selected area';
export const FLO_PAGE_CLICK_TOOLTIP_OUTSIDE_RANGE = 'Click is outside selected area';

export const FLO_PAGE_TEXT_TOOLTIP_OUTSIDE_RANGE = 'Text is outside selected area';

export const INTERACTIONS_TOOLTIP_TITLE_TEXT = 'Edit text';
export const FLO_PAGE_TEXT_TOOLTIP_PARTIAL_RANGE =
	'Text is partially outside selected area';
export const FLO_PAGE_ELEMENT_DELETE_TEXT_TOOLTIP = 'Delete Text';

//  Discard this recording and start over
// You dont have any shared Flos
// Collaborate asynchronously
export const FLO_RIGHT_PANEL_ADDITIONAL_SETTINGS = 'Additional settings';
export const RECENT_FLO_HEADER = 'Recent Flos';
export const HOME_TITLE = 'Home';
export const SHARE_DOWNLOAD_WAITING_TXT =
	"We don't like to keep you waiting! When we are done, we'll mail you the copy of your video";
export const SHARE_DOWNLOAD_PDF_WAITING_TXT =
	"We don't like to keep you waiting! When we are done, we'll mail you the copy of your pdf";
export const SHARE_DOWNLOAD_HTML_WAITING_TXT =
	"We don't like to keep you waiting! When we are done, we'll mail you the copy of your html";
export const SHARE_DOWNLOAD_MARKDOWN_WAITING_TXT =
	"We don't like to keep you waiting! When we are done, we'll mail you the copy of your markdown";
export const SHARE_DOWNLOAD_GIF_WAITING_TXT =
	"We don't like to keep you waiting! When we are done, we'll mail you the copy of your gif";

export const SHARE_DOWNLOAD_ERROR_TXT = 'Failed to download';
export const SHARE_DOWNLOAD_GIF_ERROR_TXT = 'Failed to download';
export const SHARE_DOWNLOAD_PDF_ERROR_TXT = 'Failed to download';
export const SHARE_DOWNLOAD_HTML_ERROR_TXT = 'Failed to download';
export const SHARE_DOWNLOAD_MARKDOWN_ERROR_TXT = 'Failed to download';

export const SHARE_PROCESSING_TXT = 'Processing video to download';
export const DOWNLOAD_MP4_TXT = 'MP4 video';
export const DOWNLOAD_TITLE_TXT = 'Download as';
export const SHARE_INTERACTIVE_DEMO_TXT = 'Share as interactive demo';
export const GUIDE_WAITING_TXT =
	'Our Guide-making robots are crafting your perfect guide. This wont take long,so please sit tight!';
export const GUIDE_CREATION_HELP_TXT =
	"Click the below button, and we'll create a custom guide for you based on your recording.";
export const GUIDE_CREATION_READY_TXT = 'Ready, set, guide!';
export const GUIDE_CREATION_ERR_TXT =
	'Oops, Guide generation failed. We are looking into the issue';
export const GUIDE_PUBLISH_FAILED_TXT =
	'Oops, something went wrong while generating your guide. Dont worry, we are looking into this issue.';

export const GUIDE_IMAGE_PROCESSING = 'Processing...';
export const GUIDE_IMAGE_LOADING = 'Loading...';

export const TEMPLATE_COMING_SOON_TXT = 'COMING SOON';
export const VIEW_TEMPLATE_TXT = 'View details';
export const SYSTEM_FOLDER_TOOLTIP_TXT = 'System generated folder, cannot be modified';
export const FOLDER_PUBLISH = 'Publish';
export const PUBLISHED_FLO = 'Published';
export const PUBLISHING_FLO = 'Publishing';
export const DRAFT_FLO = 'Draft';
export const FOLDER_RENAME = 'Rename';
export const FOLDER_DELETE = 'Delete';
export const PUBLISHED_FLO_TOOLTIP_TXT = 'Flo - Published & Ready';
export const DRAFT_FLO_TOOLTIP_TXT = 'Flo - Editing & Improving';
export const FILE_RENAME = 'Rename';
export const FILE_DELETE = 'Delete';
export const FOLDER_LOAD_MORE_TXT = 'Load more';
export const FOLDER_NO_FLOS_TXT = 'No Flos inside';
export const INSIGHTS_ROW_NAME_TXT = 'Name';
export const INSIGHTS_ROW_CTA_TXT = 'CTA clicked';
export const INSIGHTS_ROW_TOTAL_COMMENTS_TXT = 'Total comments';
export const INSIGHTS_ROW_TOTAL_VIEWS_TXT = 'Total views';
export const INSIGHTS_ROW_LAST_VIEWED_AT_TXT = 'Last viewed at';
export const ONBOARDING_CONTINUE_TXT = 'Continue';
export const AUDIO_EXCEED_ERR_TXT =
	"Oops, words overload! Let's trim it down and our AI will get back in action";
export const AUDIO_VIDEO_TRIM_ERR_TXT =
	'Adjust AI voice time to hear complete audio; trim affects playback';
export const AUDIO_OVERLAP_ERR_TXT =
	'Audio is overlapping, this audio will not play completely';
export const AUDIO_TRIMMED_OUT_ERR_TXT = 'Audio is trimmed out, this audio will not play';
export const AUDIO_SAME_TIME_ERR_TXT = 'Time stamp already exist';
export const AUDIO_EMPTY_TRANSCRIBE_TXT = 'Transcribe is empty';
export const SUBTITLE_BLOCK_ENABLE_TOOLTIP_TXT = 'Enable';
export const SUBTITLE_BLOCK_DISABLE_TOOLTIP_TXT = 'Disable';
export const AUDIO_REPHRASE_VOICE_OVER_TXT = 'Rephrase with AI';
export const DISPLAY_SURFACE_NAME_MAP = {
	BROWSER: 'TAB',
	WINDOW: 'WINDOW',
	MONITOR: 'MONITOR',
};

export const DISPLAY_SURFACE_DESCRIPTION_MAP = {
	BROWSER: 'Recording your browser tab',
	WINDOW: 'Recording your browser window',
	MONITOR: 'Recording your entire screen',
};

export const ADVENTURE_CTA_TITLE_PLACEHOLDER = 'Title';
export const ADVENTURE_CTA_DESCRIPTION_PLACEHOLDER = 'Description';
export const ADVENTURE_CTA_SELECT_FLO_PLACEHOLDER = 'Select the target Flo';
export const ADVENTURE_CTA_SELECT_FLO_POPUP_TITLE = 'Select the target Flo';
export const ADVENTURE_CTA_SELECT_FLO_BUTTON_TEXT = 'Select Flo';
export const ADVENTURE_CTA_BUTTON_PLACEHOLDER = 'Enter button name';
export const ADVENTURE_CTA_ARROW_BUTTON_TOOLTIP_TEXT = 'Open flo in new tab';
export const ADVENTURE_CTA_NO_FLOS_MESSAGE = 'No published Flos found';
export const ADVENTURE_CTA_DISCARD_BUTTON_TEXT = 'Discard';
export const ADVENTURE_CTA_CANCEL_BUTTON_TEXT = 'Cancel';
export const ADVENTURE_CTA_DISCARD_POPUP_TITLE = 'Discard Interaction cues?';
export const MULTIPLE_CTA_ERROR_MESSAGE = 'Multiple popups not allowed';

export const NEW_FLO_DEVICE_ISSUE_MESSAGE =
	'Seems like your camera and microphone is not enabled. Please check your settings and enable it for your browser. ';
export const NEW_FLO_DEVICE_ISSUE_MESSAGE_LINK_TEXT = 'link';
export const NEW_FLO_DEVICE_ISSUE_WINDOWS_SYSTEM_LINK =
	'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
export const NEW_FLO_DEVICE_ISSUE_MAC_SYSTEM_LINK =
	'https://support.apple.com/en-in/guide/mac-help/mchlf6d108da/mac';
export const NEW_FLO_DEVICE_ISSUE_LINUX_SYSTEM_LINK =
	'https://askubuntu.com/questions/1318256/how-to-change-camera-access';
export const NEW_FLO_OPTIONAL_MICROPHONE_SKIP_BUTTON_TEXT = 'Skip and continue';
export const NEW_FLO_OPTIONAL_MICROPHONE_CANCEL_BUTTON_TEXT = 'Cancel';
export const NEW_FLO_NO_AUDIO_DROPDOWN_LABEL = 'No audio';
export const MYSPACE_EMPTY_FLOS_TEXT = "Haven't created a Flo yet? Let's go";
export const MYSPACE_EMPTY_FOLDER_TEXT = "Let's Flo it up!";
export const MYSPACE_EMPTY_FLOS_BUTTON_TEXT = 'Create a new Flo';
export const MYSPACE_HAS_NO_FOLDERS = "It appears you haven't created a folder yet";
export const MYSPACE_CREATE_FOLDER = 'Create folders to manage your Flos';
export const MYSPACE_EMPTY_TEAM_FLOS_TEXT = 'No Flos yet';
export const TEAMSPACE_DIALOG_TITLE = 'Team';
export const TEAMSPACE_ROLES_DROPDOWN_PLACEHOLDER = 'Select role';
export const TEAMSPACE_OWNER_ROLE_TEXT = 'Owner';
export const TEAMSPACE_DIALOG_INVITE_MEMBERS = 'Invite team members';
export const TEAMSPACE_DIALOG_INVITE_TEXT = 'Invite';
export const TEAMSPACE_DIALOG_INVITE_AGAIN = 'Invite again';
export const TEAMSPACE_DIALOG_INVITE_AGAIN_INPROGRESS = 'Inviting';
export const TEAMSPACE_EMAIL_INPUT_PLACEHOLDER = 'Enter email';
export const TEAMSPACE_EMAIL_ERROR_SAME_EMAIL =
	'Cannot invite the user with email %EMAIL% because you cannot invite yourself.';
export const TEAMSPACE_EMAIL_ERROR_ALREADY_EXIST_EMAIL = `User with email %EMAIL% has already been invited`;
export const MY_SPACE_FOLDER_SUB_TITLE_TEXT = 'Name';
export const TEAMSPACE_MANAGE_USERS = 'Manage users';
export const TEAMSPACE_ROLE_CHANGE_DIALOG_HEADER = 'Change Role';
export const TEAMSPACE_ADMIN_ROLE_INFO =
	'Have access to all product functionality including modify users and branding but cannot view or update billing information';
export const TEAMSPACE_USER_ROLE_INFO =
	'Have access to all product functionality but cannot modify users or branding or view billing information';
export const TEAMSPACE_BILLING_ROLE_INFO =
	'Can modify billing data but cannot modify users or branding or access non-billing admin functions';
export const MYSPACE_ADD_FOLDER = 'Add folder';
export const MYSPACE_RENAME_FOLDER = 'Rename folder';
export const MYSPACE_ADD_FOLDER_CONFIRM = 'Add folder';
export const MYSPACE_UPDATE_FOLDER_CONFIRM = 'Update name';

export const USER_INVITING_TEXT = 'Inviting';
export const USER_INVITE_TEXT = 'Invite';
export const DELETE_USER_CONFIRM_TITLE = 'Remove user';
export const DELETE_USER_CONFIRM_BUTTON_TEXT = 'Remove user';
export const DELETE_USER_CONFIRM_TEXT = 'Are you sure you want to remove this user?';
export const REMOVE_USER_BUTTON_TEXT = 'Remove user';
export const REMOVED_USER_TEXT = 'Access revoked';
export const CANCEL_USER_INVITE_BUTTON_TEXT = 'Cancel invite';
export const CANCEL_USER_INVITE_CONFIRM_TITLE = 'Cancel invite';
export const CANCEL_USER_INVITE_CONFIRM_BUTTON_TEXT = 'Cancel invite';
export const CANCEL_USER_INVITE_CONFIRM_TEXT =
	"Are you sure you want to cancel this user's invite?";
export const PRICING_QA_TXT =
	'Have a question or want to chat about the right plan for you?';
export const PRICING_BOOK_SLOT_TXT = 'Book a slot';
export const PRICING_RELOAD_TOOLTIP_TXT = 'Click to get latest updates';
export const PRICING_CANCEL_SUBSCRIPTION_TXT = 'Cancel your subscription';
export const PRICING_RENEW_SUBSCRIPTION_TXT = 'Renew now';
export const PRICING_RECOMMENDED_PLAN_TEXT = 'Most popular';
export const PRICING_UPGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT =
	'Upgrade subscription';
export const PRICING_DOWNGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT = 'Contact support';
export const PRICING_CONTACT_SUPPORT_LINK = 'mailto:hi@floik.com';
export const PRICING_BOOK_SLOT_LINK = 'mailto:hi@floik.com';
export const PRICING_RENEWAL_DATE_TITLE = 'Renewal date';
export const PRICING_TRIAL_DATE_TITLE = 'Trial ends on';
export const PRICING_TRIAL_PERIOD_TEXT = ' (Trial)';
export const PRICING_SUBSCRIPTION_TAB_1_TEXT = 'Monthly';
export const PRICING_SUBSCRIPTION_TAB_2_TEXT = 'Yearly';
export const PRICING_CURRENT_PLAN_TXT = 'Current plan';
export const PRICING_CURRENT_PLAN_BUTTON_TXT = 'Current plan';
export const PRICING_CANCEL_SUBSCRIPTION_POPUP_TITLE_TXT = 'Subscription Cancellation';
export const PRICING_CANCEL_SUBSCRIPTION_POPUP_DESC_TXT =
	'Please be aware that this is an irreversible operation. Upon cancellation, you will be unsubscribed immediately, even if there are remaining days in your current subscription period. Additionally, no refunds will be provided for any remaining days. By proceeding, you acknowledge and accept these terms.';
export const PRICING_UPGRADE_SUBSCRIPTION_POPUP_TITLE_TXT = 'Subscription Cancellation';
export const PRICING_UPGRADE_SUBSCRIPTION_POPUP_DESC_TXT =
	'Please be aware that this is an irreversible operation. Upon cancellation, you will be unsubscribed immediately, even if there are remaining days in your current subscription period. Additionally, no refunds will be provided for any remaining days. By proceeding, you acknowledge and accept these terms.';
export const PRICING_CANCEL_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT =
	'%UPGRADE% subscription';
export const PRICING_CANCEL_SUBSCRIPTION_POPUP_CANCEL_POPUP_TXT = 'Keep subscription';
export const PRICING_UPGRADE_PLAN_BUTTON_TXT = 'Upgrade';
export const PRICING_DOWNGRADE_PLAN_BUTTON_TXT = 'Downgrade';
export const PRICING_CONTACT_US_PLAN_BUTTON_TXT = 'Contact us';
export const GENERATE_NOW_TXT = 'Generate now';
export const GUIDE_NO_CLICKS_GEN_ERR_TXT =
	'Oops, We are unable to generate Guide as no clicks were found in your Flo.';
export const HOME_TOAST_TXT = 'Looks like your plan has expired';
export const HOME_TOAST_RENEW_WARNING_TXT =
	'Your plan is about to expire, please renew now';
export const HOME_RESOURCES_HEADING_TXT = 'Resources';

export const INVITE_SUCCESS_PAGE_JOINED_SUCCESSFUL =
	"You have successfully joined %USER_NAME%'s flospace";
export const INVITE_FAILED_PAGE_DEFAULT_MESSAGE = 'Oops! Something went wrong';
export const INVITE_SUCCESS_PAGE_GOTO_FLOS = 'Take me to home';
export const GLOBAL_USER_REMOVED_FROM_FLOSPACE_TITLE = 'Access revoked';
export const GLOBAL_USER_REMOVED_FROM_FLOSPACE_DESCRIPTION =
	'Your access has been revoked.';

export const GLOBAL_USER_ADDED_FROM_FLOSPACE_TITLE = 'Congratulations!';
export const GLOBAL_USER_ADDED_FROM_FLOSPACE_DESCRIPTION =
	'You have joined a team. click to reload';

export const MULTIPLE_FLO_RECORDING_TXT = 'Multiple flo recording not allowed';
export const MULTIPLE_FLO_RECORDING_CONFIRM_BTN_TXT = 'Use here';
export const MULTIPLE_FLO_RECORDING_CANCEL_BTN_TXT = 'Okay';

export const SERVICE_WORKER_NEW_VERSION =
	'A newer version is available, please reload the app.';

export const PROFILE_SHOW_LOGO_IN_HEADER_HEADING = 'Header';
export const PROFILE_SHOW_LOGO_IN_HEADER_TITLE = 'Show logo in header.';
export const PROFILE_LOGO_AS_WATERMARK_HEADING = 'Watermark';
export const PROFILE_LOGO_AS_WATERMARK_TITLE = 'Show logo as watermark.';

export const INTERACTIONS_TOOLTIP_TITLE_BLUR = 'Edit Blur';
export const INTERACTIONS_TOOLTIP_TITLE_SPOTLIGHT = 'Edit Spotlight';
export const FLO_PAGE_EDIT_BLUR_DISABLED_TOOLTIP = 'Blur is disabled';
export const FLO_PAGE_BLUR_TOOLTIP_OUTSIDE_RANGE = 'Blur is outside selected area';
export const FLO_PAGE_ELEMENT_DELETE_BLUR_TOOLTIP = 'Delete blur';
export const FLO_PAGE_ELEMENT_DELETE_BLUR_TITLE = 'Delete blur';
export const FLO_PAGE_ELEMENT_DELETE_BLUR_DESCRIPTION =
	'Are you sure you want to delete this blur?';
export const FLO_PAGE_BLUR_TOOLTIP_OUTSIDE_TRIM_RANGE =
	'Blur will not be shown in trimmed sections';
export const FLO_PAGE_SPOTLIGHT_TOOLTIP_OUTSIDE_TRIM_RANGE =
	'Spotlight will not be shown in trimmed sections';

export const FLO_PAGE_SPOTLIGHT_TOOLTIP_OUTSIDE_RANGE =
	'Spotlight is outside selected area';
export const FLO_PAGE_EDIT_SPOTLIGHT_DISABLED_TOOLTIP = 'Spotlight is disabled';
export const FLO_PAGE_ELEMENT_DELETE_SPOTLIGHT_TITLE = 'Delete spotlight';
export const FLO_PAGE_ELEMENT_DELETE_SPOTLIGHT_TOOLTIP = 'Delete spotlight';
export const FLO_PAGE_ELEMENT_DELETE_SPOTLIGHT_DESCRIPTION =
	'Are you sure you want to delete this spotlight?';

export const INTERACTIONS_TOOLTIP_TITLE_CTA = 'Edit CTA';
export const INTERACTIONS_TOOLTIP_TITLE_IMAGE = 'Edit Image';
export const INTERACTIONS_TOOLTIP_TITLE_MOUSE = 'Move the mouse click';
export const INTERACTIONS_TOOLTIP_EDIT_HOTSPOT = 'Edit hotspot';
export const INTERACTIONS_TOOLTIP_ZOOM_ENABLE_MOUSE = 'Zoom enabled';
export const INTERACTIONS_TOOLTIP_ZOOM_DISABLED_MOUSE = 'Zoom disabled';
export const INTERACTIONS_TOOLTIP_HEADING_INTERACTIVE_CUE = 'Interaction Cue';
export const INTERACTIONS_TOOLTIP_HEADING_TITLE_CTA = 'Call to action';
export const INTERACTIONS_TOOLTIP_HEADING_TITLE_MOUSE_CLICK = 'Mouse click';

export const INTERACTIVE_CTA_CONTROLPANEL_ADD_TITLE = 'Add call to action';
export const INTERACTIVE_CTA_CONTROLPANEL_EDIT_TITLE = 'Call to action';
export const INTERACTIVE_CTA_CONTROLPANEL_DELETE_BUTTON_TOOLTIP = 'Delete';
export const INTERACTIVE_CTA_CONTROLPANEL_CANCEL_BUTTON = 'Cancel';
export const INTERACTIVE_CTA_CONTROLPANEL_DONE_BUTTON = 'Done';
export const INTERACTIVE_CTA_CONTROLPANEL_APPLY_BUTTON = 'Apply';
export const INTERACTIVE_CTA_CONTROLPANEL_DROPDOWN_SELECT = 'Select';
export const INTERACTIVE_CTA_CONTROLPANEL_ADD_NEW_SECTION = 'Add new';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_EMPTY_FORM =
	'Please fill the form to continue.';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_REQUIRED_FIELD = 'Required';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_CTA_IMAGE_TIME_NOT_MATCHING =
	'CTA time doesn’t match. Update time for the CTA or create a new one.';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_MULTIPLE_REQUIRED_FIELD =
	'Required at least one entry.';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_MAX_ITEMS_REQUIRED_FIELD =
	'Only %MAX_ITEMS% allowed.';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_MIN_ITEMS_REQUIRED_FIELD =
	'Minimum %MIN_ITEMS% item required.';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_NO_FLO_SELECTED = 'Select a flo';
export const INTERACTIVE_CTA_CONTROLPANEL_ERROR_INVALID_LINK = 'Enter a valid link';
export const INTERACTIVE_CTA_CONTROLPANEL_CANCEL_POPUP_HEADING = 'Unsaved changes';
export const INTERACTIVE_CTA_CONTROLPANEL_CANCEL_POPUP_DESCRIPTION =
	'You have unsaved changes, do you want to discard?';
export const INTERACTIVE_CTA_CONTROLPANEL_CANCEL_POPUP_CONFIRM_TEXT = 'Confirm';
export const INTERACTIVE_CTA_CONTROLPANEL_CANCEL_POPUP_CANCEL_TEXT = 'Cancel';
export const UNTITLED_INTERACTIONS = 'Untitled';
export const INTERACTIVE_CUE_SELECTED_CTA_FLO_NAME = 'Flo';
export const HOTSPOT_INVALID_POSITION_MESSAGE = 'Invalid position, please update';

export const CROP_CONTROLPANEL_ADD_TITLE = 'Crop';
export const CROP_CONTROLPANEL_EDIT_TITLE = 'Crop';
export const CROP_CONTROLPANEL_TIME_RANGE_START_TIME_TITLE = 'From';
export const CROP_CONTROLPANEL_TIME_RANGE_END_TIME_TITLE = 'To';
export const TRIM_CONTROLPANEL_ADD_TITLE = 'Trim';
export const TRIM_CONTROLPANEL_EDIT_TITLE = 'Trim';
export const IMAGE_CONTROLPANEL_ADD_TITLE = 'Image';
export const IMAGE_CONTROLPANEL_EDIT_TITLE = 'Image';
export const HOTSPOT_CONTROLPANEL_ADD_TITLE = 'Hotspots';
export const HOTSPOT_CONTROLPANEL_EDIT_TITLE = 'Edit Hotspots';
export const HOTSPOT_CONTROLPANEL_BASE_TITLE = 'Hotspots';

export const BLUR_CONTROLPANEL_BASE_TITLE = 'Blur';
export const BLUR_CONTROLPANEL_ADD_TITLE = 'Blur';
export const BLUR_CONTROLPANEL_EDIT_TITLE = 'Edit Blur';

export const SPOTLIGHT_CONTROLPANEL_BASE_TITLE = 'Spotlight';
export const SPOTLIGHT_CONTROLPANEL_ADD_TITLE = 'Spotlight';
export const SPOTLIGHT_CONTROLPANEL_EDIT_TITLE = 'Edit Spotlight';

export const DOCUMENT_BLUR_CONTROLPANEL_BASE_TITLE = 'Blur';
export const DOCUMENT_BLUR_CONTROLPANEL_ADD_TITLE = 'Blur';
export const DOCUMENT_BLUR_CONTROLPANEL_EDIT_TITLE = 'Edit Blur';

export const VIRTUAL_BACKGROUND_CONTROLPANEL_BASE_TITLE = 'Background';
export const VIRTUAL_BACKGROUND_CONTROLPANEL_ADD_TITLE = 'Background';
export const VIRTUAL_BACKGROUND_CONTROLPANEL_EDIT_TITLE = 'Background';

export const CROP_CONTROLPANEL_CROP_OUTPUT_TITLE = 'Crop output';
export const CROP_CONTROLPANEL_CROP_OUTPUT__WIDTH_TITLE = 'Width';
export const CROP_CONTROLPANEL_CROP_OUTPUT_HEIGHT_TITLE = 'Height';
export const CROP_CONTROLPANEL_CROP_OUTPUT_ASPECT_RATIO_TITLE = 'Aspect ratio';
export const DROPDOWN_IMAGE_ALT_TEXT = '';
export const CROP_ASPECT_RATIO_CONTROLPANEL_PLACEHOLDER = '--:--';
export const FLOIK_ACADEMY_TEXT = 'Floik academy';
export const WHATS_NEW_TEXT = "What's new";
export const FLOIK_ACADEMY_URL = 'https://www.floik.com/resources/floik-academy';
export const WHATS_NEW_URL = 'https://www.floik.com/resources/changelog';
export const NO_COMMENTS_TEXT = 'Come back here to view comments';
export const FLO_PUBLISH_RESTRICTED_HEADER = '';
export const FLO_PUBLISH_RESTRICTED_BODY =
	'This Flo’s video resolution (%WIDTH% x %HEIGHT%) is high and is not supported in your current subscription plan. Please re-record a new Flo in a lower resolution or upgrade your subscription to publish this Flo.';
export const FLO_PUBLISH_RESTRICTED_BUTTON_TEXT = 'Okay';

export const FLO_PUBLISH_ESTIMATE_TIME_HD = 'Estimated time to complete: 10min';
export const FLO_PUBLISH_ESTIMATE_TIME_FHD = 'Estimated time to complete: 15min';
export const FLO_PUBLISH_ESTIMATE_TIME_UHD = 'Estimated time to complete: 30min';

export const FLO_HOTSPOT_FAILED_TO_SAVE =
	'Failed to %EDIT_TYPE% hotspot, please try again';

export const TRIM_CONTROLPANEL_CANCEL_POPUP_HEADING = 'Unsaved changes';
export const TRIM_CONTROLPANEL_CANCEL_POPUP_DESCRIPTION =
	'You have unsaved changes, do you want to discard?';
export const TRIM_CONTROLPANEL_CANCEL_POPUP_CONFIRM_TEXT = 'Confirm';
export const TRIM_CONTROLPANEL_CANCEL_POPUP_CANCEL_TEXT = 'Cancel';

export const TRIM_CONTROLPANEL_DELETE_POPUP_HEADING = 'Delete';
export const TRIM_CONTROLPANEL_DELETE_POPUP_DESCRIPTION =
	'Are you sure you want to delete this trim?';
export const TRIM_CONTROLPANEL_DELETE_POPUP_CONFIRM_TEXT = 'Confirm';
export const TRIM_CONTROLPANEL_DELETE_POPUP_CANCEL_TEXT = 'Cancel';

export const HOTSPOTS_CONTROLPANEL_EMPTY_TITLE = 'Add hotspots';
export const HOTSPOTS_CONTROLPANEL_EMPTY_DESCRIPTION =
	'Create hotspots to give users stepwise guidance';
export const HOTSPOTS_CONTROLPANEL_EMPTY_ADD_HOTSPOT_TEXT = 'Create';
export const HOTSPOTS_CONTROLPANEL_EMPTY_CANCEL_HOTSPOT_TEXT = 'Cancel';

export const BLUR_CONTROLPANEL_EMPTY_TITLE = 'Add blur';
export const BLUR_CONTROLPANEL_EMPTY_DESCRIPTION =
	'Create blurs to mask sensitive contents';
export const BLUR_CONTROLPANEL_EMPTY_CANCEL_TEXT = 'Cancel';
export const BLUR_CONTROLPANEL_EMPTY_ADD_TEXT = 'Add';

export const SPOTLIGHT_CONTROLPANEL_EMPTY_TITLE = 'Add spotlight';
export const SPOTLIGHT_CONTROLPANEL_EMPTY_DESCRIPTION =
	'Create spotlights to highlight content';
export const SPOTLIGHT_CONTROLPANEL_EMPTY_CANCEL_TEXT = 'Cancel';
export const SPOTLIGHT_CONTROLPANEL_EMPTY_ADD_TEXT = 'Add';

export const TEXT_CONTROLPANEL_EMPTY_TITLE = 'Add text';
export const TEXT_CONTROLPANEL_EMPTY_DESCRIPTION = 'Add text to guide users';
export const TEXT_CONTROLPANEL_EMPTY_CANCEL_TEXT = 'Cancel';
export const TEXT_CONTROLPANEL_EMPTY_ADD_TEXT = 'Add';

export const IMAGE_CONTROLPANEL_EMPTY_TITLE = 'Add image';
export const IMAGE_CONTROLPANEL_EMPTY_DESCRIPTION =
	'Lorem Ipsum is simply dummy text of the printing and typesetting industry';
export const IMAGE_CONTROLPANEL_EMPTY_ADD_TEXT = 'Create';
export const IMAGE_CONTROLPANEL_EMPTY_CANCEL_TEXT = 'Cancel';

export const TRIM_CONTROLPANEL_EMPTY_TITLE = 'Trim your video now';
export const TRIM_CONTROLPANEL_EMPTY_DESCRIPTION = '';
export const TRIM_CONTROLPANEL_ADD_TRIM_TEXT = 'Add new';
export const TRIM_CONTROLPANEL_EMPTY_ADD_TRIM_TEXT = 'Add now';

export const ERROR_ELEMENTS_TRIM_OVERLAP = 'Overlap of trims not allowed';
export const ERROR_ELEMENTS_TRIM_VALUE = 'Trim value';
export const ERROR_ELEMENT_TRIM_END_TIME_EXCEEDED = 'End time is exceeding video length';
export const ERROR_ELEMENT_CANNOT_ADD_DURING_PROCESSING = 'Can not add during processing';
export const ERROR_ELEMENT_TRIM_MIN_VIDEO_DURATION =
	'Should have minimum of 3seconds video time';
export const ERROR_ELEMENT_TRIM_END_LESS_THAN_START = 'Invalid start and end time';

export const FLO_ELEMENT_CROP_NOT_AVAILABLE =
	'Crop is not available at the moment, try later';
export const FLO_ELEMENT_TRIM_NOT_AVAILABLE =
	'Trim is not available at the moment, try later';
export const BRAND_CARD_TITLE = 'Branding';
export const BRAND_CARD_DESC = 'Customise brand colors, add your logo and more';
export const BRAND_BTN_TEXT = 'Checkout now';
export const PH_LINK =
	'https://www.producthunt.com/golden-kitty-awards/product-of-the-year';
export const STARTER_CARD_TITLE = 'Free plan';
export const STARTER_CARD_DESC = 'You can publish up to 5 Flos for free.';
export const STARTER_CARD_DESC_EXHAUST_TXT = 'You have exhausted your Flo publish limit.';
export const REFERRAL_CARD_TITLE = 'Earn access to paid plans';
export const REFERRAL_CARD_DESC =
	'Refer your team and friends to try Floik to unlock premium benefits for yourself';
export const REFERRAL_CARD_CTA_LINK = 'Refer now';
export const STARTER_CARD_BTN_TEXT = 'Upgrade now';
export const FLO_SHARE_TAB_SKIP_VIDEO_PROMPT_HEADER = 'Republish Flo';
export const FLO_SHARE_TAB_SKIP_VIDEO_PROMPT_BODY =
	'You will need to republish your Flo to enable this feature.';
export const FLO_SHARE_TAB_SKIP_VIDEO_PROMPT_CANCEL_BUTTON = 'Cancel';
export const FLO_SHARE_TAB_SKIP_VIDEO_PROMPT_CONFIRM_BUTTON = 'Okay';
export const FLO_PAGE_DELETE_IMAGE = 'Delete image?';
export const FLO_PAGE_DELETE_IMAGE_BODY = 'Are you sure you want to delete this image?';
export const ONBOARDING_HEADING_TEXT = "Let's explore together";
export const ONBOARDING_DISMISS_BUTTON_TEXT = 'Dismiss';
export const ONBOARDING_DISMISS_CONFIRM_POPUP_TITLE = 'Dismiss';
export const ONBOARDING_DISMISS_CONFIRM_POPUP_TEXT =
	'Are you sure you want to hide the introduction panel?';
export const ONBOARDING_INSTALL_EXTENSION_LABEL = 'Install Chrome extension';
export const ONBOARDING_CREATE_FLO_LABEL = 'Create a Flo';
export const ONBOARDING_SHARE_FLO_LABEL = 'Publish a Flo';

export const FLO_PAGE_DELETE_BLUR = 'Delete blur?';
export const FLO_PAGE_DELETE_BLUR_BODY = 'Are you sure you want to delete this blur?';
export const FLO_PAGE_DISCARD_BLUR = 'Discard changes?';
export const FLO_PAGE_DISCARD_BLUR_BODY = 'Are you sure you want to discard the change?';
export const FLO_PAGE_DISCARD_BLUR_CONFIRM_BUTTON_TEXT = 'Discard';
export const FLO_PAGE_DISCARD_BLUR_CANCEL_BUTTON_TEXT = 'Cancel';

export const FLO_PAGE_DELETE_SPOTLIGHT = 'Delete spotlight?';
export const FLO_PAGE_DELETE_SPOTLIGHT_BODY =
	'Are you sure you want to delete this spotlight?';
export const FLO_PAGE_DISCARD_SPOTLIGHT = 'Discard changes?';
export const FLO_PAGE_DISCARD_SPOTLIGHT_BODY =
	'Are you sure you want to discard the change?';
export const FLO_PAGE_DISCARD_SPOTLIGHT_CONFIRM_BUTTON_TEXT = 'Discard';
export const FLO_PAGE_DISCARD_SPOTLIGHT_CANCEL_BUTTON_TEXT = 'Cancel';

export const FLO_PAGE_DELETE_HOTSPOT = 'Delete hotspot?';
export const FLO_PAGE_DELETE_HOTSPOT_BODY =
	'Are you sure you want to delete this hotspot?';

export const FLO_PAGE_DELETE_TEXT = 'Delete text?';
export const FLO_PAGE_DELETE_TEXT_BODY = 'Are you sure you want to delete this text?';

export const FLO_PAGE_DISCARD_IMAGE = 'Discard changes?';
export const FLO_PAGE_DISCARD_CONFIRM_BUTTON_TEXT = 'Discard';
export const FLO_PAGE_DISCARD_IMAGE_CONFIRM_BUTTON_TEXT = 'Discard';
export const FLO_PAGE_DISCARD_IMAGE_CANCEL_BUTTON_TEXT = 'Cancel';
export const FLO_PAGE_DISCARD_IMAGE_BODY = 'Are you sure you want to discard the change?';

export const STEP_IMAGE_ADD_NEW_ERROR_HEADER = 'Error';
export const STEP_IMAGE_ADD_NEW_ERROR_BODY =
	'Can not proceed adding image. need at-least one step without image as element';
export const STEP_IMAGE_ADD_NEW_ERROR_CONFIRM_BUTTON_TEXT = 'Okay';
export const STEP_IMAGE_ADD_NEW_ERROR_CANCEL_BUTTON_TEXT = 'Discard';

export const STEP_IMAGE_ADD_IMAGE_ERROR_HEADER = 'Step image?';
export const STEP_IMAGE_ADD_IMAGE_ERROR_BODY =
	"Step doesn't have a slide image. Please set a slide image before adding image as element";
export const STEP_IMAGE_ADD_IMAGE_ERROR_CONFIRM_BUTTON_TEXT = 'Okay';
export const STEP_IMAGE_ADD_IMAGE_ERROR_CANCEL_BUTTON_TEXT = 'Discard';

export const STEP_IMAGE_ADD_IMAGE_NOT_LOADED_HEADER = 'Step image?';
export const STEP_IMAGE_ADD_IMAGE_NOT_LOADED_BODY = 'Step is not ready to add image';
export const STEP_IMAGE_ADD_IMAGE_NOT_LOADED_CONFIRM_BUTTON_TEXT = 'Okay';
export const STEP_IMAGE_ADD_IMAGE_NOT_LOADED_CANCEL_BUTTON_TEXT = 'Discard';

export const STEP_IMAGE_ADD_NEW_EXISTS_ERROR_HEADER = 'Already exists';
export const STEP_IMAGE_ADD_NEW_EXISTS_ERROR_BODY =
	'Step already contains image element.';
export const STEP_IMAGE_ADD_NEW_EXISTS_ERROR_CONFIRM_BUTTON_TEXT = 'Okay';
export const STEP_IMAGE_ADD_NEW_EXISTS_ERROR_CANCEL_BUTTON_TEXT = 'Discard';

export const FLO_PAGE_DISCARD_HOTSPOT = 'Discard changes?';
export const FLO_PAGE_DISCARD_HOTSPOT_CONFIRM_BUTTON_TEXT = 'Discard';
export const FLO_PAGE_DISCARD_HOTSPOT_CANCEL_BUTTON_TEXT = 'Cancel';
export const FLO_PAGE_DISCARD_HOTSPOT_BODY =
	'Are you sure you want to discard the change?';

export const GALLERY_UPLOAD_PROGRESS_TEXT = 'Uploading...';
export const GALLERY_IMAGE_DIMENSIONS_ERROR_TOOLTIP =
	'Image does not meet minimum resolution criteria';
export const IMAGE_LOADING_TEXT = 'Loading...';

export const GALLERY_SLIDE_IMAGE_HEADER = 'Select slide image';
export const GALLERY_AUDIO_PRE_REQUISITE = 'Supported audio files: .mp3, .aac';

export const SUBSCRIPTION_UPGRADE_POPUP_CANCEL_BUTTON_TEXT = 'Maybe later';
export const SUBSCRIPTION_PRO_FEATURE_HEADER = 'Pro Feature';
export const SUBSCRIPTION_DIAMOND_FEATURE_CANCEL_BUTTON_TEXT = 'Maybe later';
export const SUBSCRIPTION_DIAMOND_FEATURE_CONFIRM_BUTTON_TEXT = 'Upgrade now';
export const SUBSCRIPTION_UPGRADE_POPUP_CONFIRM_BUTTON_TEXT = 'Upgrade now';
export const INSIGHTS_GRAPH_NO_DATA_MESSAGE = 'No data found';
export const SUBSCRIPTION_ADVANCED_INSIGHTS_HEADING = 'Advanced insights';
export const SUBSCRIPTION_ADVANCED_INSIGHTS_DESCRIPTION =
	'Learn what works and understand the impact of every change.';
export const GALLERY_SUBHEADING = 'Recently used';
export const GALLERY_NO_IMAGES_FOUND = 'Upload file to get started';
export const GALLERY_NO_FILE_FOUND = 'No file found';
export const GALLERY_NO_AUDIO_FOUND = 'Upload audio file to get started';
export const GALLERY_CORRUPT_IMAGE_ERROR_MSG =
	'Error occurred while uploading file. Please try again.';
export const GALLERY_FAILED_TO_LOAD_IMAGES = 'Failed to load';
export const GALLERY_CANCEL_BUTTON_TEXT = 'Cancel';
export const GALLERY_CONFIRM_BUTTON_TEXT = 'Select';
export const GALLERY_UPLOAD_BUTTON_TEXT = 'Upload';
export const GALLERY_UPLOAD_FILE_SIZE_ERROR =
	'Please select image with size less than 10mb';
export const GALLERY_UPLOAD_FILE_SIZE_ERROR_AUDIO =
	'Please select audio file with size less than 5mb';
export const GALLERY_UPLOAD_FILE_CORRUPTED_ERROR = 'Please upload a valid file';
export const GALLERY_MY_UPLOADS_KEY = 'uploaded';
export const GALLERY_SELECT_FROM_ORIGINAL_TEXT = 'Select from original';
export const GUIDE_MANUAL_STEP_UPLOAD_FILE_CORRUPTED_ERROR = 'Please upload a valid file';
export const GUIDE_MANUAL_STEP_UPLOAD_FILE_SIZE_ERROR =
	'Please select image with size less than 10mb';
export const BRANDING_UPLOADING_CORRUPT_FILE_ERROR = 'Please upload a valid file';
export const ERROR_ELEMENT_IMAGE_NOT_A_VALID_DURATION =
	'Please provide a duration between 2 and 10.';
export const HEADS_UP_SWIFT_MODE_WARNING =
	'Image will not be shown in Swift Play mode for this Behaviour(Stay For).';
export const HEADS_UP_VOICE_OVER_WARNING_IMAGE =
	'Heads up! The AI voice you added will not play as CTA with voice is linked';
export const HEADS_UP_VOICE_OVER_WARNING_CTA =
	'Heads up! The AI voice you added for the linked image will not play';
export const SHARE_LINK_ACCORDION_TITLE = 'Share link';
export const SHARE_LINK_ACCORDION_DESCRIPTION = 'Anyone with the link can view';
export const SHARE_BUTTON_COPY_LINK = 'Copy link';
export const SHARE_VIA_EMAIL_TITLE = 'Share via email';
export const EMBED_LINK_ACCORDION_TITLE = 'Embed code';
export const SCRIPT_SHARE_ACCORDION_TITLE = 'Script';
export const SCRIPT_SHARE_CONFIGURE_HEADER_LINK_TEXT = 'Configure your header script';
export const EMBED_LINK_ACCORDION_DESCRIPTION = 'Simple embed anywhere on the web';
export const SCRIPT_SHARE_ACCORDION_DESCRIPTION =
	'Paste the following code inside the element where you want to view this flo.';
export const SCRIPT_SHARE_COPY_SUCCESS_TOAST_MESSAGE = 'Code copied to clipboard.';
export const EMBED_BUTTON_COPY_CODE = 'Copy code';
export const EMBED_DROPDOWN_TITLE = 'Platform';
export const DOWNLOAD_MP4_ACCORDION_TITLE = 'Export';
export const DOWNLOAD_GIF_ACCORDION_TITLE = 'Export GIF';
export const DOWNLOAD_PREVIEW_ACCORDION_TITLE = 'Share via email';
export const DOWNLOAD_AS_GIF = 'Download GIF';
export const DOWNLOAD_GIF_PREVIEW = 'Download the GIF Preview';
export const GIF_PREVIEW_GMAIL_FLO = 'How to add a clickable GIF in Gmail';
export const GIF_PREVIEW_GMAIL_FLO_LINK =
	'https://www.floik.com/help/share/How-To-Attach-A-Gif-With-A-Link-In-Gmail-d.html?show-author=false';
export const GIF_PREVIEW_OUTLOOK_FLO = 'How to add a clickable GIF in Outlook';
export const GIF_PREVIEW_OUTLOOK_FLO_LINK =
	'https://www.floik.com/help/share/How-To-Attach-A-Gif-With-A-Link-In-Outlook-d.html?show-author=false';
export const DOWNLOAD_MP4_WARNING =
	'MP4 download may not fully capture the online experience. Some effects or quality may differ. Share the Flo link for best results.';
export const DOWNLOAD_PREVIEW_ACCORDION_DESCRIPTION =
	'Attach a GIF preview with a clickable Flo link in your email.';
export const DOWNLOAD_MP4_ACCORDION_DESCRIPTION = 'Download';
export const DOWNLOAD_BUTTON = 'Download';
export const DOWNLOAD_AS_MP4 = 'Download as MP4';
export const DEFAULT_EXAMPLE_FLO_HEADING = 'How to embed in %PRODUCT%?';
export const LINK_NOT_READY = 'Link is not ready, please try later';
export const DOWNLOAD_PDF_ACCORDION_TITLE = 'Export as PDF';
export const DOWNLOAD_HTML_ACCORDION_TITLE = 'Export as HTML';
export const DOWNLOAD_MARKDOWN_ACCORDION_TITLE = 'Export as MARKDOWN';
export const DOWNLOAD_AS_PDF = 'Download as PDF';
export const DOWNLOAD_AS_HTML = 'Download as HTML';
export const DOWNLOAD_AS_MARKDOWN = 'Download as MARKDOWN';
export const FLO_PAGE_EDIT_HOTSPOT_DISABLED_TOOLTIP = 'Hotspot is disabled';
export const FLO_PAGE_EDIT_TEXT_DISABLED_TOOLTIP = 'Text is disabled';
export const FLO_PAGE_EDIT_HOTSPOT_EDIT_TOOLTIP = 'Edit';
export const FLO_SHARE_TAB_AUTHOR_LABEL = 'Author';
export const FLO_SHARE_TAB_TOC_LABEL = 'Table of content';
export const FLO_SHARE_TAB_SWIFT_MODE_LABEL = 'Swift Play';
export const FLO_SHARE_AUTO_PLAY_MODE_LABEL = 'Auto Play';
export const FLO_SHARE_TAB_SUBTITLES_LABEL = 'Include subtitles';
export const FLO_SHARE_TAB_ID_SWIFT_PLAY_LABEL = 'Swift Play';
export const SHAREFLO_START_TIME_TITLE = 'Start time';
export const SHAREFLO_DURATION_TITLE = 'Duration';
export const SHAREFLO_QUALITY_TITLE = 'Quality';
export const SHAREFLO_QUALITY_LOW = 'Low';
export const SHAREFLO_QUALITY_MEDIUM = 'Medium';
export const SHAREFLO_QUALITY_HIGH = 'High';
export const EMBED_CODE_DOCUMENT_HELPTEXT =
	'/* Note: You can change the height value as per your need to show more steps without having inner scroll */';
export const EMBED_CODE_VIDEO_HELPTEXT =
	'/* Note: Other height values\n       Based on % of actual height of the Flo: %IFRAME_HEIGHT_100% (100%),  %IFRAME_HEIGHT_90% (90%), %IFRAME_HEIGHT_80% (80%), %IFRAME_HEIGHT_50% (50%), %IFRAME_HEIGHT_30% (30%)\n       You can use any value for height and the width will automatically adjust to maintain the aspect ratio*/';
export const GUIDE_BLUR_NO_IMAGE_ERROR_HEADER = 'Blur';
export const GUIDE_BLUR_NO_IMAGE_ERROR_BODY =
	'No slide found for the selected step, Please add a slide for blur to be applied';
export const GUIDE_SPOTLIGHT_NO_IMAGE_ERROR_BODY =
	'No slide found for the selected step, Please add a slide for spotlight to be applied';
export const GUIDE_IMAGE_NO_SLIDE_IMAGE_ERROR_BODY =
	'No slide found for the selected step, Please add a slide for image to be added';
export const GUIDE_DOCUMENT_IMAGE_NO_IMAGE_ERROR_HEADER = 'Image';
export const GUIDE_DOCUMENT_IMAGE_NO_IMAGE_ERROR_BODY =
	'No slide found for the selected step, Please add a slide for image element to be added';

export const GUIDE_BLUR_NO_SLIDE_DROPDOWN_ITEM_ERROR = 'No slide found in this step';
export const GUIDE_IMAGE_NO_SLIDE_DROPDOWN_ITEM_ERROR = 'No image found in this step';
export const GUIDE_SPOTLIGHT_NO_SLIDE_DROPDOWN_ITEM_ERROR = 'No slide found in this step';

export const GUIDE_BLUR_RESOLUTION_MISMATCH_DROPDOWN_ITEM_ERROR =
	'Image resolution do not match with that of the selected step';

export const GUIDE_BLUR_STEP_CHANGE_DIALOG_HEADER = 'Change Step?';
export const GUIDE_BLUR_STEP_CHANGE_DIALOG_DESCRIPTION =
	'Changing step will reset all changes done to this element. Would you like to continue?';
export const GUIDE_BLUR_STEP_CHANGE_DIALOG_CONFIRM_BUTTON_TEXT = 'Continue';
export const GUIDE_BLUR_STEP_CHANGE_DIALOG_CANCEL_BUTTON_TEXT = 'Cancel';

export const GUIDE_SPOTLIGHT_STEP_CHANGE_DIALOG_HEADER = 'Change Step?';
export const GUIDE_SPOTLIGHT_STEP_CHANGE_DIALOG_DESCRIPTION =
	'Changing step will reset all changes done to this spotlight element. Would you like to continue?';
export const GUIDE_SPOTLIGHT_STEP_CHANGE_DIALOG_CONFIRM_BUTTON_TEXT = 'Continue';
export const GUIDE_SPOTLIGHT_STEP_CHANGE_DIALOG_CANCEL_BUTTON_TEXT = 'Cancel';

export const GUIDE_IMAGE_STEP_CHANGE_DIALOG_HEADER = 'Change Step?';
export const GUIDE_IMAGE_STEP_CHANGE_DIALOG_DESCRIPTION =
	'Changing step will reset all changes done to this element. Would you like to continue?';
export const GUIDE_IMAGE_STEP_CHANGE_DIALOG_CONFIRM_BUTTON_TEXT = 'Continue';
export const GUIDE_IMAGE_STEP_CHANGE_DIALOG_CANCEL_BUTTON_TEXT = 'Cancel';

export const GUIDE_IMAGE_EXIST_STEP_CHANGE_DIALOG_HEADER = 'Change Step?';
export const GUIDE_IMAGE_EXIST_STEP_CHANGE_DIALOG_DESCRIPTION =
	'Select a step without image element in it';
export const GUIDE_IMAGE_EXIST_STEP_CHANGE_DIALOG_CONFIRM_BUTTON_TEXT = 'Okay';
export const GUIDE_IMAGE_EXIST_STEP_CHANGE_DIALOG_CANCEL_BUTTON_TEXT = 'Cancel';

export const GUIDE_BLUR_NO_STEP_SELECTED_IMAGE_ERROR_HEADER = 'Blur';
export const GUIDE_BLUR_NO_STEP_SELECTED_IMAGE_ERROR_BODY =
	'Select step before adding blur';
export const IMAGE_CTA_NOT_FOUND = 'CTA is no longer available for this image';
export const IMAGE_ELEMENT_DROPPED_OUTSIDE =
	'Image is outside the video, do you want to delete this element?';
export const DOCUMENT_IMAGE_ELEMENT_DROPPED_OUTSIDE =
	'Image is outside the step image, do you want to delete this element?';
export const TEXT_ELEMENT_DROPPED_OUTSIDE =
	'Text is outside the video, do you want to delete this element?';

export const IMAGE_ELEMENT_ROTATED_OUTSIDE =
	'Image will be outside the video on rotating, do you want to delete this element?';
export const DOCUMENT_IMAGE_ELEMENT_ROTATED_OUTSIDE =
	'Image will be outside the step image on rotating, do you want to delete this element?';
export const STEP_ANIMATE_CLICK_DISABLED_MESSAGE =
	'Click animation is disabled as the step has a custom slide image.';

export const FLO_PAGE_IMAGE_TOOLTIP_OUTSIDE_RANGE = 'Image is outside selected area';

export const FLO_PAGE_CTA_TOOLTIP_PARTIAL_RANGE = FLO_PAGE_CTA_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_CLICK_TOOLTIP_PARTIAL_RANGE = FLO_PAGE_CLICK_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_HOTSPOT_TOOLTIP_PARTIAL_RANGE =
	FLO_PAGE_HOTSPOT_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_IMAGE_TOOLTIP_PARTIAL_RANGE =
	'Image is partially outside the video area, it will get cut off.';
export const FLO_PAGE_BLUR_TOOLTIP_PARTIAL_RANGE = FLO_PAGE_BLUR_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_SPOTLIGHT_TOOLTIP_PARTIAL_RANGE =
	FLO_PAGE_SPOTLIGHT_TOOLTIP_OUTSIDE_RANGE;

export const FLO_PAGE_CTA_TOOLTIP_INVALID_DATA = FLO_PAGE_CTA_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_CLICK_TOOLTIP_INVALID_DATA = FLO_PAGE_CLICK_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_HOTSPOT_TOOLTIP_INVALID_DATA =
	FLO_PAGE_HOTSPOT_TOOLTIP_OUTSIDE_RANGE;
export const FLO_PAGE_IMAGE_TOOLTIP_INVALID_DATA = 'Please update the image position';
export const FLO_PAGE_BLUR_TOOLTIP_INVALID_DATA = FLO_PAGE_BLUR_TOOLTIP_OUTSIDE_RANGE;

export const INTERACTIONS_ELEMENT_UNLINKED = 'unlinked';
export const ELEMENT_MILLISECOND_TUNE_TITLE = 'Finetune Milliseconds';
export const GALLERY_MILLISECOND_TUNE_TITLE = 'Manually select frame';
export const GALLERY_MILLISECONDS_MULTIPLIER = 5;

export const STEP_IMAGE_UPLOADED_WILL_DELETE_IMAGE_ELEMENT_TITLE = 'Replace image';
export const STEP_IMAGE_UPLOADED_WILL_DELETE_IMAGE_ELEMENT_BODY =
	'Replacing image will remove the image element for this step, do you want to proceed?';

export const FLOIK_BETA_LABEL = 'Beta';

export const ERROR_CUSTOM_FILE_UPLOAD_FILE_SIZE = 'Please upload a file less than 300mb';
export const ERROR_CUSTOM_FILE_UPLOAD_RESOLUTION =
	'Please upload a file less than 2k resolution';
export const ERROR_CUSTOM_FILE_DURATION = 'Please upload a file less than 10min';
export const ERROR_CUSTOM_FILE_UPLOAD = 'Failed to upload video';
export const FILE_UPLOAD_DRAG_AND_DROP_DESCRIPTION = 'Drag and drop a file to upload';
export const FILE_UPLOAD_FILES_TYPE = 'MP4, MOV up to 300mb';
export const FILE_UPLOAD_UNSUPPORTED_FORMAT = 'Only MP4, MOV up to 300mb';
export const FILE_UPLOAD_BROWSE_FILES = 'Browse files';
export const FILE_UPLOAD_TAKES_FEW_SECOND_MESSAGE = 'This might take few seconds';
export const FILE_UPLOAD_UPLOADING_MESSAGE = 'Uploading';
export const FILE_UPLOAD_UPLOADED_MESSAGE = 'Uploaded';
export const FILE_UPLOAD_UPLOAD_SPEED_MESSAGE = 'Upload speed';
export const FILE_UPLOAD_DROP_FILE_MESSAGE = 'Drop here';

export const MAX_MANUAL_UPLOAD_LIMIT = '10';

export const FILE_UPLOAD_CANCEL_POPUP_HEADING = 'Upload is in progress';
export const FILE_UPLOAD_CANCEL_POPUP_DESCRIPTION = 'Do you want to cancel the upload?';
export const FILE_UPLOAD_CANCEL_POPUP_CONFIRM_TEXT = 'Confirm';
export const FILE_UPLOAD_CANCEL_POPUP_CANCEL_TEXT = 'Cancel';
export const DIAMOND_ICON_PREMIUM_TOOLTIP_TEXT = 'Premium feature';
export const IMG_UPLOAD_TOOLTIP = 'Click to upload an image';
