import { FloCreationTemplates } from '../../Common/Common.types';
import { CropAreaType } from '../../Components/Annotations/Annotations.types';
import { floTrackType } from '../Flo/Flo.types';

export type RecorderOptions =
	| 'screen'
	| 'screencamera'
	| 'camera'
	| 'screenshot'
	| 'screenmicrophone'
	| 'screencameramicrophone'
	| 'cameramicrophone';

export const optionKeys: {
	[key: string]: RecorderOptions;
} = {
	screenOnly: 'screen',
	screen: 'screenmicrophone',
	screenCam: 'screencamera',
	screenCamMic: 'screencameramicrophone',
	cameraOnly: 'camera',
	camera: 'cameramicrophone',
	screenShot: 'screenshot',
};

export type RecordStates =
	| 'stopped'
	| 'creating'
	| 'recording'
	| 'saving'
	| 'onDiscardSaving'
	| 'onStopSaving'
	| 'stopped/save';

export type setFloStateType = {
	tracks: floTrackType[];
	duration?: number;
};

export type TrimStateType = {
	enable: boolean;
	values?: string[] | null;
	timeValue?: number[];
	rangeValues?: number[];
};

export type CropStateType = {
	enable: boolean;
	values?: CropAreaType | null;
	videoSize: {
		width: number;
		height: number;
	};
};

export interface PostProcessConfigType {
	crop: CropStateType;
	trim: TrimStateType;
}

export interface NewFlosReducerType {
	floStreams: MediaStream[];
	name?: string;
	hasFailedMessage?: string;
	templates: FloCreationTemplates[];
	template?: FloCreationTemplates;
	templateFilter: string;
	showTemplateDeviceSelection: boolean;
	cacheTemplates: {
		[key: string]: FloCreationTemplates[];
	};
	templateCategories: TemplateCategoryType[];
	elementId: string;
	extensionRecordingInProgress: boolean;
	uploadSpeed: number;
	uploadSpeedData: {
		[key: string]: [number, number];
	};
	videoFileDuration?: number;
	viedoFileName?: string;
	viedoFileSize?: string;
	videoFileID?: string;
	videoFileUploadError?: string;
	videoFileMeta?: {
		screenShare: {
			height: number;
			width: number;
		};
	};
	noOfClicks: number;
	showPopup: boolean;
	canDelete: boolean;
	isOnline: boolean;
	hasFailedAttempt: boolean;
	floId: string;
	countDownTrack: string;
	stopSoundTrack: string;
	state: RecordStates;
	customFlo?: boolean;
	type?: RecorderOptions;
	chunkStats: {
		screenShareTotalSize: number;
		screenShareUploadedSize: number;
		videoTotalSize: number;
		videoUploadedSize: number;
	};
	trackIds: {
		screenShare?: string;
		multiScreenShare?: string;
		camera?: string;
		multiCamera?: string;
	};
	captureSize: {
		video: {
			width: number;
			height: number;
		};
		screenShare: {
			width: number;
			height: number;
		};
	};
	canShowNewFlo: boolean;
	duration: number;
	tracks: floTrackType[];
	postProcessConfigs: PostProcessConfigType;
}

export type AvailableDevices = {
	perms?: {
		[key: string]: string;
	};
	devicesAudio: MediaDeviceInfo[];
	devicesVideo: MediaDeviceInfo[];
	previewStreams?: MediaStream | null;
	inputDevices?: {
		[key: string]: string | undefined;
	};
};

export type TemplateCategoryType = {
	id?: string;
	label: string;
	key: string;
	nonClickable?: boolean;
};
