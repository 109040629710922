import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import {
	debounce,
	forEach,
	get,
	includes,
	isFinite,
	isFunction,
	max,
	min,
	omit,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import { checkTabletIpadPlatform } from '../../utils/IsPhone';
import {
	calculateAspectRatioFit,
	calculateVideoCropMatrix,
} from './../../utils/video.utils';
import './VideoPlayer.css';
import styles from './VideoPlayer.module.css';
import { IVideoPlayerProps } from './VideoPlayer.type';
import './vtt.plugin';
import KeyboardEvent = videojs.KeyboardEvent;

import moment from 'moment';
import { FLO_ACTIVE_TAB_EDIT } from '../../Pages/Flo/Flo.constants';
import { setDuration, setTrimCurrentTime } from '../../Pages/Flo/Flo.reducers';
import { floOutputMetadataType } from '../../Pages/Flo/Flo.types';
import CustomControls from './CustomControls/CustomControls';

const playbackRates = [0.5, 1, 1.5, 2];
const initialOptions: videojs.PlayerOptions = {
	controls: false,
	fluid: false,
	playbackRates,
	controlBar: {
		remainingTimeDisplay: true,
		durationDisplay: true,
		currentTimeDisplay: true,
		fullscreenToggle: false,
		pictureInPictureToggle: false,
		volumePanel: {
			inline: false,
		},
	},
	userActions: {
		doubleClick: false,
	},
};

const isHandHeldDevice = checkTabletIpadPlatform();
const timeout = isHandHeldDevice ? 1000 : 300;

export const Video: React.FC<Partial<IVideoPlayerProps>> = ({
	style,
	hasAudioTrack,
	id,
	showSubTitle,
	addWrapper,
	options,
	containerRef,
	setError,
	ignoreCropAndTrim,
	setReady,
	mute,
	volume,
	nodeRef,
	className,
	setControls,
	onPlay,
	onUpdate,
	onPause,
	onSeeked,
	onVolumeChange,
	defaultOptions,
	setPlayerDim,
	setPlayerRef,
	appliedCrop,
	offsetWidth,
	offsetHeight,
	isAudioWaveAvailable,
	// onTimeUpdate,
	onPlaybackRatesChange,
	onChangeTime: onChangeTimeProp,
	canShowCustomControls,
	customControlsChildren,
	setCroppedVideoStyles,
	videoSize,
	duration,
	hideProgressBar,
	// onClickProgressBar,
	onSeeking,
	setVideoReady,
	fullHeight,
	useVideoSizeForFit,
}) => {
	const showingEditCta = useSelector((state) =>
		get(state, 'floPage.activeEditElement.show')
	);
	const dispatch = useDispatch();
	const player = React.useRef<videojs.Player>();
	const defaultProps = defaultOptions || initialOptions;
	const [localReady, setLocalReady] = useState(false);
	const [containerDim, setContainerDim] = useState({
		width: get(defaultOptions, 'width') || 0,
		height: get(defaultOptions, 'height') || 0,
	});
	const progressRef = React.useRef<HTMLDivElement>();
	const durationRef = React.useRef<HTMLDivElement>();
	const trimConfig = useSelector((state) =>
		get(state, 'floPage.postProcessConfigs.trim')
	);
	const cropConfig = useSelector((state) =>
		get(state, 'floPage.postProcessConfigs.crop')
	);
	const floId = useSelector((state) => get(state, 'floPage.floDetails.id'));
	const isTrimEnable = !ignoreCropAndTrim && get(trimConfig, 'enable');
	const isCropEnable = !ignoreCropAndTrim && get(cropConfig, 'enable');
	const timeValue = ignoreCropAndTrim ? [] : get(trimConfig, 'timeValue', []);
	const originalLength = get(trimConfig, 'originalLength');
	const isPublished: floOutputMetadataType = useSelector((state) =>
		get(state, 'floPage.currentMetadata.published')
	);
	const activeTab = useSelector(
		(state) => get(state, 'floPage.tabActive') || FLO_ACTIVE_TAB_EDIT
	);
	const rangeValues = ignoreCropAndTrim ? [] : get(trimConfig, 'rangeValues');
	const containerWidth = containerRef?.current?.offsetWidth || 0;
	const onVolumeChangeRef = useRef(onVolumeChange);

	useEffect(() => {
		onVolumeChangeRef.current = onVolumeChange;
	}, [onVolumeChangeRef, onVolumeChange]);

	useEffect(() => {
		//@ts-ignore
		if (
			!player?.current ||
			!isFinite(player.current.duration() ?? NaN) ||
			!canShowCustomControls ||
			!progressRef?.current
		)
			return;

		let newDuration = duration || get(trimConfig, 'originalLength');
		// if (isTrimEnable) {
		// 	newDuration = duration || get(trimConfig, 'originalLength');
		// player?.current?.duration(newDuration);
		if (newDuration) {
			dispatch(setDuration(newDuration));
		}
		// 	player?.current?.currentTime(timeValue[0] || 0);
		// } else {
		// 	if (isEmpty(timeValue)) return;
		// 	newDuration = timeValue[1];
		// 	player?.current?.duration(newDuration);
		// 	player?.current?.currentTime(timeValue[0] || 0);
		// }
	}, [
		isTrimEnable,
		originalLength,
		timeValue,
		duration,
		player?.current?.duration,
		player?.current?.currentTime,
		canShowCustomControls,
		progressRef?.current,
	]);

	useEffect(() => {
		try {
			if (setReady) {
				setReady(false);
			}
			const source = get(options, 'sources[0].src');
			if (source && player.current) {
				console.log(get(options, 'sources[0]'));
				player.current.src(get(options, 'sources[0]'));
				if (get(options, 'tracks[0]')) {
					// @ts-ignore
					player.current?.addRemoteTextTrack(get(options, 'tracks[0]'), false);
				}
			}
		} catch (e) {
			console.warn(e);
		}
	}, [setReady, get(options, 'sources[0].src'), player]);

	useEffect(() => {
		if (player.current) {
			if (player.current?.remoteTextTracks) {
				const textTracks = player.current?.remoteTextTracks();
				forEach(textTracks, (track) => {
					// @ts-ignore
					track && textTracks.removeTrack(track);
				});
			}
			if (get(options, 'tracks[0]')) {
				// @ts-ignore
				player.current?.addRemoteTextTrack(get(options, 'tracks[0]'), false);
			}
		}
	}, [get(options, 'tracks[0].src'), player?.current]);

	useEffect(() => {
		setContainerDim({
			width: get(defaultOptions, 'width') || 0,
			height: get(defaultOptions, 'height') || 0,
		});
		try {
			if (localReady && player.current) {
				player.current?.width(get(defaultOptions, 'width') || 0);
				player.current?.height(get(defaultOptions, 'height') || 0);
			}
		} catch (e) {
			console.warn('failed to set height and width to player', player);
		}
	}, [defaultOptions, localReady, player, setContainerDim]);
	const resizeHandler = useCallback(() => {
		try {
			if (!setPlayerDim || !containerRef) {
				return;
			}
			// let videoRatio = width / height;
			const containerWidth = containerRef?.current?.offsetWidth || 0;
			let containerHeight = containerRef?.current?.offsetHeight || 0;

			const videoDimensions = useVideoSizeForFit
				? videoSize
				: {
						width: 16,
						height: 9,
				  };
			containerHeight = containerHeight - (fullHeight ? 0 : 120);
			const { width: oWidth, height: oHeight } = calculateAspectRatioFit(
				videoDimensions?.width || 16,
				videoDimensions?.height || 9,
				max([16, containerWidth]) as number,
				max([9, containerHeight]) as number
			);
			try {
				if (localReady && player.current && get(options, 'sources[0].src')) {
					player.current?.height(oHeight);
					player.current?.width(oWidth);
				}
			} catch (e) {
				console.warn('failed to set height and width to player', player);
			}

			setContainerDim({
				height: oHeight,
				width: oWidth,
			});
			const offsetHeight = Math.abs((oHeight - containerHeight) / 2);
			const offsetWidth = Math.abs(oWidth - containerWidth);
			setPlayerDim({
				offsetWidth,
				offsetHeight,
				height: oHeight,
				width: oWidth,
			});
			if (setCroppedVideoStyles) {
				setCroppedVideoStyles(
					calculateVideoCropMatrix(
						{
							height: oHeight,
							width: oWidth,
						},
						videoSize || appliedCrop?.videoSize,
						activeTab !== FLO_ACTIVE_TAB_EDIT ? appliedCrop : undefined,
						activeTab !== FLO_ACTIVE_TAB_EDIT ? isCropEnable : false
					)
				);
			}
			if (setPlayerRef) setPlayerRef(nodeRef?.current);
		} catch (e) {
			console.warn(e);
		}
	}, [
		activeTab,
		videoSize,
		ignoreCropAndTrim,
		setContainerDim,
		setCroppedVideoStyles,
		offsetWidth,
		offsetHeight,
		appliedCrop,
		isCropEnable,
		containerRef,
		setPlayerRef,
		nodeRef,
		player,
	]);

	useEffect(() => {
		if (!containerWidth) return;
		resizeHandler();
	}, [containerWidth, isCropEnable]);
	useEffect(() => {
		const throttledHandler = debounce(resizeHandler, timeout);
		window.addEventListener('resize', throttledHandler, true);
		// throttledHandler();
		return () => {
			window.removeEventListener('resize', throttledHandler, true);
		};
	}, [resizeHandler]);

	const userHotKeys = useCallback(
		(event: KeyboardEvent) => {
			if (!setPlayerRef || !player.current || showingEditCta) return;
			switch (event.key) {
				case 'ArrowLeft': {
					const ct = player.current?.currentTime() || 0;
					player.current?.currentTime(max([ct - 5, 0]) as number);
					break;
				}
				case 'ArrowRight': {
					const duration = player.current?.duration();
					const ct = player.current?.currentTime() || 0;
					player.current?.currentTime(min([ct + 5, duration]) as number);
					break;
				}
				case 'ArrowUp': {
					const volume = player.current?.volume();
					const setVolume = min([1, volume + 0.5]) as number;
					if (isFunction(onVolumeChangeRef?.current)) {
						onVolumeChangeRef.current({
							isMuted: !setVolume,
							volume: setVolume,
						});
					}
					break;
				}
				case 'ArrowDown': {
					const volume = player.current?.volume();
					const setVolume = min([1, volume - 0.5]) as number;
					if (isFunction(onVolumeChangeRef?.current)) {
						onVolumeChangeRef.current({
							isMuted: !setVolume,
							volume: setVolume,
						});
					}
					break;
				}
				case ' ': {
					const isPaused = player.current?.paused();
					if (isPaused) {
						player.current?.play();
					} else {
						player.current?.pause();
					}
					break;
				}
				case 'm': {
					if (isFunction(onVolumeChangeRef?.current)) {
						onVolumeChangeRef.current({
							isMuted: true,
							volume: 0,
						});
					}
					break;
				}
				case ',': {
					const currRate = player.current?.playbackRate();
					const index = playbackRates.indexOf(currRate);
					const newRate =
						playbackRates[max([0, (index - 1) % playbackRates.length]) as number];
					player.current?.playbackRate(newRate);
					break;
				}
				case '.': {
					const currRate = player.current?.playbackRate();
					const index = playbackRates.indexOf(currRate);
					if ((index + 1) % playbackRates.length === 0) return;
					const newRate =
						playbackRates[
							min([
								(index + 1) % playbackRates.length,
								playbackRates.length - 1,
							]) as number
						];
					player.current?.playbackRate(newRate);
					break;
				}
			}
		},
		[setPlayerRef, player, showingEditCta, onVolumeChangeRef]
	);

	const setProgressRef = useCallback(
		(newDuration: number, currentTime: number) => {
			if (!progressRef?.current) return;
			// @ts-ignore
			progressRef.current.style.width = `${
				// @ts-ignore
				(currentTime / newDuration) * 100
			}%`;
			const duration = moment.duration(newDuration || 0, 'seconds');
			let valueTotal = moment
				.utc(duration.as('milliseconds'))
				// @ts-ignore
				.format(((newDuration || 0) >= 60 * 60 ? 'HH:' : '') + 'mm:ss');

			if (!moment.utc(duration.as('milliseconds')).isValid()) {
				valueTotal = '--:--';
			}

			const currentDuration = moment.duration(
				// @ts-ignore
				currentTime || 0,
				'seconds'
			);

			let valueCurrent = moment
				.utc(currentDuration.as('milliseconds'))
				// @ts-ignore
				.format(((currentTime || 0) >= 60 * 60 ? 'HH:' : '') + 'mm:ss');

			const currentValueIsValid = moment
				.utc(currentDuration.as('milliseconds'))
				.isValid();
			if (!currentValueIsValid) {
				valueCurrent = '--:--';
			}
			if (durationRef.current) {
				// @ts-ignore
				durationRef.current.innerText = `${valueCurrent} / ${valueTotal}`;
			}
		},
		[progressRef, timeValue, durationRef]
	);

	useEffect(() => {
		if (className === 'previewTrack') console.log('previewTrack options', options);
		if (!get(options, 'sources[0].src') || !nodeRef?.current) return;

		// @ts-ignore
		if (!player.current)
			videojs(nodeRef.current!, {
				...defaultProps,
				...omit(options, ['tracks']),
				html5:
					className === 'previewTrack'
						? {}
						: {
								vhs: {
									smoothQualityChange: true,
									withCredentials:
										process.env.REACT_APP_ENABLE_HLS_CREDENTIALS === 'true',
									overrideNative: true,
								},
						  },
				userActions: {
					hotkeys: userHotKeys,
					doubleClick: false,
				},
			}).ready(function () {
				if (className === 'previewTrack') console.log('previewTrack ready');
				player.current = this;
				// @ts-ignore
				if (isFunction(this.hlsQualitySelector)) {
					// @ts-ignore
					this.hlsQualitySelector({
						displayCurrentQuality: true,
					});
				}
				// @ts-ignore
				if (get(options, 'sources[0].hasThumbnails') && isFunction(this.vttThumbnails)) {
					// @ts-ignore
					this.vttThumbnails({
						src: get(options, 'sources[0].thumbnailsPath'),
					});
				}
				this.playbackRate(1);
				if ([Infinity, NaN].includes(this.duration())) {
					const seeking = () => {
						this.off('seeking', seeking);
					};
					this.on('seeking', seeking);
					const seeked = () => {
						this.off('seeked', seeked);
						this.currentTime(0);
						if (setVideoReady) {
							setVideoReady(true);
						}
						if (setProgressRef) {
							const currDuration = this.duration();
							setProgressRef(isFinite(currDuration) ? currDuration : duration || 0, 0);
						}
					};
					this.on('seeked', seeked);
					this.currentTime(Number.MAX_SAFE_INTEGER);
					if (setVideoReady) {
						setVideoReady(false);
					}
				}
				if (setControls) {
					setControls(this);
				}
				this.bigPlayButton.on('click', () => {
					if (onUpdate) onUpdate();
				});
				this.on('play', () => {
					if (onUpdate) onUpdate();
					this.bigPlayButton.hide();
				});
				this.on('loadedmetadata', () => {
					if (setProgressRef) {
						const currDuration = this.duration();
						setProgressRef(isFinite(currDuration) ? currDuration : duration || 0, 0);
					}
					if (setReady) {
						setReady(true);
					}
					if (get(options, 'autoplay') === 'muted' && player.current?.paused()) {
						player.current?.play();
					}
					console.log('** onChangeTimeProp **');
					onChangeTimeProp?.();
				});
				this.on('error', () => {
					// @ts-ignore
					if (setError) setError(true);
				});
				this.on('ratechange', () => {
					if (onPlaybackRatesChange) {
						onPlaybackRatesChange(this.playbackRate());
					}
				});
				this.on('pause', () => {
					if (onUpdate) onUpdate();
					this.bigPlayButton.show();
				});
				this.on('volumechange', () => {
					if (this.volume && onVolumeChange) {
						onVolumeChange({
							from: 'videoPlayer',
							isMuted: this.muted(),
							volume: this.volume(),
						});
					}
				});
				setLocalReady(true);
				// @ts-ignore
				if (setPlayerRef) {
					setTimeout(() => {
						resizeHandler();
					}, 1000);
				}
			});
	}, [
		onChangeTimeProp,
		setError,
		resizeHandler,
		// @ts-ignore
		setProgressRef,
		duration,
		userHotKeys,
		setReady,
		get(options, 'sources[0].src'),
		setLocalReady,
		setPlayerDim,
		// @ts-ignore
		nodeRef,
		player,
	]);

	const onChangeTime = useCallback(
		(e: SyntheticEvent<HTMLVideoElement, Event>) => {
			try {
				if (isFunction(onChangeTimeProp)) onChangeTimeProp();
				if (!player?.current || !canShowCustomControls) return;

				// @ts-ignore
				let playerdDuration = isFinite(player.current.duration())
					? player.current.duration()
					: duration || 0;
				// @ts-ignore
				let currentTime = player.current.currentTime();
				setVideoCurrentTime(e, originalLength);
				if (progressRef.current) {
					// @ts-ignore
					let newDuration = playerdDuration;
					// @ts-ignore
					let newCurrentTime = currentTime;

					setProgressRef(newDuration, newCurrentTime);
				}
			} catch (e) {
				console.warn(e);
			}
		},
		[
			onChangeTimeProp,
			// @ts-ignore
			setProgressRef,
			timeValue,
			duration,
			canShowCustomControls,
			player?.current?.currentTime,
			player?.current?.duration,
			rangeValues,
			isTrimEnable,
			originalLength,
		]
	);

	const setVideoCurrentTime = useCallback(
		(event: unknown, originalLength: number) => {
			if (!event) return;
			// @ts-ignore
			let currentTime = (event.target.currentTime / duration) * 100;
			dispatch(
				setTrimCurrentTime({
					currentTime,
					// @ts-ignore
					currentTimeSeconds: event.target.currentTime,
				})
			);
		},
		[duration]
	);

	const onClickProgressBar = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) => {
			const target = document.getElementById('floikProgressBar');
			// @ts-ignore
			const { width } = target.getBoundingClientRect();
			const clientX = get(e, 'nativeEvent.offsetX', 0);

			let newDuration = player?.current?.duration();
			if (!newDuration) return;
			const ratio = clientX / width;
			const currentTime = ratio * newDuration;
			player?.current?.currentTime(currentTime);
		},
		[isTrimEnable, timeValue, player?.current]
	);

	const opacity = !containerDim.width || !containerDim.height ? 0 : 1;
	const isWebm = includes(get(options, 'sources[0].src'), 'webm');
	let video = (
		<video
			// @ts-ignore
			ref={nodeRef}
			style={{
				...(addWrapper
					? {}
					: {
							width: containerDim.width,
							height: containerDim.height,
							opacity,
					  }),
			}}
			crossOrigin={
				isWebm || process.env.REACT_APP_ENABLE_HLS_CREDENTIALS !== 'true'
					? 'anonymous'
					: 'use-credentials'
			}
			id={id}
			onSeeked={onSeeked}
			onSeeking={onSeeking}
			className={classNames('video-js', className, styles.mainVideoPlayer)}
			onContextMenu={(e) => e.preventDefault()}
			onTimeUpdate={onChangeTime}
			onLoadedMetadata={onChangeTime}
		/>
	);

	if (canShowCustomControls) {
		video = (
			<CustomControls
				style={{
					width: containerDim.width,
					height: containerDim.height,
					opacity,
				}}
				hideProgressBar={hideProgressBar}
				hasAudioTrack={hasAudioTrack}
				volume={volume}
				onPlay={onPlay}
				onPause={onPause}
				nodeRef={nodeRef}
				progressRef={progressRef}
				durationRef={durationRef}
				onVolumeChange={onVolumeChange}
				customControlsChildren={customControlsChildren}
				onClickProgressBar={onClickProgressBar}
			>
				<div className={styles.videoWrapper}>{video}</div>
			</CustomControls>
		);
	}

	if (addWrapper) {
		return (
			<div
				style={{
					...(style || {}),
					width: containerDim.width,
					height: containerDim.height,
					opacity,
				}}
			>
				{video}
			</div>
		);
	}
	return video;
};

export default Video;
