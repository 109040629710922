import Translations from '../../../../../Common/Translate.utils';

export const NO_VOICE = 'NO_VOICE';
export const NO_VOICE_KEY = 'NO_VOICE';
export const ORIGINAL_VOICE_KEY = 'ORIGINAL_VOICE';
export const SYNTHESISED_AI_VOICE_KEY = 'AI_VOICE';
export const PUBLIC_SHARE_LINK_TYPE_KEY = 'PUBLIC_SHARE_LINK';
export const PRIVATE_SHARE_LINK_TYPE_KEY = 'PRIVATE_SHARE_LINK';

export const {
	ORIGINAL_VOICE,
	SYNTHESISED_AI_VOICE,
	PUBLIC_SHARE_LINK,
	PRIVATE_SHARE_LINK,
} = Translations;

export const DATE_VALUE = '2022-04-17T00:00';
export const VOICE_TYPES = [
	{
		key: ORIGINAL_VOICE_KEY,
		label: ORIGINAL_VOICE,
		translateKey: 'ORIGINAL_VOICE',
	},
	{
		key: SYNTHESISED_AI_VOICE_KEY,
		label: SYNTHESISED_AI_VOICE,
		translateKey: 'SYNTHESISED_AI_VOICE',
	},
	{
		key: NO_VOICE,
		label: NO_VOICE,
		translateKey: 'NO_VOICE',
	},
];

export const SHARE_LINK_TYPES = [
	{
		key: PUBLIC_SHARE_LINK_TYPE_KEY,
		label: PUBLIC_SHARE_LINK,
		translateKey: 'PUBLIC_SHARE_LINK',
	},
	{
		key: PRIVATE_SHARE_LINK_TYPE_KEY,
		label: PRIVATE_SHARE_LINK,
		translateKey: 'PRIVATE_SHARE_LINK',
	},
];
