import { Box, Button, Input } from '@mui/material';
import classNames from 'classnames';
import { get, throttle } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uniqId from 'uniqid';
import uploadImage from '../../Common/images/UploadImg.png';
import Translations from '../../Common/Translate.utils';
import { setErrorToast } from '../Notification/Toasts.reducers';
import Switch from '../Switch/Switch';
import styles from './BrandingDialog.module.css';
import {
	getBrandingDetailsAction,
	removeBrandingLogoAction,
	setBrandingColorAction,
	setBrandingLogoAction,
	setBrandingPositionAction,
	togglePoweredByFloikAction,
	toggleShowLogoAsWatermarkAction,
	toggleShowLogoInHeadingAction,
} from './BrandingDialog.reducer';
import { BrandingDialogState } from './BrandingDialog.types';

const BrandingDialog = () => {
	const {
		PROFILE_BRANDING_COLOR_PREVIEW_TXT,
		PROFILE_BRANDING_COLOR_SUB_TXT,
		PROFILE_BRANDING_COLOR_TITLE_TXT,
		PROFILE_BRANDING_LOGO_FORMAT_TXT,
		PROFILE_BRANDING_LOGO_REMOVE_TXT,
		PROFILE_BRANDING_LOGO_SUB_TXT,
		PROFILE_BRANDING_LOGO_TITLE_2_TXT,
		PROFILE_BRANDING_LOGO_TITLE_TXT,
		PROFILE_FLOIK_BRANDING_TITLE_TXT,
		PROFILE_FLOIK_BRANDING_DESC_TXT,
		PROFILE_FLOIK_BRANDING_TOGGLE_BTN_TXT,
		PROFILE_POPUP_TITLE_2_TEXT,
		PROFILE_BRANDING_LOGO_TXT,
		PROFILE_BRANDING_LOGO_FILE_FORMAT_ERR_TXT,
	} = Translations;
	const { data: branding, status } = useSelector<unknown, BrandingDialogState>((state) =>
		get(state, 'brandingDialog')
	);
	const dispatch = useDispatch();
	const [tabValue, setTabValue] = useState('1');
	const getBrandColor = branding?.brandColor || '';
	const videoPosition = branding?.watermarkPosition || 'bottom-right';
	const defaultHidePoweredByFloik = branding.hidePoweredByFloik;
	const defaultShowLogoInHeading = !get(branding, 'hideHeaderBrandLogo');
	const defaultShowLogoAsWatermark = !get(
		branding,
		'customWatermarkConfiguration.customWatermarkDisabled'
	);
	const filePath = branding?.customWatermarkConfiguration?.customWatermarkFilePath;
	const brandLogo = filePath || uploadImage;
	const [selectedImage, setSelectedImage] = useState(brandLogo);
	const [videoPos, setVideoPos] = useState(videoPosition);
	const [brandColor, setBrandColor] = useState(getBrandColor);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const [fileInputKey, setFileInputKey] = useState(uniqId());
	const colorInputRef = useRef<HTMLInputElement>(null);
	const uploadingImage = useSelector(
		(state) => get(state, 'loaders.upload_brand_image', 0) > 0
	);
	const poweredByFloikToggleFeatureEnabled = useSelector((state) =>
		get(
			state,
			'routerContainer.currentFloSpace.flospaceConstraints.poweredByFloikToggleFeatureEnabled'
		)
	);
	const [hidePoweredByFloik, setHidePoweredByFloik] = useState(defaultHidePoweredByFloik);

	const [showLogoInHeading, setShowLogoInHeading] = useState(defaultShowLogoInHeading);
	const [showLogoAsWatermark, setShowLogoAsWatermark] = useState(
		defaultShowLogoAsWatermark
	);

	const handleColorInputClick = useCallback(() => {
		colorInputRef.current?.click();
	}, [colorInputRef, brandColor]);

	useEffect(() => {
		setSelectedImage(brandLogo);
	}, [brandLogo]);

	useEffect(() => {
		dispatch(getBrandingDetailsAction({ update: true }));
		// return () => {
		// 	dispatch(
		// 		setBrandingDetails({
		// 			status: 'loading',
		// 			uuid: uniqId(),
		// 			data: {},
		// 		})
		// 	);
		// };
	}, []);

	useEffect(() => {
		setVideoPos(branding?.watermarkPosition);
		setBrandColor(branding?.brandColor);
		setHidePoweredByFloik(branding?.hidePoweredByFloik);
		setShowLogoAsWatermark(
			!get(branding, 'customWatermarkConfiguration.customWatermarkDisabled')
		);
		setShowLogoInHeading(!get(branding, 'hideHeaderBrandLogo'));
	}, [setVideoPos, setBrandColor, branding]);

	const onPoweredByToggle = useCallback(
		(value: boolean) => {
			setHidePoweredByFloik(value);
			dispatch(
				togglePoweredByFloikAction({
					hidePoweredBy: value,
				})
			);
		},
		[setHidePoweredByFloik]
	);

	const onShowLogoInHeadingToggle = useCallback(
		(event: unknown, value: boolean) => {
			if (!filePath) return;
			setShowLogoInHeading(value);
			dispatch(
				toggleShowLogoInHeadingAction({
					showLogoInHeading: value,
				})
			);
		},
		[setShowLogoInHeading, filePath]
	);

	const onShowLogoAsWatermarkToggle = useCallback(
		(event: unknown, value: boolean) => {
			if (!filePath) return;
			setShowLogoAsWatermark(value);
			dispatch(
				toggleShowLogoAsWatermarkAction({
					showLogoAsWatermark: value,
				})
			);
		},
		[setShowLogoAsWatermark, filePath]
	);

	const handleColorChange = useCallback(
		throttle(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				if (!e.target.value) {
					return;
				}
				dispatch(
					setBrandingColorAction({
						brandColor: e.target.value,
					})
				);
				setBrandColor(e.target.value);
			},
			1000,
			{ leading: true, trailing: false }
		),
		[brandColor, setBrandingColorAction, setBrandColor]
	);

	const handleUploadClick = useCallback(() => {
		fileInputRef.current?.click();
	}, [fileInputRef, brandLogo]);

	const handleFileChange = useCallback(
		throttle(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				if (!e.target.files) {
					return;
				}
				setFileInputKey(uniqId());
				const imgFile = e.target.files[0];
				if (get(imgFile, 'type') === 'image/png') {
					let img = new Image();
					img.src = window.URL.createObjectURL(imgFile);
					img.onerror = () => {
						dispatch(
							setErrorToast({
								type: 'error',
								message: Translations.BRANDING_UPLOADING_CORRUPT_FILE_ERROR,
								canShow: true,
							})
						);
					};
					img.onload = () => {
						if (img.width <= 250 && img.height <= 150) {
							dispatch(setBrandingLogoAction({ file: imgFile }));
							setSelectedImage(img.src);
						} else {
							dispatch(
								setErrorToast({
									type: 'error',
									message: `Upload failed, We require image less than or equal to 250px x 150px but found ${img.width}px x ${img.height}px`,
									canShow: true,
								})
							);
						}
					};
				} else {
					dispatch(
						setErrorToast({
							type: 'error',
							message: { PROFILE_BRANDING_LOGO_FILE_FORMAT_ERR_TXT },
							canShow: true,
						})
					);
				}
			},
			1000,
			{ leading: true, trailing: false }
		),
		[selectedImage, setFileInputKey]
	);

	//@ts-ignore
	const handleTabChange = (_, val) => {
		setTabValue(val);
	};

	const handleBrandPos = useCallback(
		(val: string) =>
			throttle(
				(e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					if (!val || !showLogoAsWatermark) {
						return;
					}
					dispatch(
						setBrandingPositionAction({
							brandPosition: val,
						})
					);
					setVideoPos(val);
				},
				1000,
				{ leading: true, trailing: false }
			),
		[videoPosition, showLogoAsWatermark]
	);

	const onRemoveImage = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
		dispatch(removeBrandingLogoAction(''));
	}, []);

	if (status === 'loading') return null;

	return (
		<Box>
			<Box className={styles.title}>{PROFILE_POPUP_TITLE_2_TEXT}</Box>
			<Box sx={{ mb: 2 }}>
				<Box className={styles.sectionTitle}>{PROFILE_BRANDING_LOGO_TXT}</Box>
				<Box className={styles.sectionSubTitle}>{PROFILE_BRANDING_LOGO_SUB_TXT}</Box>
				<Box
					className={classNames(styles.uploadImgBtn, {
						[styles.uploadingImage]: uploadingImage,
					})}
					role="button"
					onClick={uploadingImage ? undefined : handleUploadClick}
					sx={{ maxWidth: '330px' }}
				>
					<div
						className={classNames(styles.brandImageContainer, {
							[styles.canRemoveImage]: uploadImage !== selectedImage,
						})}
					>
						<img
							key={`${branding?.imageUUid}_${selectedImage}`}
							className={styles.brandImage}
							src={selectedImage}
						/>
						{!uploadingImage && (
							<Button
								variant="text"
								className={styles.removeImageText}
								onClick={onRemoveImage}
							>
								{PROFILE_BRANDING_LOGO_REMOVE_TXT}
							</Button>
						)}
					</div>
					<Box sx={{ ml: 2 }}>
						<Box className={styles.uploadText1}>{PROFILE_BRANDING_LOGO_TITLE_TXT}</Box>
						<Box className={styles.uploadText2}>{PROFILE_BRANDING_LOGO_FORMAT_TXT}</Box>
					</Box>
					<input
						ref={fileInputRef}
						key={fileInputKey}
						type="file"
						accept=".png"
						className={styles.uploadInput}
						onChange={handleFileChange}
					/>
				</Box>
			</Box>
			<Box className={styles.toggleLogoContainer}>
				<Box className={styles.sectionTitle}>
					{Translations.PROFILE_SHOW_LOGO_IN_HEADER_HEADING}
				</Box>
				<Box className={styles.switchContainer}>
					<Switch
						disabled={!filePath}
						key={`toggleLogoInHeading_${branding?.uuid}`}
						name="toggleLogoInHeading"
						onToggle={onShowLogoInHeadingToggle}
						label={Translations.PROFILE_SHOW_LOGO_IN_HEADER_TITLE}
						isChecked={showLogoInHeading}
					/>
				</Box>
			</Box>
			<Box className={styles.toggleLogoContainer}>
				<Box className={styles.sectionTitle}>
					{Translations.PROFILE_LOGO_AS_WATERMARK_HEADING}
				</Box>
				<Box className={styles.switchContainer}>
					<Switch
						disabled={!filePath}
						key={`toggleLogoAsWatermark_${branding?.uuid}`}
						name="toggleLogoAsWatermark"
						onToggle={onShowLogoAsWatermarkToggle}
						label={Translations.PROFILE_LOGO_AS_WATERMARK_TITLE}
						isChecked={showLogoAsWatermark}
					/>
				</Box>
			</Box>
			<Box
				sx={{ mb: 3 }}
				className={classNames({
					[styles.disable]: !showLogoAsWatermark,
				})}
			>
				<Box className={styles.sectionSubTitle}>{PROFILE_BRANDING_LOGO_TITLE_2_TXT}</Box>
				<Box className={styles.guideView} sx={{ maxWidth: '330px' }}>
					<Button
						className={classNames({
							[styles.posBtn]: true,
							[styles.posTL]: true,
							[styles.selected]: videoPos === 'top-left',
						})}
						onClick={handleBrandPos('top-left')}
					/>
					<Button
						className={classNames({
							[styles.posBtn]: true,
							[styles.posTR]: true,
							[styles.selected]: videoPos === 'top-right',
						})}
						onClick={handleBrandPos('top-right')}
					/>
					<Button
						className={classNames({
							[styles.posBtn]: true,
							[styles.posBL]: true,
							[styles.selected]: videoPos === 'bottom-left',
						})}
						onClick={handleBrandPos('bottom-left')}
					/>
					<Button
						className={classNames({
							[styles.posBtn]: true,
							[styles.posBR]: true,
							[styles.selected]: videoPos === 'bottom-right',
						})}
						onClick={handleBrandPos('bottom-right')}
					/>
				</Box>
			</Box>
			<Box>
				<Box className={styles.sectionTitle}>{PROFILE_BRANDING_COLOR_TITLE_TXT}</Box>
				<Box className={styles.sectionSubTitle}>{PROFILE_BRANDING_COLOR_SUB_TXT}</Box>
				<Box
					className={styles.colorPicker}
					role="button"
					onClick={handleColorInputClick}
					sx={{ maxWidth: '330px' }}
				>
					<Box className={styles.colorViewer} sx={{ backgroundColor: brandColor }} />
					<Input type="text" value={brandColor} className={styles.colorInput} />
					<input
						ref={colorInputRef}
						value={brandColor}
						type="color"
						className={styles.colorInputSelector}
						onChange={handleColorChange}
					/>
				</Box>
				<Box className={styles.sectionSubTitle} sx={{ mt: 2 }}>
					{PROFILE_BRANDING_COLOR_PREVIEW_TXT}
				</Box>
			</Box>
			<Box sx={{ mb: 2 }} className={styles.previewBoxes}>
				<Box className={styles.previewBox} sx={{ bgcolor: `${brandColor}08` }}>
					<Box
						className={styles.previewBoxWhite}
						sx={{ width: '160px', height: '95px', top: '18px', left: '23px' }}
					>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '135px', height: '15px', top: '12px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '135px', height: '15px', top: '32px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '135px', height: '15px', top: '52px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxBrandColor}
							sx={{
								width: '135px',
								height: '5px',
								top: '78px',
								left: '12px',
								bgcolor: `${brandColor}10`,
							}}
						>
							<Box
								className={styles.previewBoxBrandColor}
								sx={{
									width: '70px',
									height: '5px',
									top: '0',
									left: '0',
									bgcolor: brandColor,
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box className={styles.previewBox} sx={{ bgcolor: `${brandColor}08` }}>
					<Box
						className={styles.previewBoxWhite}
						sx={{ width: '112px', height: '16px', top: '18px', left: '47px' }}
					>
						<Box
							className={styles.previewBoxBrandColor}
							sx={{
								width: '5px',
								height: '5px',
								top: '5px',
								left: '5px',
								bgcolor: brandColor,
								borderRadius: 5,
							}}
						/>
					</Box>
					<Box
						className={styles.previewBoxWhite}
						sx={{ width: '112px', height: '40px', top: '38px', left: '47px' }}
					>
						<Box
							className={styles.previewBoxBrandColor}
							sx={{
								width: '5px',
								height: '5px',
								top: '5px',
								left: '5px',
								bgcolor: brandColor,
								borderRadius: 5,
							}}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '102px', height: '19px', top: '15px', left: '5px' }}
						/>
					</Box>
					<Box
						className={styles.previewBoxWhite}
						sx={{ width: '112px', height: '40px', top: '82px', left: '47px' }}
					>
						<Box
							className={styles.previewBoxBrandColor}
							sx={{
								width: '5px',
								height: '5px',
								top: '5px',
								left: '5px',
								bgcolor: brandColor,
								borderRadius: 5,
							}}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '102px', height: '19px', top: '15px', left: '5px' }}
						/>
					</Box>
				</Box>
				<Box className={styles.previewBox} sx={{ bgcolor: `${brandColor}08` }}>
					<Box
						className={styles.previewBoxWhite}
						sx={{ width: '160px', height: '95px', top: '18px', left: '23px' }}
					>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '135px', height: '22px', top: '12px', left: '12px' }}
						>
							<Box
								className={styles.previewBoxBrandColor}
								sx={{
									width: '11px',
									height: '11px',
									top: '5px',
									left: '35px',
									border: `${brandColor} 2px solid`,
									borderRadius: 11,
								}}
							/>
							<Box
								className={styles.previewBoxBrandColor}
								sx={{
									width: '48px',
									height: '12px',
									top: '5px',
									left: '52px',
									bgcolor: brandColor,
									borderRadius: '2px !important',
								}}
							>
								<Box
									className={styles.previewBoxBrandColor}
									sx={{
										width: '0px',
										height: '0px',
										borderTop: '3px solid transparent',
										borderBottom: '3px solid transparent',
										borderRight: `3px solid ${brandColor}`,
										left: '-3px',
										top: '3px',
									}}
								/>
							</Box>
						</Box>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '68px', height: '7px', top: '40px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '68px', height: '7px', top: '52px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '68px', height: '7px', top: '64px', left: '12px' }}
						/>
						<Box
							className={styles.previewBoxGrey}
							sx={{ width: '68px', height: '7px', top: '76px', left: '12px' }}
						/>
					</Box>
				</Box>
			</Box>
			{poweredByFloikToggleFeatureEnabled && (
				<Box>
					<Box className={styles.sectionTitle}>{PROFILE_FLOIK_BRANDING_TITLE_TXT}</Box>
					<Box className={styles.sectionSubTitle}>{PROFILE_FLOIK_BRANDING_DESC_TXT}</Box>
					<Box className={styles.switchContainer}>
						<Switch
							key="TogglePoweredByFloik"
							name="TogglePoweredByFloik"
							defaultChecked={!defaultHidePoweredByFloik}
							onToggle={(e) => onPoweredByToggle(!hidePoweredByFloik)}
							label={PROFILE_FLOIK_BRANDING_TOGGLE_BTN_TXT}
							isChecked={!hidePoweredByFloik}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default BrandingDialog;
