import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
	cloneDeep,
	concat,
	find,
	findIndex,
	get,
	isEmpty,
	map,
	max,
	pullAt,
	reduce,
	reject,
	set,
	sortBy,
} from 'lodash';
import uniqId from 'uniqid';
import { PaginationType } from '../../Common/Common.types';
import { formattedTimeToSeconds, Playlist } from '../../Common/Common.utils';
import {
	AnnotationSnapshot,
	setAnnotationsByTimeType,
} from '../../Components/Annotations/Annotations.types';
import {
	FLO_ACTIVE_TAB_EDIT,
	FLO_ELEMENT_CROP_TYPE,
	FLO_ELEMENT_NEW_TRIM_TYPE,
} from './Flo.constants';
import {
	commentType,
	createdByType,
	CropStateType,
	deleteCommentPayloadType,
	elementBeanListType,
	elementsDataType,
	FloDetailsType,
	floOutputMetadataType,
	FloReducerType,
	floTrackType,
	outputFormats,
	privateShareEmailListType,
	scrollToType,
	setFloCommentsType,
	setSettingsType,
	SSEDataType,
	TrimStateType,
} from './Flo.types';
import { GuideDataType } from './pages/GuideFlo/Gude.types';
import guideFloReducer from './pages/GuideFlo/Guide.reducer';
import InteractiveCueReducer, {
	initialInteractiveElementObject,
} from './pages/VideoFlo/InteractiveCue.reducer';
import videoFloReducer from './pages/VideoFlo/videoFlo.reducer';

// const floReducers = {};

const initialState = Object.freeze({
	qualitySelectors: {},
	amplitudeData: [],
	audioUrl: '',
	currentCommentAnnotation: null,
	annotationTool: 'none',
	annotations: [],
	errorCode: 0,
	editingCommentId: '',
	supportedInIphone: false,
	errored: false,
	errorMessage: '',
	annotationsMeta: [],
	tracks: [],
	playlist: {},
	commentReplies: {},
	comments: {
		data: [],
		paginationInfo: {},
		loading: true,
	},
	floDetails: {},
	guide: {
		selectedGuideStepId: '',
		steps: [],
		imagesState: {},
		pagination: {
			hasMore: false,
			pageNo: 1,
			pageSize: 100,
			totalRecords: 0,
		},
	},
	scrollTo: {
		commentId: '',
		highlightCommentId: '',
		highlightAnnotationByCommentId: '',
		behavior: 'smooth',
		replyId: '',
	},
	voiceOver: {
		transcribeStatus: 'loading',
	},
	greaterThan4k: false,
	currentHighestResolution: {
		width: 0,
		height: 0,
	},
	isSSEConnected: false,
	sseEventSource: null,
	postProcessConfigs: {
		crop: {
			enable: false,
			values: null,
			videoSize: {
				width: 0,
				height: 0,
			},
		},
		trim: {
			enable: false,
			values: ['0', '100'],
			timeValue: [],
			rangeValues: [0, 100],
			originalLength: 0,
			currentTime: 0,
			currentTimeSeconds: 0,
		},
	},
	activeEditElement: initialInteractiveElementObject,
	prevActiveEditElement: null,
	editorsElements: {},
	editorsElementsConfig: [],
	trimSections: [],
	currentMetadata: {},
	publishedEditorsElementsConfig: [],
	tabActive: FLO_ACTIVE_TAB_EDIT,
	alternativeOutputs: [],
	resourceGroups: [],
	presenter: {
		enable: true,
		imageUrl: '',
		imageType: 'none',
	},
});

const generateTrimSection = (
	editorsElementsConfig: elementBeanListType[]
): Array<[number, number]> => {
	const trimSections = reduce(
		editorsElementsConfig,
		(acc: Array<[number, number]>, config) => {
			if (config.editorElementName === FLO_ELEMENT_NEW_TRIM_TYPE) {
				const trimStartTime = formattedTimeToSeconds(
					get(config, 'properties.trimStartTime', '00:00:00:00')
				) as number;
				const trimEndTime = formattedTimeToSeconds(
					get(config, 'properties.trimEndTime', '00:00:00:00')
				) as number;
				acc.push([trimStartTime, trimEndTime]);
			}
			return acc;
		},
		[]
	);
	return trimSections;
};

const scrollToDefault: scrollToType = {
	commentId: '',
	highlightAnnotationByCommentId: '',
	highlightCommentId: '',
	behavior: 'smooth',
	replyId: '',
};

const rearrangeFunction = (
	state: FloReducerType,
	{ payload }: { payload: { from: number; to: number } }
) => {
	const fromSteps = pullAt(state.guide.steps, [payload.from]);
	state.guide.steps = [
		...state.guide.steps.slice(0, payload.to),
		...fromSteps,
		...state.guide.steps.slice(payload.to),
	];
	state.guide.steps = map(state.guide.steps, (step, order) => ({
		...step,
		order,
		indexOrder: order,
	}));
};

const supportedSize = 3840 * 2160;
const sizeHD = 1920 * 1080;
const size2k = 2560 * 1440;

const floReducers = {
	...videoFloReducer,
	...InteractiveCueReducer,
	...guideFloReducer,
	setScrollTo(state: FloReducerType, { payload }: { payload: scrollToType }) {
		state.scrollTo = payload;
		if (!payload.highlightCommentId) {
			state.annotations = [];
		}
	},
	getFloDetails(state: FloReducerType, payload: unknown) {
		const newState = cloneDeep(initialState);
		if (get(state, 'publishedTracks', []).length) {
			newState.publishedTracks = get(state, 'publishedTracks', []);
			newState.publishedDuration = get(state, 'publishedDuration');
		}
		return newState;
	},
	setFloDetails(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		return {
			...state,
			floDetails: payload,
		};
	},
	setUpdatedFloDetails(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		state.floDetails = payload;
	},
	setSSEFloDetails(state: FloReducerType, { payload }: { payload: FloDetailsType }) {
		state.floDetails = payload;
	},
	updatedFloName(
		state: FloReducerType,
		{ payload }: { payload: { id: string; name: string } }
	) {
		if (payload.id === state.floDetails?.id) {
			state.floDetails.name = payload.name;
		}
	},
	setFloErrored(state: FloReducerType, { payload }: { payload: AxiosError }) {
		const message = get(payload, 'response.data.data') || get(payload, 'message');
		const messageStatus = get(payload, 'response.status');
		state.errorCode = messageStatus;
		state.errorMessage = message || 'Oops! Something went wrong';
		state.errored = true;
	},
	setFloTracks(state: FloReducerType, { payload }: { payload: floTrackType[] }) {
		state.tracks = {
			...state.tracks,
			...payload,
		};
		state.duration = Number(max(payload.map((i) => i.duration || 0)) || 0);
		const floResources = state.tracks;
		const greaterThan4k = reduce(
			floResources,
			(acc, item) => {
				const currRes = get(item, 'width') * get(item, 'height');
				if (currRes >= get(item, 'maxRes')) {
					let screenSizeBucket = 'HD';
					if (currRes > size2k) {
						screenSizeBucket = 'UHD';
					} else if (currRes > sizeHD && currRes <= size2k) {
						screenSizeBucket = 'FHD';
					}

					return {
						isHigh: true,
						maxRes: currRes,
						screenSizeBucket,
						currentResolution: {
							width: get(item, 'width'),
							height: get(item, 'height'),
						},
					};
				}
				return acc;
			},
			{
				isHigh: false,
				maxRes: 0,
				screenSizeBucket: 'HD',
				currentResolution: { height: 0, width: 0 },
			}
		);
		// state.greaterThan4k = greaterThan4k.isHigh;
		state.currentHighestResolution = greaterThan4k.currentResolution;
		state.screenSizeBucket = greaterThan4k.screenSizeBucket;
		// state.estimatedCompletionTime = Translations[`FLO_PUBLISH_ESTIMATE_TIME_${get(greaterThan4k, 'screenSizeBucket') || 'HD'}`];
	},
	setDuration(state: FloReducerType, { payload }: { payload: number }) {
		state.duration = payload;
	},

	setFloPlayList(
		state: FloReducerType,
		{ payload }: { payload: Record<string, Playlist[]> }
	) {
		state.playlist = payload;
	},

	setResourcesList(
		state: FloReducerType,
		{ payload }: { payload: Record<string, Playlist[]> }
	) {
		state.resource = payload;
	},

	setPublishedFloTracks(state: FloReducerType, { payload }: { payload: floTrackType[] }) {
		state.publishedTracks = payload;
		state.publishedDuration = Number(max(payload.map((i) => i.duration || 0)) || 0);
	},

	setPublishedElementsConfig(
		state: FloReducerType,
		{ payload }: { payload: elementsDataType[] }
	) {
		state.publishedEditorsElementsConfig = payload || [];
	},
	getFloComments(state: any, { payload }: any) {
		state.comments.loading = true;
	},
	setFloComments(state: setFloCommentsType, { payload }: { payload: any }) {
		const { data } = payload || {};
		const comment = data[data.length - 1];
		// if (payload.type === 'add' && state.comments.data) {
		// 	const lastAnnotationId = get(state, 'comments.data[0].annotationId', '');
		// 	const annotationId = get(comment, 'annotationId');
		// 	state.scrollTo = { ...scrollToDefault, commentId: comment.id, behavior: 'auto' };
		// 	if (lastAnnotationId === annotationId) {
		// 		data.splice(data.length - 1, 1);
		// 	}

		// 	state.comments.data = concat(data, state.comments.data);
		// 	state.comments.paginationInfo = payload.paginationInfo;
		// } else {
		state.comments = payload;
		state.scrollTo = { ...scrollToDefault, commentId: comment?.id, behavior: 'auto' };
		// }
		state.comments.loading = false;
	},
	addComments(state: any, { payload }: { payload: any }) {},
	setAddComments(state: any, { payload }: { payload: any }) {
		const annotations = state.comments.data;
		const lastAnnotationId =
			annotations.length && annotations[annotations.length - 1].annotationId;
		const newAnnotationId = get(payload, 'annotationId');
		if (lastAnnotationId === newAnnotationId) return;
		state.comments.data.push(payload);

		if (payload.canSkipScrollTo) return;
		state.scrollTo = {
			...scrollToDefault,
			...(payload.addHighlight ? { highlightCommentId: payload.id } : {}),
			commentId: payload.id,
		};
	},

	getFloReplies(state: any, { payload }: any) {
		state.commentReplies[payload.commentId] =
			state.commentReplies[payload.commentId] || {};
		const reply = state.commentReplies[payload.commentId];
		state.scrollTo = { ...scrollToDefault };
		if (reply) reply.loading = true;
	},
	setFloReplies(state: any, { payload }: { payload: any }) {
		const { commentId, data, paginationInfo } = payload || {};
		const newLastReply = data[data.length - 1];

		if (payload.type === 'add') {
			const lastAnnotationId = get(state, 'commentReplies.data[0].annotationId', '');
			const annotationId = get(newLastReply, 'annotationId');
			if (lastAnnotationId === annotationId) {
				data.splice(data.length - 1, 1);
			}

			const currentReplies = state.commentReplies[commentId].data;
			state.commentReplies[commentId].data = concat(data, currentReplies);
			state.commentReplies[commentId].paginationInfo = paginationInfo;
			state.scrollTo = {
				...scrollToDefault,
				replyId: newLastReply.id,
				commentId,
				behavior: 'auto',
			};
		} else {
			state.commentReplies[commentId] = payload;
			state.scrollTo = { ...scrollToDefault, commentId };
		}
		state.commentReplies[commentId].loading = false;
	},
	addFloReply(state: any, { payload }: { payload: any }) {},
	setAddFloReply(state: any, { payload }: { payload: any }) {
		const parentId = get(payload, 'parentCommentId', '');

		if (parentId) {
			const comment = find(state.comments.data, { id: parentId });
			// state.commentReplies[parentId].data.push(payload);
			const currentReplies = get(state.commentReplies[parentId], 'data') || [];

			if (!isEmpty(currentReplies)) {
				const lastReplyId = currentReplies[currentReplies.length - 1].id;
				const canPush = lastReplyId !== payload.id;
				canPush && currentReplies.push(payload);
			} else {
				state.commentReplies[parentId] = { data: [payload] };
			}
			state.scrollTo = {
				...scrollToDefault,
				replyId: '',
				commentId: parentId,
				behavior: 'smooth',
			};
		}
	},

	setAnnotationTool(state: FloReducerType, { payload }: { payload: string }) {
		state.annotationTool = payload;
	},
	setAmplitudeData(state: FloReducerType, { payload }: { payload: any }) {
		state.amplitudeData = payload;
	},
	setQualitySelectors(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				key: string;
				audioUrl: string;
				values: Array<{
					url: string;
					audioUrl: string;
					label: string;
				}>;
			};
		}
	) {
		if (payload.audioUrl) state.audioUrl = payload.audioUrl;
		state.qualitySelectors[payload.key] = payload.values;
	},
	setIsFloSupportedInIphone(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: boolean;
		}
	) {
		state.supportedInIphone = payload;
	},
	addAnnotations(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: AnnotationSnapshot;
				floId: string;
				floSpaceId: string;
				trackId: string;
			};
		}
	) {
		state.annotations.push(payload.data);
	},
	getAnnotationsMeta(state: FloReducerType, { payload }: { payload?: null }) {},
	setAnnotationsMeta(
		state: FloReducerType,
		{ payload }: { payload: AnnotationSnapshot[] }
	) {
		state.annotationsMeta = sortBy(payload, 'time');
	},
	getAnnotationsByTime(state: FloReducerType, { payload }: { payload?: null }) {},
	setAnnotationsByTime(
		state: FloReducerType,
		{ payload }: { payload: setAnnotationsByTimeType }
	) {
		const { data, commentId } = payload;
		if (data) {
			let annotations = sortBy(data, 'time');
			let annotationItem: AnnotationSnapshot = data[0];
			if (commentId) {
				//@ts-ignore
				annotationItem = find(data, { commentId }) || {};
				annotations = [annotationItem];
			}
			state.annotations = annotations;
			state.scrollTo = {
				...scrollToDefault,
				commentId: get(annotationItem, 'commentId') || commentId || '',
				highlightCommentId: get(annotationItem, 'commentId') || commentId || '',
			};
		} else {
			state.annotations = [];
			//  state.scrollTo ={ ...scrollToDefault };
		}
	},
	updateAnnotation(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data?: AnnotationSnapshot;
				uiId?: string;
				content?: string;
				commentId?: string;
				createdBy?: createdByType;
				id?: string;
			};
		}
	) {
		if (!payload?.data) {
			state.annotations = state.annotations.slice(0, -1);
		} else {
			if (state.annotations.length - 1 >= 0) {
				const index = state.annotations.findIndex((annot) => annot.uid == payload.uiId);
				const newData = set(
					{ ...payload.data },
					'state[0].comment.content',
					payload.content
				) as AnnotationSnapshot;
				newData.id = payload.id;
				// @ts-ignore
				newData.createdBy = payload.createdBy;
				// @ts-ignore
				state.annotations[index] = newData;
				state.scrollTo = {
					...scrollToDefault,
					commentId: newData.commentId || '',
					highlightCommentId: newData.commentId || '',
				};
			}
		}
	},
	sendAnnotation(state: FloReducerType, { payload }: { payload: AnnotationSnapshot }) {},
	removeAnnotations(state: FloReducerType, { payload }: { payload: string }) {
		state.annotations = reject(state.annotations, ['id', payload]);
	},
	getFloTracks(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				floSpaceId: string;
			};
		}
	) {},

	resolveComment(state: any, { payload }: { payload: any }) {},
	setResolveComment(state: any, { payload }: { payload: any }) {
		const { annotationId, isResolved, isResolvedBy } = payload;
		const comment = find(state.comments.data, { annotationId });

		if (comment) {
			comment.isResolved = isResolved;
			comment.isResolvedBy = isResolvedBy;
		}
	},
	decisionComment(state: any, { payload }: { payload: any }) {},
	setDecisionComment(state: any, { payload }: { payload: any }) {
		const { annotationId, isDecision, isDecisionBy, parent, commentId } = payload;

		if (parent) {
			const repliesList = state.commentReplies[parent];
			if (repliesList) {
				const reply = find(repliesList.data, { id: commentId });
				reply.isDecision = isDecision;
				reply.isDecisionBy = isDecisionBy;
			}
			return;
		}

		const comment = find(state.comments.data, { annotationId });
		if (comment) {
			comment.isDecision = isDecision;
			comment.isDecisionBy = isDecisionBy;
		}
	},

	getCommentsCount(state: any, { payload }: { payload: any }) {},

	leaveFloPage() {
		return initialState;
	},
	updateSkipConsent(state: FloReducerType) {
		if (state.floDetails?.floUserConsent) {
			// @ts-ignore
			state.floDetails.floUserConsent.profileSkipped = true;
		}
	},
	setSettings(state: FloReducerType, { payload }: { payload: setSettingsType }) {
		const {
			shareLinkEnabled,
			shareLinkDisabled,
			inviteLinkEnabled,
			status,
			closed,
			privateFlo,
			collaborator,
		} = payload;
		const { floDetails } = state;
		if (state.floDetails?.id !== payload.id) return;
		if (floDetails && !isEmpty(payload)) {
			floDetails.shareLinkEnabled = shareLinkEnabled;
			floDetails.shareLinkDisabled = shareLinkDisabled;
			floDetails.status = status;
			floDetails.closed = closed;
			floDetails.privateFlo = privateFlo;
			floDetails.collaborator = collaborator;
			floDetails.inviteLinkEnabled = inviteLinkEnabled;
		} else {
			const newFloDetails = {
				shareLinkEnabled: false,
				shareLinkDisabled: false,
				inviteLinkEnabled: false,
				status: 'PROCESSING',
				closed: true,
				privateFlo: false,
				id: '',
				floSpaceId: '',
				name: '',
				canEdit: false,
				folderId: 'default',
				collaborator: true,
			};
			state.floDetails = newFloDetails;
		}
	},
	deleteComment(state: setFloCommentsType) {},
	setDeleteComment(
		state: FloReducerType,
		{ payload }: { payload: deleteCommentPayloadType }
	) {
		const { commentId } = payload || {};
		const commentsData = state.comments.data;
		const index = findIndex(commentsData, { id: commentId });

		if (index > -1) {
			commentsData.splice(index, 1);
			state.floDetails.commentsCount = payload.count;
		}
		if (payload.annotationId)
			state.annotations = reject(state.annotations, ['id', payload.annotationId]);
	},
	deleteReply(state: setFloCommentsType) {},
	setDeleteReply(
		state: setFloCommentsType,
		{ payload }: { payload: deleteCommentPayloadType }
	) {
		const { commentId, parentId, replyCount, count } = payload || {};
		if (parentId) {
			const replyData = state.commentReplies && state.commentReplies[parentId].data;
			const comment = find(state.comments.data, { id: parentId });

			const index = findIndex(replyData, { id: commentId });
			if (replyData && index > -1) {
				replyData.splice(index, 1);
				state.floDetails['commentsCount'] = count;
				if (comment) comment.count = replyCount || 0;
			}
		}
	},
	stageEditComment(state: FloReducerType, { payload }: { payload: string }) {
		state.editingCommentId = payload;
		state.scrollTo = {
			behavior: 'smooth',
			commentId: '',
			highlightAnnotationByCommentId: '',
			highlightCommentId: '',
			replyId: '',
		};
		state.scrollTo.highlightCommentId = payload;
	},
	editComment(state: FloReducerType) {},
	setEditComment(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				annotationId: string;
				content: string;
				commentId: string;
				time: number;
			};
		}
	) {
		const { annotationId, content } = payload;
		const comment = find(state.comments.data, { annotationId });
		if (comment) comment.content = content;
	},
	editReply(state: FloReducerType) {},
	setEditReply(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				annotationId: string;
				commentId: string;
				content: string;
				parent: string;
			};
		}
	) {
		const { parent, commentId, content } = payload;
		const repliesList = state.commentReplies && state.commentReplies[parent];
		if (repliesList) {
			const reply = find(repliesList.data, { id: commentId });
			if (reply) reply.content = content;
		}
	},
	onSSEMessage(state: setFloCommentsType, { payload }: { payload: SSEDataType }) {},
	setCommentCount(state: setFloCommentsType, { payload }: { payload: number }) {
		state.floDetails['commentsCount'] = payload;
	},
	setReplyCount(
		state: setFloCommentsType,
		{
			payload,
		}: {
			payload: {
				parentCommentId: string;
				count: string;
			};
		}
	) {
		const { parentCommentId, count } = payload;
		const comment = find(state.comments.data, { id: parentCommentId });
		if (comment) comment.count = parseInt(count);
		else {
			//@ts-ignore
			state.comments.data.push({
				id: parentCommentId,
				count: 1,
			});
		}
	},

	initiateSSE(state: setFloCommentsType) {},
	setSseEventSource(state: setFloCommentsType, { payload }: { payload: EventSource }) {
		state.sseEventSource = payload;
	},
	setTrimData(state: FloReducerType, { payload }: { payload: TrimStateType }) {
		state.postProcessConfigs.trim = payload;
		state.currentCommentAnnotation = null;
	},
	setTrimCurrentTime(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				currentTime: number;
				currentTimeSeconds: number;
			};
		}
	) {
		state.postProcessConfigs.trim.currentTime = payload.currentTime;
		state.postProcessConfigs.trim.currentTimeSeconds = payload.currentTimeSeconds;
	},
	setCropData(state: FloReducerType, { payload }: { payload: CropStateType }) {
		state.postProcessConfigs.crop = payload;
		state.currentCommentAnnotation = null;

		if (get(state.activeEditElement, 'type') === FLO_ELEMENT_CROP_TYPE) {
			// @ts-ignore
			state.activeEditElement = set(
				// @ts-ignore
				state.activeEditElement,
				'element.properties.values',
				payload || {}
			);
		}
	},
	resetCropData(state: FloReducerType) {},
	getElements(state: FloReducerType) {},
	setElements(state: FloReducerType, { payload }: { payload: elementsDataType[] }) {
		state.editorsElements = payload;
	},
	getElementsConfig(state: FloReducerType) {},
	setElementsConfig(state: FloReducerType, { payload }: { payload: elementsDataType[] }) {
		state.editorsElementsConfig = payload || [];
		state.editorsElementsConfig = sortBy(payload, 'properties.time');
		state.trimSections = generateTrimSection(payload || []);
		// state.editorsElementsConfigMap = reduce(payload, (acc, item) => ({
		// 	...acc,
		// }), {});
	},
	setElementsForGuide(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				[key: string]: elementsDataType;
			};
		}
	) {
		state.guide.elements = payload || [];
		// state.editorsElementsConfigMap = reduce(payload, (acc, item) => ({
		// 	...acc,
		// }), {});
	},
	updateElementsConfig(
		state: FloReducerType,
		{ payload }: { payload: elementsDataType }
	) {
		const index = findIndex(state.editorsElementsConfig, ['id', payload.id]);
		state.editorsElementsConfig = state.editorsElementsConfig || [];
		if (index >= 0) {
			state.editorsElementsConfig[index] = payload;
		} else {
			state.editorsElementsConfig.push(payload);
		}
		state.trimSections = generateTrimSection(state.editorsElementsConfig || []);
	},
	updateVoiceOverElementsConfig(
		state: FloReducerType,
		{ payload }: { payload: elementsDataType[] }
	) {
		const _payload = payload[0];
		const index = findIndex(
			state.editorsElementsConfig,
			(i) => get(i, 'editorElementName') === get(_payload, 'editorElementName')
		);
		state.editorsElementsConfig = state.editorsElementsConfig || [];
		if (index >= 0) {
			state.editorsElementsConfig[index] = _payload;
		} else {
			state.editorsElementsConfig.push(_payload);
		}
		state.trimSections = generateTrimSection(state.editorsElementsConfig || []);
	},
	updateElementView(
		state: FloReducerType,
		{ payload }: { payload: { id: string; data: boolean } }
	) {
		const index = findIndex(state.editorsElementsConfig, ['id', payload.id]);
		if (index >= 0) {
			set(state, `editorsElementsConfig[${index}].enabled`, payload.data);
		}
		state.trimSections = generateTrimSection(state.editorsElementsConfig || []);
	},

	deleteElementsConfig(state: FloReducerType) {},
	toggleElementView(state: FloReducerType) {},
	changePublishConfiguration(state: FloReducerType) {},
	changeFloMetaConfiguration(state: FloReducerType) {},
	setFloOutputMetadata(
		state: FloReducerType,
		{ payload }: { payload: floOutputMetadataType[] }
	) {
		state.floDetails.floOutputMetadata = payload;
	},
	onPublishFlo(state: FloReducerType) {
		state.currentCommentAnnotation = null;
	},
	onGenerateFlo(state: FloReducerType) {},
	setFloCurrentMetadata(
		state: FloReducerType,
		{ payload }: { payload: floOutputMetadataType }
	) {
		state.floDetails.floOutputMetadata = map(
			state.floDetails.floOutputMetadata,
			(outpput) => {
				return outpput.outputId === payload.outputId ? payload : outpput;
			}
		);
		state.currentMetadata = payload;
		const values = reduce(
			payload.downloadConfiguration,
			(acc, config, key: string) => {
				console.log(acc, key, config);
				if (
					get(state.downloadStatus, [key]) &&
					get(config, 'downloadStatus') !== 'YET_TO_START'
				) {
					acc[key] = get(config, 'downloadStatus') === 'PROCESSING';
				}
				return acc;
			},
			{
				...state.downloadStatus,
			}
		);
		state.downloadStatus = values;
	},
	editTrim(state: FloReducerType) {
		state.currentCommentAnnotation = null;
	},
	editCrop(state: FloReducerType) {
		state.currentCommentAnnotation = null;
	},
	generateVoice(state: FloReducerType) {
		state.currentCommentAnnotation = null;
		// state.voiceOver.transcribes = [];
	},
	editCtaElement(state: FloReducerType) {
		state.currentCommentAnnotation = null;
	},
	setCommentAnnotation(
		state: FloReducerType,
		{ payload }: { payload?: commentType | null }
	) {
		state.currentCommentAnnotation = payload;
	},
	getGuideData(state: FloReducerType) {},
	getPublishedGuideData(state: FloReducerType) {},
	updateFloStepTitle(state: FloReducerType) {},
	updateFloStepDescription(state: FloReducerType) {},
	updateFloStepTitleSuccess(
		state: FloReducerType,
		{ payload }: { payload: { id: string; title: string } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.id
				? {
						...step,
						title: payload.title,
				  }
				: step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	updateFloStepDescriptionSuccess(
		state: FloReducerType,
		{ payload }: { payload: { id: string; description: string } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.id
				? {
						...step,
						description: payload.description,
				  }
				: step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	setGuideData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: GuideDataType[];
				pagination: PaginationType;
			};
		}
	) {
		state.guide.steps = payload.data;
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
		if (payload.pagination) {
			state.guide.pagination = payload.pagination;
		}
	},
	setPublishedGuideData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: GuideDataType[];
				pagination?: PaginationType;
				guideName: string;
				guideDescription?: string;
			};
		}
	) {
		state.guide.publishedGuideName = payload.guideName || '';
		state.guide.publishedGuideDescription = payload.guideDescription || '';
		state.guide.publishedSteps = payload.data;
	},
	setElementsForPublishedGuide(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				[key: string]: elementsDataType;
			};
		}
	) {
		state.guide.publishedElements = payload || [];
		// state.editorsElementsConfigMap = reduce(payload, (acc, item) => ({
		// 	...acc,
		// }), {});
	},
	setGuideError(state: FloReducerType) {
		state.guide.errored = true;
	},
	setActiveTab(state: FloReducerType, { payload }: { payload: string }) {
		state.tabActive = payload || '';
		state.voiceOver.show = false;
		state.activeEditElement = {
			show: false,
			enableSave: false,
			type: '',
			element: null,
			config: null,
		};
	},
	deleteFloStepConfirm(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; stepId: string } }
	) {},
	deleteFloStep(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; stepId: string } }
	) {},
	toggleFloStepMouseClick(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; stepId: string } }
	) {},
	toggleFloStepMouseClickToggleSuccess(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; stepId: string } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.stepId
				? {
						...step,
						enabled: !step.enabled,
				  }
				: step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	deleteFloStepSuccess(
		state: FloReducerType,
		{ payload }: { payload: { floId: string; stepId: string } }
	) {
		state.guide.steps = reject(state.guide.steps, ['id', payload.stepId]);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	downloadFlo(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				outputTypeId: string;
				settings?: { [key: string]: boolean };
			};
		}
	) {},
	downloadGIFFlo(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
	setDownloadStatus(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				reset?: boolean;
				key: string;
				value: boolean;
			};
		}
	) {
		state.downloadStatus = state.downloadStatus || {};
		if (payload.reset) {
			state.downloadStatus = {};
		} else {
			state.downloadStatus[payload.key] = payload.value;
		}
	},
	rearrangeFloStep: rearrangeFunction,
	rearrangeFloStepFailed: rearrangeFunction,
	getAlternativeOutputs(state: FloReducerType) {},
	setAlternativeOutputs(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: outputFormats[];
		}
	) {
		state.alternativeOutputs = payload;
	},
	updateHotspot(state: FloReducerType) {},
	updateMouseClick(state: FloReducerType) {},
	addHotspotSuccess: function (
		state: FloReducerType,
		{
			payload,
		}: {
			payload: elementsDataType;
		}
	) {
		// @ts-ignore
		state.editorsElementsConfig.push({ ...payload, new: true });
		state.editorsElementsConfig = sortBy(state.editorsElementsConfig, 'properties.time');
	},
	updateHotspotSuccess(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: elementsDataType & {
				keepActiveElement?: boolean;
			};
		}
	) {
		if (state.activeEditElement && !payload.keepActiveElement) {
			state.activeEditElement.show = false;
			state.activeEditElement.type = '';
		}
		state.editorsElementsConfig = map(state.editorsElementsConfig, (element) =>
			element.id === payload.id
				? {
						uid: uniqId(),
						...element,
						...payload,
				  }
				: element
		);
		if (state.guide?.elements?.[payload.id]) state.guide.elements[payload.id] = payload;
	},
	updateHotspotFailed(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {
		// if (state.activeEditElement) {
		// 	state.activeEditElement.show = false;
		// 	state.activeEditElement.type = '';
		// }
		state.editorsElementsConfig = map(state.editorsElementsConfig, (element) =>
			element.id === payload
				? {
						uid: uniqId(),
						...element,
				  }
				: element
		);
	},
	resetHotspot(state: FloReducerType, { payload }: { payload: string }) {
		state.editorsElementsConfig = map(state.editorsElementsConfig, (element) =>
			element.new
				? {
						...element,
						new: false,
				  }
				: element
		);
	},
	addManualStep() {},
	addClickToStepSuccess() {},
	uploadStepImage() {},
	uploadStepImageSuccess() {},
	updateFloStepSuccess(
		state: FloReducerType,
		{ payload }: { payload: { id: string; stepData: GuideDataType } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.id
				? {
						...payload.stepData,
						imageUrl:
							(get(step, 'customResource')
								? get(payload, 'stepData.dataUrl')
								: get(payload, 'stepData.imageUrl')) || step.imageUrl,
				  }
				: step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	addClickToStep() {},
	setStepNewlyAddedClick(
		state: FloReducerType,
		{ payload }: { payload: { id: string; value: boolean } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.id ? { ...step, newlyAddedClick: payload.value } : step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	setStepLoader(
		state: FloReducerType,
		{ payload }: { payload: { id: string; status: boolean } }
	) {
		state.guide.steps = map(state.guide.steps, (step) =>
			step.id === payload.id ? { ...step, loading: payload.status } : step
		);
		state.guide.steps = map(sortBy(state.guide.steps, 'order'), (step, order) => ({
			...step,
			indexOrder: order,
		}));
	},
	addManualStepSuccess(
		state: FloReducerType,
		{ payload }: { payload: { data: GuideDataType; index: number } }
	) {
		state.guide.steps = [
			...state.guide.steps.slice(0, payload.index),
			payload.data,
			...state.guide.steps.slice(payload.index),
		];

		state.guide.steps = map(state.guide.steps, (step, order) => ({
			...step,
			order,
			indexOrder: order,
		}));
	},
	getFloResourceGroup() {},
	getFloResourceGroupSuccess(state: FloReducerType, { payload }: { payload: {} }) {
		state.resourceGroups = payload;
	},
	setFloPreviewResourceGroup(state: FloReducerType, { payload }: { payload: {} }) {
		state.previewResources = payload;
	},
	deleteFloAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				floId: string;
				canEdit: boolean;
				folderId: string;
			};
		}
	) {},
	unpublishFloAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
			};
		}
	) {},
	cloneFloAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
			};
		}
	) {},
	unpublishFloConfirmedAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
			};
		}
	) {},
	deleteFloConfirmedAction(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { floId: string; folderId: string; name: string; canEdit: boolean };
		}
	) {},
	setHotspotWrapperDims(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				width: number;
				height: number;
			};
		}
	) {
		state.hotspotWrapper = payload;
	},
	saveHotspot(
		state: FloReducerType,
		{
			payload,
		}: {
			payload?: unknown;
		}
	) {},
	changeShareLinkType(
		state: FloReducerType,
		{
			payload,
		}: {
			payload?: unknown;
		}
	) {},
	changeShareLinkTypeSuccess(
		state: FloReducerType,
		{
			payload,
		}: {
			payload?: unknown;
		}
	) {
		state.currentMetadata.publicAccess = !state.currentMetadata.publicAccess;
	},
	addPrivateShareEmail(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: privateShareEmailListType[];
		}
	) {},
	addPrivateShareEmailSuccess(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: privateShareEmailListType[];
		}
	) {
		if (get(state, 'currentMetadata.privateShareEmailList', []).length) {
			state.currentMetadata.privateShareEmailList = concat(
				payload,
				state.currentMetadata.privateShareEmailList
			);
			state.currentMetadata.privateShareEmailListPagination.totalRecords =
				state.currentMetadata.privateShareEmailListPagination.totalRecords +
				payload.length;
		} else {
			state.currentMetadata.privateShareEmailList = payload;
			state.currentMetadata.privateShareEmailListPagination.totalRecords = payload.length;
		}
	},
	getPrivateShareEmailList(state: FloReducerType) {},
	setPrivateShareEmailList(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				data: privateShareEmailListType[];
				paginationInfo: PaginationType;
			};
		}
	) {
		if (get(payload, 'paginationInfo.pageNo') === 1) {
			state.currentMetadata.privateShareEmailList = payload.data;
		} else {
			state.currentMetadata.privateShareEmailList = concat(
				state.currentMetadata.privateShareEmailList,
				payload.data
			);
		}
		state.currentMetadata.privateShareEmailListPagination = payload.paginationInfo;
	},
	deletePrivateShareEmail(
		state: FloReducerType,
		{
			payload,
		}: {
			payload?: unknown;
		}
	) {},
	deletePrivateShareEmailSuccess(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: privateShareEmailListType;
		}
	) {
		state.currentMetadata.privateShareEmailList = reject(
			state.currentMetadata.privateShareEmailList,
			['authorizedUserId', payload.authorizedUserId]
		);
		state.currentMetadata.privateShareEmailListPagination.totalRecords =
			state.currentMetadata.privateShareEmailListPagination.totalRecords - 1;
	},
};

export const floPage = createSlice({
	name: 'floPage',
	initialState,
	// @ts-ignore
	reducers: floReducers,
});

export const getFloDetails = floPage.actions.getFloDetails;
export const deleteFloAction = floPage.actions.deleteFloAction;
export const unpublishFloAction = floPage.actions.unpublishFloAction;
export const cloneFloAction = floPage.actions.cloneFloAction;
export const unpublishFloConfirmedAction = floPage.actions.unpublishFloConfirmedAction;
export const deleteFloConfirmedAction = floPage.actions.deleteFloConfirmedAction;
export const setFloDetails = floPage.actions.setFloDetails;
export const updatedFloName = floPage.actions.updatedFloName;
export const setFloErroredAction = floPage.actions.setFloErrored;
export const getFloTracks = floPage.actions.getFloTracks;
export const leaveFloPageAction = floPage.actions.leaveFloPage;
export const setFloTracks = floPage.actions.setFloTracks;
export const setFloPlayList = floPage.actions.setFloPlayList;
export const setResourcesList = floPage.actions.setResourcesList;
export const setPublishedFloTracks = floPage.actions.setPublishedFloTracks;
export const setPublishedElementsConfig = floPage.actions.setPublishedElementsConfig;
export const setIsFloSupportedInIphone = floPage.actions.setIsFloSupportedInIphone;
export const getFloComments = floPage.actions.getFloComments;
export const setFloComments = floPage.actions.setFloComments;
export const addComments = floPage.actions.addComments;
export const setAddComments = floPage.actions.setAddComments;

export const getFloReplies = floPage.actions.getFloReplies;
export const setFloReplies = floPage.actions.setFloReplies;
export const addFloReply = floPage.actions.addFloReply;
export const updateSkipConsentAction = floPage.actions.updateSkipConsent;
export const setAddFloReply = floPage.actions.setAddFloReply;
export const setAnnotationToolAction = floPage.actions.setAnnotationTool;
export const addAnnotationsAction = floPage.actions.addAnnotations;
export const getAnnotationsMeta = floPage.actions.getAnnotationsMeta;
export const setAnnotationsMeta = floPage.actions.setAnnotationsMeta;
export const getAnnotationsByTime = floPage.actions.getAnnotationsByTime;
export const setAnnotationsByTime = floPage.actions.setAnnotationsByTime;
export const removeAnnotationsAction = floPage.actions.removeAnnotations;
export const updateAnnotationAction = floPage.actions.updateAnnotation;
export const sendAnnotationAction = floPage.actions.sendAnnotation;
export const resolveComment = floPage.actions.resolveComment;
export const setResolveComment = floPage.actions.setResolveComment;
export const decisionComment = floPage.actions.decisionComment;
export const setDecisionComment = floPage.actions.setDecisionComment;
export const getCommentsCount = floPage.actions.getCommentsCount;
export const setScrollTo = floPage.actions.setScrollTo;
export const setSettings = floPage.actions.setSettings;
export const deleteComment = floPage.actions.deleteComment;
export const setDeleteComment = floPage.actions.setDeleteComment;
export const deleteReply = floPage.actions.deleteReply;
export const setDeleteReply = floPage.actions.setDeleteReply;
export const setAmplitudeData = floPage.actions.setAmplitudeData;
export const setQualitySelectors = floPage.actions.setQualitySelectors;
export const stageEditComment = floPage.actions.stageEditComment;
export const editComment = floPage.actions.editComment;
export const setEditComment = floPage.actions.setEditComment;
export const editReply = floPage.actions.editReply;
export const setEditReply = floPage.actions.setEditReply;
export const onSSEMessage = floPage.actions.onSSEMessage;
export const setCommentCount = floPage.actions.setCommentCount;
export const setReplyCount = floPage.actions.setReplyCount;
export const initiateSSE = floPage.actions.initiateSSE;
export const setSseEventSource = floPage.actions.setSseEventSource;
export const setSSEFloDetails = floPage.actions.setSSEFloDetails;
export const setUpdatedFloDetails = floPage.actions.setUpdatedFloDetails;
export const getElements = floPage.actions.getElements;
export const setElements = floPage.actions.setElements;
export const getElementsConfig = floPage.actions.getElementsConfig;
export const setElementsConfig = floPage.actions.setElementsConfig;
export const setElementsForGuide = floPage.actions.setElementsForGuide;
export const updateElementsConfig = floPage.actions.updateElementsConfig;
export const updateVoiceOverElementsConfig =
	floPage.actions.updateVoiceOverElementsConfig;
export const deleteElementsConfig = floPage.actions.deleteElementsConfig;
export const toggleElementView = floPage.actions.toggleElementView;
export const setTrimDataAction = floPage.actions.setTrimData;
export const setTrimCurrentTime = floPage.actions.setTrimCurrentTime;
export const setCropDataAction = floPage.actions.setCropData;
export const resetCropData = floPage.actions.resetCropData;
export const changePublishConfiguration = floPage.actions.changePublishConfiguration;
export const changeFloMetaConfiguration = floPage.actions.changeFloMetaConfiguration;
export const setFloOutputMetadata = floPage.actions.setFloOutputMetadata;
export const onPublishFlo = floPage.actions.onPublishFlo;
export const generateNewFlo = floPage.actions.onGenerateFlo;
export const setFloCurrentMetadata = floPage.actions.setFloCurrentMetadata;
export const editTrim = floPage.actions.editTrim;
export const editCrop = floPage.actions.editCrop;
export const generateVoice = floPage.actions.generateVoice;
export const editCtaElement = floPage.actions.editCtaElement;
export const updateElementView = floPage.actions.updateElementView;
export const setCommentAnnotation = floPage.actions.setCommentAnnotation;
export const getGuideData = floPage.actions.getGuideData;
export const getPublishedGuideData = floPage.actions.getPublishedGuideData;
export const updateFloStepTitle = floPage.actions.updateFloStepTitle;
export const updateFloStepDescription = floPage.actions.updateFloStepDescription;
export const deleteFloStep = floPage.actions.deleteFloStep;
export const toggleFloStepMouseClick = floPage.actions.toggleFloStepMouseClick;
export const toggleFloStepMouseClickToggleSuccess =
	floPage.actions.toggleFloStepMouseClickToggleSuccess;
export const deleteFloStepConfirm = floPage.actions.deleteFloStepConfirm;
export const deleteFloStepSuccess = floPage.actions.deleteFloStepSuccess;
export const rearrangeFloStep = floPage.actions.rearrangeFloStep;
export const rearrangeFloStepFailed = floPage.actions.rearrangeFloStepFailed;
export const updateFloStepTitleSuccess = floPage.actions.updateFloStepTitleSuccess;
export const updateFloStepDescriptionSuccess =
	floPage.actions.updateFloStepDescriptionSuccess;

export const setGuideData = floPage.actions.setGuideData;
export const setPublishedGuideData = floPage.actions.setPublishedGuideData;
export const setElementsForPublishedGuide = floPage.actions.setElementsForPublishedGuide;
export const setGuideError = floPage.actions.setGuideError;
export const setActiveTab = floPage.actions.setActiveTab;
export const downloadFlo = floPage.actions.downloadFlo;
export const downloadGIFFlo = floPage.actions.downloadGIFFlo;

export const setDownloadStatus = floPage.actions.setDownloadStatus;
export const deleteTranscribe = floPage.actions.deleteTranscribe;
export const deleteTranscribeSuccess = floPage.actions.deleteTranscribeSuccess;
export const getAlternativeOutputs = floPage.actions.getAlternativeOutputs;
export const setAlternativeOutputs = floPage.actions.setAlternativeOutputs;
export const addHotspotSuccess = floPage.actions.addHotspotSuccess;
export const updateHotspot = floPage.actions.updateHotspot;
export const updateMouseClick = floPage.actions.updateMouseClick;
export const updateHotspotSuccess = floPage.actions.updateHotspotSuccess;
export const updateHotspotFailed = floPage.actions.updateHotspotFailed;
export const resetHotspot = floPage.actions.resetHotspot;
export const availableCacheEntries = floPage.actions.availableCacheEntries;

export const addManualStep = floPage.actions.addManualStep;
export const addManualStepSuccess = floPage.actions.addManualStepSuccess;
export const uploadStepImage = floPage.actions.uploadStepImage;
export const uploadStepImageSuccess = floPage.actions.uploadStepImageSuccess;
export const addClickToStep = floPage.actions.addClickToStep;
export const updateFloStepSuccess = floPage.actions.updateFloStepSuccess;
export const addClickToStepSuccess = floPage.actions.addClickToStepSuccess;
export const getFloResourceGroup = floPage.actions.getFloResourceGroup;
export const getFloResourceGroupSuccess = floPage.actions.getFloResourceGroupSuccess;
export const setFloPreviewResourceGroup = floPage.actions.setFloPreviewResourceGroup;
export const setStepLoader = floPage.actions.setStepLoader;
export const setStepNewlyAddedClick = floPage.actions.setStepNewlyAddedClick;
export const setDuration = floPage.actions.setDuration;
export const setHotspotWrapperDims = floPage.actions.setHotspotWrapperDims;
export const saveHotspot = floPage.actions.saveHotspot;
export const changeShareLinkType = floPage.actions.changeShareLinkType;
export const addPrivateShareEmail = floPage.actions.addPrivateShareEmail;
export const getPrivateShareEmailList = floPage.actions.getPrivateShareEmailList;
export const deletePrivateShareEmail = floPage.actions.deletePrivateShareEmail;
export const changeShareLinkTypeSuccess = floPage.actions.changeShareLinkTypeSuccess;
export const addPrivateShareEmailSuccess = floPage.actions.addPrivateShareEmailSuccess;
export const setPrivateShareEmailList = floPage.actions.setPrivateShareEmailList;
export const deletePrivateShareEmailSuccess =
	floPage.actions.deletePrivateShareEmailSuccess;

export const getPresenterData = floPage.actions.getPresenterData;
export const getPresenterPreviewData = floPage.actions.getPresenterPreviewData;
export const setPresenterPreviewData = floPage.actions.setPresenterPreviewData;
export const savePresenterVideoBackground = floPage.actions.savePresenterVideoBackground;
export const togglePresenterVideo = floPage.actions.togglePresenterVideo;
export const setEnablePresenterVideo = floPage.actions.setEnablePresenterVideo;
export const setPresenterData = floPage.actions.setPresenterData;
export const showPresenterBackground = floPage.actions.showPresenterBackground;
export const updatePresenterPreviewData = floPage.actions.updatePresenterPreviewData;
export const setPreviewSnippets = floPage.actions.setPreviewSnippets;
export const setBGMusicData = floPage.actions.setBGMusicData;
export const showBGMusic = floPage.actions.showBGMusic;
export const saveBGMusic = floPage.actions.saveBGMusic;
export const saveText = floPage.actions.saveText;
export const showTextElement = floPage.actions.showText;

export default floPage.reducer;
