export const API_BASE = process.env.REACT_APP_API_ENDPOINT || 'https://api.signoff.ai';
export const EVENT_SOURCE_BASE =
	process.env.REACT_APP_EVENT_SOURCE_API_ENDPOINT || 'https://hermes.signoff.ai';
export const WEBSITE_PRICING_PAGE =
	process.env.REACT_APP_WEBSITE_PRICING_PAGE || 'https://floik.com/pricing';
export const ENVIRONMENT =
	(process.env.REACT_APP_ENVIRONMENT || '') === 'production'
		? 'production'
		: 'development';
export const POSTPEND_TERM = ENVIRONMENT === 'production' ? '' : '-dev';
export const END_USER_SCRIPT_SRC =
	ENVIRONMENT === 'production'
		? 'https://www.floik.com/exe/main.js'
		: 'https://www.signoff.ai/exe/main.js';
