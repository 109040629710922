import classNames from 'classnames';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';
import Translations from '../../Common/Translate.utils';
import { ProcessingImg } from '../ImageGallery/ImageGallery';
import styles from './Image.module.css';

const Image = ({
	src,
	alt,
	id,
	classes = { imageWrapper: '', image: '' },
	onLoad,
	title,
}: {
	src: string;
	alt?: string;
	id?: string;
	classes?: { imageWrapper?: string; image?: string };
	onLoad?(e: Event): void;
	title?: string;
}) => {
	const { IMAGE_LOADING_TEXT } = Translations;

	const [imageLoadStatus, setImageLoadStatus] = useState('LOADING');
	const [imgSrc, setImgSrc] = useState(src);

	const onImageLoad = useCallback(
		(event: Event) => {
			setImageLoadStatus('LOADED');
			onLoad?.(event);
		},
		[setImageLoadStatus, id, src, onLoad]
	);

	const onImageError = useCallback(() => {
		setImageLoadStatus('ERROR');
		setImgSrc('https://zeus.floik.com/system/w3/media/images/not-found-avatar.svg');
	}, [setImageLoadStatus, id, src]);

	const isLoading = imageLoadStatus === 'LOADING';
	const loadStatusError = imageLoadStatus === 'ERROR';

	return (
		<div className={classNames(styles.imageWrapper, classes.imageWrapper)} title={title}>
			<img
				id={id}
				className={classNames(styles.image, classes.image, {
					[styles.imageWhileLoading]: isLoading,
				})}
				src={imgSrc}
				alt={alt}
				onLoad={!loadStatusError ? onImageLoad : noop}
				onError={onImageError}
			/>
			{isLoading && <ProcessingImg text={IMAGE_LOADING_TEXT} />}
		</div>
	);
};

export default Image;
