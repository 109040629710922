import { floPage } from './Flo.reducers';

export const getVoiceTranscribes = floPage.actions.getVoiceTranscribes;
export const getSubtitles = floPage.actions.getSubtitles;
export const showBlurOrSpotlightElements = floPage.actions.showBlurOrSpotlightElements;
export const showCtaElements = floPage.actions.showCtaElements;
export const showHotspotElements = floPage.actions.showHotspotElements;
export const showImageElements = floPage.actions.showImageElements;
export const showTextElements = floPage.actions.showTextElements;
export const generateSubtitlesForVoice = floPage.actions.generateSubtitlesForVoice;
export const toggleVoiceTranscribes = floPage.actions.toggleVoiceTranscribes;
export const toggleVoiceTranscribesSuccess =
	floPage.actions.toggleVoiceTranscribesSuccess;
export const setVoiceOverVTT = floPage.actions.setVoiceOverVTT;
export const showVoiceOverPanel = floPage.actions.showVoiceOver;
export const showSubtitlesPanel = floPage.actions.showSubtitlesPanel;
export const setSubtitles = floPage.actions.setSubtitles;
export const setVoiceOverTranscribeScript = floPage.actions.setVoiceOverTranscribeScript;
export const setVoiceOverSubtitleStatus = floPage.actions.setVoiceOverSubtitleStatus;
export const setVoiceOverTranscribeError = floPage.actions.setVoiceOverTranscribeError;
export const setVoiceOverSubtitleError = floPage.actions.setVoiceOverSubtitleError;
export const setVoiceOverVoiceStatus = floPage.actions.setVoiceOverVoiceStatus;
export const updateVoiceOverTranscribe = floPage.actions.updateVoiceOverTranscribe;
export const rephraseVoiceOverTranscribe = floPage.actions.rephraseVoiceOverTranscribe;
export const setRephraseVoiceOverTranscribe =
	floPage.actions.setRephraseVoiceOverTranscribe;
export const rephraseVoiceOverTranscribeError =
	floPage.actions.rephraseVoiceOverTranscribeError;
export const toggleEnableVoiceOverTranscribe =
	floPage.actions.toggleEnableVoiceOverTranscribe;

export const addNewTranscribe = floPage.actions.addNewTranscribe;
export const updateVoiceOverTranscribeSuccess =
	floPage.actions.updateVoiceOverTranscribeSuccess;
export const updateVoiceOverTranscribeError =
	floPage.actions.updateVoiceOverTranscribeError;
export const resetTranscribe = floPage.actions.resetTranscribe;
export const deleteTranscribe = floPage.actions.deleteTranscribe;
export const getElementVoiceResource = floPage.actions.getElementVoiceResource;
export const setElementVoiceResource = floPage.actions.setElementVoiceResource;
export const setElementVoiceResourceStatus =
	floPage.actions.setElementVoiceResourceStatus;
export const generateVoiceForElement = floPage.actions.generateVoiceForElement;
export const generateVoiceForElementSuccess =
	floPage.actions.generateVoiceForElementSuccess;
export const generateVoiceForElementError = floPage.actions.generateVoiceForElementError;

export const addHotspot = floPage.actions.addHotspot;
export const resetHotspot = floPage.actions.resetHotspot;
export const onInteractionItemClick = floPage.actions.onInteractionItemClick;
export const showInteractiveCueEditor = floPage.actions.showInteractiveCueEditor;
export const addButtonToActiveEditElement = floPage.actions.addButtonToActiveEditElement;
export const updateEditorValue = floPage.actions.updateEditorValue;
export const mergeEditorValue = floPage.actions.mergeEditorValue;
export const enableSaveInteractiveCueEditor =
	floPage.actions.enableSaveInteractiveCueEditor;
export const toggleInteractiveCueEditorFooter = floPage.actions.toggleInteractiveCueEditorFooter;
export const removeButtonFromActiveEditElement =
	floPage.actions.removeButtonFromActiveEditElement;
export const setEditorError = floPage.actions.setEditorError;
export const setEditingId = floPage.actions.setEditingId;
export const setTempValue = floPage.actions.setTempValue;
export const setTimeSliderValue = floPage.actions.setTimeSliderValue;
export const saveImage = floPage.actions.saveImage;
export const resetStepImage = floPage.actions.resetStepImage;
export const rearrangeHotspot = floPage.actions.rearrangeHotspot;
export const updateTempValue = floPage.actions.updateTempValue;
export const saveBlur = floPage.actions.saveBlur;
export const setSelectedGuideStep = floPage.actions.setSelectedGuideStep;
export const setGuideActiveElement = floPage.actions.setGuideActiveElement;
export const setGuideImageLoadStatus = floPage.actions.setGuideImageLoadStatus;
export const toggleInteractiveWithCta = floPage.actions.toggleInteractiveWithCta;
export const toggleInteractiveEditor = floPage.actions.toggleInteractiveEditor;
export const clearPrevEditorElement = floPage.actions.clearPrevEditorElement;
export const setGuideGalleryImages = floPage.actions.setGuideGalleryImages;
export const saveText = floPage.actions.saveText;
