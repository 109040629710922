import { get, includes } from 'lodash';

export function IsIphone() {
	return includes(
		[
			// 'iPad Simulator',
			'iPhone Simulator',
			// 'iPod Simulator',
			// 'iPad',
			'iPhone',
			// 'iPod'
		],
		get(navigator, 'userAgentData.platform') || get(navigator, 'platform') || ''
	);
}

export const checkTabletIpadPlatform = (): boolean => {
	if (/iPad|iPhone|iPod/.test(navigator.platform)) {
		return true;
	} else if (
		/(ipad|tablet|android|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
			navigator.userAgent.toLowerCase()
		)
	) {
		return true;
	} else {
		return navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
	}
};

export const checkIsLandscapeTablet = () => {
	const isTabletIpadPlatform = checkTabletIpadPlatform();
	const isLandscape = window.matchMedia('(orientation: landscape)').matches;
	const mediaLandscapeForTablet =
		window.matchMedia('(max-width:1024px) and (orientation: landscape)').matches &&
		isTabletIpadPlatform;

	return mediaLandscapeForTablet || (isTabletIpadPlatform && isLandscape);
};
