import { Box, Button, Tooltip } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { IconCheck, IconHeadset, IconReload } from '@tabler/icons-react';
import classNames from 'classnames';
import { find, get, map } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLinkInNewTab } from '../../Common/Common.utils';
import { ReactComponent as FloikSilver } from '../../Common/images/Gray-Logo-White-Text.svg';
import { ReactComponent as InfoIcon } from '../../Common/images/info-filled-circle.svg';
import subscriberPlanLifetimeImg from '../../Common/images/subscriber-plan-lifetime.svg';
import subscriberPlanProPlusImg from '../../Common/images/subscriber-plan-pro-plus.svg';
import subscriberPlanProImg from '../../Common/images/subscriber-plan-pro.svg';
import subscriberPlanStarterImg from '../../Common/images/subscriber-plan-starter.svg';
import subscriberPlanTeamImg from '../../Common/images/subscriber-plan-team.svg';
import Translations, { translate } from '../../Common/Translate.utils';
import { showDialogAction } from '../Dialog/Dialog.reducer';
import Loader from '../Loader/Loader';
import styles from './BillingDialog.module.css';
import {
	BillingDialogState,
	cancelBillingDetailsAction,
	getBillingDetailsAction,
	PricingType,
	refreshPaymentStatus,
	renewBillingDetailsAction,
	retryPayment,
	SubscriptionType,
	upgradeBillingDetailsAction,
} from './BillingDialog.reducer';

const PLAN_SUBSCRIPTION_INFO_MAP: {
	[key: string]: {
		image: string;
	};
} = {
	STARTER: { image: subscriberPlanStarterImg },
	TEAMS: { image: subscriberPlanTeamImg },
	PRO: { image: subscriberPlanProImg },
	RHBUSINESS: { image: subscriberPlanLifetimeImg },
	RHPRO: { image: subscriberPlanLifetimeImg },
	RHSOLO: { image: subscriberPlanLifetimeImg },
	'PRO+': { image: subscriberPlanProPlusImg },
};

type TabItemType = {
	labelKey: string;
	id: string;
	frequencyLabel: string;
};

const TABS: TabItemType[] = [
	{
		labelKey: 'PRICING_SUBSCRIPTION_TAB_1_TEXT',
		id: 'monthly',
		frequencyLabel: 'month',
	},
	{
		labelKey: 'PRICING_SUBSCRIPTION_TAB_2_TEXT',
		id: 'yearly',
		frequencyLabel: 'year',
	},
];

export const PAYMENT_STATUSES = {
	PENDING: 'PAYMENT_PENDING',
	FAILED: 'PAYMENT_FAILED',
	COMPLETED: 'PAYMENT_COMPLETED',
};

const BillingDialog = () => {
	const {
		PRICING_BOOK_SLOT_TXT,
		PRICING_QA_TXT,
		PRICING_RELOAD_TOOLTIP_TXT,
		PROFILE_BILLING_PAYMENT_PENDING_TEXT,
		PROFILE_BILLING_PAYMENT_FAILED_TEXT,
		PROFILE_BILLING_PAYMENT_PAGE_BUTTON_TEXT,
		PROFILE_BILLING_RETRY_PAYMENT_BUTTON_TEXT,
		PRICING_CURRENT_PLAN_TXT,
		PRICING_CURRENT_PLAN_BUTTON_TXT,
		PRICING_UPGRADE_PLAN_BUTTON_TXT,
		PRICING_DOWNGRADE_PLAN_BUTTON_TXT,
		PRICING_CONTACT_US_PLAN_BUTTON_TXT,
		PRICING_CANCEL_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT,
		PRICING_CANCEL_SUBSCRIPTION_POPUP_CANCEL_POPUP_TXT,
		PROFILE_SUBSCRIPTION_TITLE_TEXT,
		PRICING_RENEWAL_DATE_TITLE,
		PRICING_TRIAL_DATE_TITLE,
		PRICING_RECOMMENDED_PLAN_TEXT,
		PRICING_TRIAL_PERIOD_TEXT,
		PRICING_UPGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT,
		PRICING_DOWNGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT,
		PRICING_BOOK_SLOT_LINK,
		PRICING_CONTACT_SUPPORT_LINK,
	} = Translations;
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState<TabItemType | undefined>();
	const { data: billingData, subscriptions } = useSelector<unknown, BillingDialogState>(
		(state) => get(state, 'billingDialog')
	);
	const [loader, setLoader] = useState(true);
	const prevLoadingState = useRef<boolean>(null);
	const loadingBillingDetails = useSelector((state) => get(state, 'loaders.billing') > 0);

	const [subscriptionsList, setSubscriptionsList] = useState<SubscriptionType[]>([]);
	const paymentLoader = useSelector((state) => get(state, 'loaders.paymentStatus') > 0);

	const isPaymentPending =
		billingData.newSubscriptionBillingStatus === PAYMENT_STATUSES.PENDING;
	const hasPaymentFailed =
		billingData.newSubscriptionBillingStatus === PAYMENT_STATUSES.FAILED;
	const existingSubscriptionInfo = PLAN_SUBSCRIPTION_INFO_MAP[
		billingData.existingSubscriptionKey
	] || {
		image: subscriberPlanStarterImg,
	};
	const isCurrentSubscriptionPaid = billingData.existingSubscriptionPaidPlan || false;
	const upgradeText = billingData.renewalPayment ? 'Renew' : 'Upgrade';
	const upgradingText = billingData.renewalPayment
		? 'Renewing'
		: billingData.newSubscriptionUpgrade
		? 'Upgrading'
		: 'Downgrading';

	useEffect(() => {
		let defaultActiveTab = find(TABS, [
			'id',
			get(billingData, 'existingPaymentPricePeriod'),
		]);
		if (billingData) {
			defaultActiveTab = defaultActiveTab || TABS[0];
			setActiveTab(defaultActiveTab);
		}
	}, [billingData, setActiveTab]);

	useEffect(() => {
		if (prevLoadingState.current && !loadingBillingDetails && loader) {
			setLoader(false);
		}
		// @ts-ignore
		prevLoadingState.current = loadingBillingDetails;
	}, [setLoader, loadingBillingDetails, loader, prevLoadingState]);

	const onUpgradePlan = useCallback(
		(plan: PricingType) => {
			const upgradeAction = () => {
				dispatch(
					upgradeBillingDetailsAction({
						// @ts-ignore
						subscriptionId: plan.subscriptionId,
					})
				);
			};

			if (isCurrentSubscriptionPaid || (!isCurrentSubscriptionPaid && !plan.upgrade)) {
				// @ts-ignore
				const upgradeText = plan.upgrade
					? PRICING_UPGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT
					: PRICING_DOWNGRADE_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT;
				// @ts-ignore
				const messageHeader = plan.messageHeader || '';
				// @ts-ignore
				const messageDescription = plan.messageDescription || '';

				dispatch(
					showDialogAction({
						header: translate(messageHeader, messageHeader),
						body: translate(messageDescription, messageDescription),
						showCancel: true,
						showConfirm: true,
						confirmButtonText: upgradeText,
						cancelButtonText: PRICING_CANCEL_SUBSCRIPTION_POPUP_CANCEL_POPUP_TXT,
						onConfirm: () => {
							if (plan.upgrade) {
								upgradeAction();
							} else {
								handleContactSupportClick();
							}
						},
					})
				);
			} else {
				upgradeAction();
			}
		},
		[billingData, dispatch, isCurrentSubscriptionPaid]
	);

	const onRenewPlan = useCallback(() => {
		dispatch(renewBillingDetailsAction({}));
	}, [billingData]);

	useEffect(() => {
		setLoader(true);
		dispatch(getBillingDetailsAction(''));
	}, []);

	useEffect(() => {
		const newSubscriptionsList = map(subscriptions, (item) => ({
			...item,
			features:
				get(Translations, get(item, 'features[0]'))?.split(';') || get(item, 'features'),
			featureHeader: get(Translations, get(item, 'featureHeader')),
		}));
		setSubscriptionsList(newSubscriptionsList);
	}, [subscriptions, setSubscriptionsList]);

	const onCancelPlan = useCallback(() => {
		dispatch(
			showDialogAction({
				header: Translations.PRICING_CANCEL_SUBSCRIPTION_POPUP_TITLE_TXT,
				body: Translations.PRICING_CANCEL_SUBSCRIPTION_POPUP_DESC_TXT,
				showCancel: true,
				showConfirm: true,
				confirmButtonType: 'delete',
				confirmButtonText: PRICING_CANCEL_SUBSCRIPTION_POPUP_CONFIRM_POPUP_TXT,
				cancelButtonText: PRICING_CANCEL_SUBSCRIPTION_POPUP_CANCEL_POPUP_TXT,
				onConfirm: (dispatch: Dispatch) => {
					dispatch(cancelBillingDetailsAction({}));
				},
			})
		);
	}, [billingData]);

	const goToPaymentPage = useCallback(() => {
		if (isPaymentPending) {
			if (billingData.newSubscriptionPaymentLink) {
				openLinkInNewTab(billingData.newSubscriptionPaymentLink);
			}
		} else {
			dispatch(
				retryPayment({
					subscriptionId: billingData.newSubscriptionTypeId,
				})
			);
		}
	}, [billingData]);

	const refreshPaymentStatusClick = useCallback(() => {
		dispatch(
			refreshPaymentStatus({
				subscriptionId: get(billingData, 'newSubscriptionTypeId'),
				newSubscriptionSessionId: get(billingData, 'newSubscriptionSessionId'),
			})
		);
	}, [billingData]);

	const onTabClick = useCallback(
		(tabItem: TabItemType) => {
			setActiveTab(tabItem);
		},
		[setActiveTab]
	);

	const renderExistingSubscription = () => {
		const renderSubscriptionRenewal = () => {
			const showRenewPaymentButton =
				!isPaymentPending &&
				(billingData.existingSubscriptionRenewPayment ||
					billingData.cancelExistingSubscription);
			if (get(billingData, 'existingPaymentExpireAtDate'))
				return (
					<div className={styles.content}>
						<div className={styles.subHeading}>
							{billingData.existingSubscriptionTrial
								? PRICING_TRIAL_DATE_TITLE
								: PRICING_RENEWAL_DATE_TITLE}
						</div>
						<div className={styles.subHeadingValue}>
							{get(billingData, 'existingPaymentExpireAtDate')}
							{/*{showRenewPaymentButton && (*/}
							{/*	<Button*/}
							{/*		variant="contained"*/}
							{/*		className={classNames(styles.renewBtn)}*/}
							{/*		disableElevation*/}
							{/*		disableFocusRipple*/}
							{/*		onClick={() => {*/}
							{/*			onRenewPlan();*/}
							{/*		}}*/}
							{/*	>*/}
							{/*		{PRICING_RENEW_SUBSCRIPTION_TXT}*/}
							{/*		/!*<IconReload*!/*/}
							{/*		/!*	size={16}*!/*/}
							{/*		/!*	onClick={refreshPaymentStatusClick}*!/*/}
							{/*		/!*	className={classNames(styles.reloadIcon)}*!/*/}
							{/*		/!*></IconReload>*!/*/}
							{/*	</Button>*/}
							{/*)}*/}
						</div>
					</div>
				);
		};
		// useEffect(() => {
		// 	return () => {
		// 		dispatch(pushState(location.pathname));
		// 	};
		// }, []);

		let period = '';
		if (billingData.existingPaymentPricePeriod) {
			period = billingData.existingSubscriptionTrial
				? PRICING_TRIAL_PERIOD_TEXT
				: ` (${billingData.existingPaymentPricePeriod})`;
		}

		return (
			<div className={styles.contentWrapper}>
				<Box className={styles.content}>
					<div className={styles.subHeading}>{PRICING_CURRENT_PLAN_TXT}</div>
					<div className={styles.subHeadingValue}>
						{billingData.existingSubscriptionDisplayName}
						{period}
					</div>
				</Box>
				{renderSubscriptionRenewal()}
			</div>
		);
	};

	const renderUpgradePlan = () => {
		return (
			<Box
				className={classNames(styles.upgradePlanWrapper, {
					[styles.withMessage]: isPaymentPending || hasPaymentFailed,
				})}
			>
				{(isPaymentPending || hasPaymentFailed) && (
					<div
						className={classNames(styles.upgradePlanContainer, {
							[styles.failed]: hasPaymentFailed,
						})}
					>
						<div className={styles.paymentStatusWrapper}>
							<div className={styles.upgradePendingTextWrapper}>
								<div className={styles.upgradePendingText}>
									<InfoIcon></InfoIcon>
									{upgradingText} to {billingData.newSubscriptionName}{' '}
									{billingData.newPaymentPricePeriod} plan
								</div>
								<Tooltip
									title={PRICING_RELOAD_TOOLTIP_TXT}
									arrow
									placement="bottom"
									classes={{
										popper: styles.tooltip,
									}}
								>
									<IconReload
										size={20}
										onClick={refreshPaymentStatusClick}
										className={classNames(styles.reloadIcon, {
											[styles.rotateIcon]: paymentLoader,
										})}
									></IconReload>
								</Tooltip>
							</div>
							<div className={styles.paymentStatusContainer}>
								<div
									className={classNames(styles.paymentStatus, {
										[styles.paymentStatusFail]: hasPaymentFailed,
									})}
								>
									{isPaymentPending
										? PROFILE_BILLING_PAYMENT_PENDING_TEXT
										: PROFILE_BILLING_PAYMENT_FAILED_TEXT.replace(
												'%UPGRADE%',
												upgradingText
										  )}
								</div>
								<Button className={styles.paymentRetryButton} onClick={goToPaymentPage}>
									{isPaymentPending
										? PROFILE_BILLING_PAYMENT_PAGE_BUTTON_TEXT
										: PROFILE_BILLING_RETRY_PAYMENT_BUTTON_TEXT}
								</Button>
							</div>
						</div>
					</div>
				)}
				<div className={styles.tabsWrapper}>
					<ul className={styles.tabsList}>
						{TABS.map((tabItem) => (
							<li
								key={tabItem.id}
								className={classNames(styles.tabsListItem, {
									[styles.activeTabsListItem]: tabItem.id === activeTab?.id,
								})}
							>
								<Button
									onClick={() => {
										onTabClick(tabItem);
									}}
									variant="text"
									className={styles.tabsListItemLink}
								>
									{get(Translations, tabItem.labelKey)}
								</Button>
							</li>
						))}
					</ul>
				</div>
				{(subscriptionsList || []).length ? (
					<ul
						className={classNames(styles.plansList, {
							[styles.plansListWithPaymentError]: hasPaymentFailed,
						})}
					>
						{(subscriptionsList || []).map((planItem) => {
							const priceInfo = get(planItem, `pricing.${activeTab?.id}`);
							let buttonText = PRICING_DOWNGRADE_PLAN_BUTTON_TXT;
							if (get(priceInfo, 'planType') === 'CUSTOM') {
								buttonText = PRICING_CONTACT_US_PLAN_BUTTON_TXT;
							} else if (get(priceInfo, 'upgrade')) {
								buttonText = PRICING_UPGRADE_PLAN_BUTTON_TXT;
							}
							let price = 'Free';
							if (get(priceInfo, 'planType') === 'PAID') {
								price = `$${get(priceInfo, 'amount')}`;
							} else if (get(priceInfo, 'planType') === 'CUSTOM') {
								price = 'Custom';
							}
							return priceInfo ? (
								<li className={styles.plansListItem} key={get(planItem, 'productId')}>
									<div
										className={styles.planCardWrapper}
										style={{
											backgroundImage: `url(${
												PLAN_SUBSCRIPTION_INFO_MAP[planItem.productName]?.image ||
												subscriberPlanStarterImg
											})`,
										}}
									>
										{get(priceInfo, 'recommended') && (
											<div className={styles.recommendedFlag}>
												{PRICING_RECOMMENDED_PLAN_TEXT}
											</div>
										)}
										<FloikSilver />
										<span className={styles.planName}>
											{get(planItem, 'productName')}
										</span>
									</div>
									<div className={styles.plansListItemNameWrapper}>
										<div className={styles.plansListItemName}>
											{get(planItem, 'productNameLowercase')}
										</div>
										<div className={styles.subTitle}>
											{get(Translations, planItem.productDescription) ||
												planItem.productDescription}
										</div>
									</div>
									<div className={styles.priceWrapper}>
										<span className={styles.price}>{price}</span>
										{!!get(priceInfo, 'amount') && (
											<span className={styles.priceFrequencyWrapper}>
												/ {activeTab?.frequencyLabel}
											</span>
										)}
									</div>
									{get(priceInfo, 'currentSubscription') ? (
										<Button
											className={classNames(styles.planUpgradeButton, styles.secondary)}
											variant="outlined"
											disabled
										>
											<span className={styles.secondaryButtonText}>
												{PRICING_CURRENT_PLAN_BUTTON_TXT}
											</span>
										</Button>
									) : (
										<Button
											className={styles.planUpgradeButton}
											variant="contained"
											onClick={() => {
												if (get(priceInfo, 'planType') === 'CUSTOM') {
													handleContactSupportClick();
												} else {
													onUpgradePlan(priceInfo);
												}
											}}
											key={'plan.subscriptionId'}
										>
											{buttonText}
										</Button>
									)}
									{get(planItem, 'features') && (
										<div className={styles.planFeaturesListWrapper}>
											<div className={styles.planFeaturesListLabel}>This includes:</div>
											{get(planItem, 'featureHeader') && (
												<div className={styles.planFeaturesListItem}>
													{get(planItem, 'featureHeader')}
												</div>
											)}
											<ul className={styles.planFeaturesList}>
												{get(planItem, 'features', []).map((feature: string) => (
													<li className={styles.planFeaturesListItem}>
														<IconCheck size={16} className={styles.iconCheck}></IconCheck>
														<div className={styles.planFeaturesListItemLabel}>
															{feature}
														</div>
													</li>
												))}
											</ul>
										</div>
									)}
								</li>
							) : null;
						})}
					</ul>
				) : null}
			</Box>
		);
	};

	const handleBookSlotClick = useCallback(() => {
		window.open(PRICING_BOOK_SLOT_LINK, '_blank');
	}, []);

	const handleContactSupportClick = useCallback(() => {
		window.open(PRICING_CONTACT_SUPPORT_LINK, '_blank');
	}, []);

	const renderContactUsSection = () => {
		return (
			<div className={styles.contactUsWrapper}>
				<div className={styles.contactUsTextContainer}>
					<IconHeadset width={20} height={20} className={styles.icon} />
					<div className={styles.contactUsText}>{PRICING_QA_TXT}</div>
				</div>
				<Button
					className={classNames(styles.whiteBtn, styles.noMargin)}
					variant="contained"
					onClick={handleBookSlotClick}
				>
					{PRICING_BOOK_SLOT_TXT}
				</Button>
			</div>
		);
	};

	if (loader) {
		return (
			<Loader
				className={styles.loaderContainer}
				wrapperClass={styles.pageLoader}
				nodelay
			/>
		);
	}
	return (
		<Box>
			<Box id="responsive-dialog-title" className={styles.title}>
				{PROFILE_SUBSCRIPTION_TITLE_TEXT}
			</Box>
			{renderExistingSubscription()}
			{renderUpgradePlan()}
			{renderContactUsSection()}
		</Box>
	);
};

export default BillingDialog;
