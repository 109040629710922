import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import moment from 'moment';
import { BrandingDialogState } from '../BrandingDialog/BrandingDialog.types';

const initialState = Object.freeze({
	data: {
		floSpaceId: '',
		existingSubscriptionCreatedAt: '',
		existingSubscriptionTypeId: '',
		existingSubscriptionName: '',
		existingSubscriptionStartAt: '',
		existingSubscriptionExpireAt: '',
		existingSubscriptionBillingStatus: '',
		upgradeAvailable: true,
		upgradePlanId: '',
		upgradePlanName: '',
		paymentRequired: true,
		newSubscriptionBillingStatus: null,
		newSubscriptionName: null,
		newSubscriptionTypeId: null,
	},
});

export interface BillingDialogState {
	data: BillingType;
	subscriptions: SubscriptionType[];
}

export type upgradePlanType = {
	productKey: string;
	productName: string;
	productDescription: string;
	displayOrder: number;
	recommended: boolean;
	upgradeOptions: [];
};

export type PricingType = {
	subscriptionId: string;
	recommended: boolean;
	amount: number;
	currency: string;
	currentSubscription: boolean;
	upgrade: boolean;
	messageHeader: string | null;
	messageDescription: string | null;
};

export type SubscriptionType = {
	productId: string;
	productName: string;
	productDescription: string;
	displayOrder: number;
	features: string[];
	pricing: {
		[key: string]: PricingType;
	};
	productNameLowercase?: string;
};

export type BillingType = {
	floSpaceId: string;
	existingSubscriptionKey: string;
	existingSubscriptionCreatedAt: string;
	existingSubscriptionTypeId: string;
	existingSubscriptionName: string;
	existingSubscriptionStartAt: string;
	existingSubscriptionExpireAt: string;
	existingSubscriptionBillingStatus: string;
	existingPaymentPricePeriod: string;
	existingSubscriptionRenewPayment: boolean;
	existingSubscriptionPaidPlan?: boolean;
	existingSubscriptionDisplayName?: string;
	existingSubscriptionTrial?: boolean;
	renewalPayment: boolean;
	cancelExistingSubscription: boolean;
	upgradeAvailable: boolean;
	upgradePlanId: string;
	upgradePlanName: string;
	paymentRequired?: boolean;
	newSubscriptionBillingStatus?: string;
	newSubscriptionName?: string;
	newSubscriptionTypeId?: string;
	newSubscriptionUpgrade?: boolean;
	existingPaymentExpireAtDate?: string;
	subscriptionUpgradableTo: upgradePlanType[];
	newSubscriptionPaymentLink: string | null;
	newPaymentPricePeriod: string;
	upgrade: boolean;
	messageHeader: string;
	messageDescription: string;
};

const billingDialogReducers = {
	getBillingDetails(state: BillingDialogState, {}: {}) {},
	getSubscriptionProducts(state: BillingDialogState) {},
	getSubscriptionProductsSuccess(
		state: BillingDialogState,
		{
			payload,
		}: {
			payload: SubscriptionType[];
		}
	) {
		state.subscriptions = payload.map((item) => ({
			...item,
			productNameLowercase: get(item, 'productName', '').toLowerCase(),
		}));
	},
	getBillingDetailsActionSuccess(
		state: BillingDialogState,
		{
			payload,
		}: {
			payload: BillingType;
		}
	) {
		const existingPaymentExpireAtDate = payload.existingSubscriptionExpireAt
			? moment(payload.existingSubscriptionExpireAt).format('MMM DD, YYYY')
			: '';
		state.data = { ...payload, existingPaymentExpireAtDate };
	},
	upgradeBillingDetailsAction(
		state: BrandingDialogState,
		{
			payload,
		}: {
			payload: {
				planId: string;
			};
		}
	) {},
	renewBillingDetailsAction(state: BrandingDialogState) {},
	cancelBillingDetailsAction: function (state: BrandingDialogState) {},
	refreshPaymentStatus() {},
	retryPayment() {},
};

export const billingDialogReducer = createSlice({
	name: 'billingDialogReducer',
	initialState,
	// @ts-ignore
	reducers: billingDialogReducers,
});

export const getBillingDetailsAction = billingDialogReducer.actions.getBillingDetails;

export const getBillingDetailsActionSuccess =
	billingDialogReducer.actions.getBillingDetailsActionSuccess;

export const refreshPaymentStatus = billingDialogReducer.actions.refreshPaymentStatus;

export const retryPayment = billingDialogReducer.actions.retryPayment;

export const upgradeBillingDetailsAction =
	billingDialogReducer.actions.upgradeBillingDetailsAction;

export const renewBillingDetailsAction =
	billingDialogReducer.actions.renewBillingDetailsAction;

export const cancelBillingDetailsAction =
	billingDialogReducer.actions.cancelBillingDetailsAction;

export const getSubscriptionProducts =
	billingDialogReducer.actions.getSubscriptionProducts;
export const getSubscriptionProductsSuccess =
	billingDialogReducer.actions.getSubscriptionProductsSuccess;

export default billingDialogReducer.reducer;
