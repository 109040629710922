import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
	IconPlayerPauseFilled,
	IconPlayerPlayFilled,
	IconReload,
	IconUpload,
} from '@tabler/icons-react';
import classNames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { get, min, noop, some, throttle } from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridCellProps, InfiniteLoader } from 'react-virtualized';
import uniqId from 'uniqid';
import { captureScreenshot } from '../../Common/Common.utils';
import isFeatureEnabled from '../../Common/DiamondFeature.hook';
import { ReactComponent as ProcessingSvg } from '../../Common/images/ProcessingLoader.svg';
import Translations, { translate } from '../../Common/Translate.utils';
import { showDiamondFeaturePopup } from '../../Containers/Routes/Routes.reducers';
import { floSpaceSelector } from '../../Pages/ManageFlos/ManageFlos.selector';
import { RootState } from '../../store';
import Button from '../Button/Button';
import DiamondIcon from '../DiamondIcon/DiamondIcon';
import ImageElement from '../Image/Image';
import Loader from '../Loader/Loader';
import { setErrorToast } from '../Notification/Toasts.reducers';
import VideoScreenCapture from '../VideoScreenCapture/VideoScreenCapture';
import styles from './ImageGallery.module.css';
import {
	Category,
	GalleryImgType,
	getGalleryFilesAction,
	getGallerySystemFilesAction,
	hideFileGalleryAction,
	ImageGalleryReducerType,
	uploadGalleryFileAction,
} from './ImageGallery.reducer';

export const ProcessingImg = ({ text }: { text: string }) => (
	<div className={styles.processingLoaderContainer}>
		<ProcessingSvg className={styles.processingSvg} />
		<span className={classNames(styles.processingText, 'padding-top')}>{text}</span>
	</div>
);

export const ProcessingAudio = ({ text }: { text: string }) => (
	<div className={styles.processingLoaderContainerImg}>
		<span className={styles.processingText}>{text}</span>
	</div>
);

const IMAGE_MAX_SIZE_IN_MB = 10;
const AUDIO_MAX_SIZE_IN_MB = 5;

const AudioItem = ({
	data,
	selectedImageId,
	onClick,
	columnIndex,
	rowIndex,
	style,
	columnCount,
	minDimensions,
	playingAudioId,
	handlePlayPause,
	onAudioEndedCb,
}: {
	rowCount: number;
	columnCount: number;
	data: GalleryImgType | undefined;
	onClick(data: GalleryImgType): void;
	selectedImageId: undefined | string;
	playingAudioId: undefined | string;
	handlePlayPause: (
		id: string,
		audioRef: React.MutableRefObject<HTMLAudioElement>
	) => void;
	columnIndex: number;
	rowIndex: number;
	style: CSSProperties;
	minDimensions?: {
		width: number;
		height: number;
	};
	onAudioEndedCb: (audioId: string) => void;
}) => {
	const isUploadInProgress = get(data, 'uploadStatus') === 'IN_PROGRESS';
	const { GALLERY_UPLOAD_PROGRESS_TEXT } = Translations;
	const audioRef = useRef<HTMLAudioElement | null>(null);

	return data ? (
		<div
			style={{
				...style,
				paddingLeft: columnIndex === 0 ? '6px' : 0,
				paddingRight: columnIndex === columnCount - 1 ? '6px' : 0,
				paddingTop: rowIndex === 0 ? '6px' : 0,
			}}
			key={data.id}
		>
			<div
				className={classNames(styles.audiosListItem, {
					[styles.selectedImagesListItem]: selectedImageId === get(data, 'id'),
					[styles.disabled]: isUploadInProgress,
				})}
				onClick={() => {
					if (!isUploadInProgress) {
						onClick(data);
					}
				}}
			>
				{isUploadInProgress ? (
					<ProcessingAudio text={GALLERY_UPLOAD_PROGRESS_TEXT} />
				) : get(data, 'cloudFrontUrl') ? (
					<div className={styles.fileDetails}>
						<span
							className={styles.playIcon}
							onClick={(e) => {
								e.stopPropagation();
								handlePlayPause(get(data, 'id', ''), audioRef);
							}}
						>
							{playingAudioId === get(data, 'id') ? (
								<IconPlayerPauseFilled width="16" height="16" />
							) : (
								<IconPlayerPlayFilled width="16" height="16" />
							)}
						</span>
						<span className={styles.audioSpan}>{get(data, 'name') || '--'}</span>
						<audio
							ref={audioRef}
							src={get(data, 'cloudFrontUrl')}
							id={`image_${get(data, 'id')}`}
							onEnded={() => {
								onAudioEndedCb(get(data, 'id'));
							}}
						/>
					</div>
				) : (
					<div className={styles.fileDetails}>
						<span className={styles.audioSpan}>{get(data, 'name') || '--'}</span>
						{get(data, 'name') !== 'none' && (
							<div className={styles.error}>Audio not available</div>
						)}
					</div>
				)}
			</div>
		</div>
	) : null;
};

const ImageItem = ({
	data,
	selectedImageId,
	onClick,
	columnIndex,
	rowIndex,
	style,
	columnCount,
	minDimensions,
}: {
	rowCount: number;
	columnCount: number;
	data: GalleryImgType | undefined;
	onClick(data: GalleryImgType): void;
	selectedImageId: undefined | string;
	columnIndex: number;
	rowIndex: number;
	style: CSSProperties;
	minDimensions?: {
		width: number;
		height: number;
	};
}) => {
	const { GALLERY_UPLOAD_PROGRESS_TEXT, GALLERY_IMAGE_DIMENSIONS_ERROR_TOOLTIP } =
		Translations;
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [minDimensionsMet, setMinDimensionsMet] = useState(false);
	const onImageLoad = useCallback(
		(event: Event) => {
			if (
				minDimensions &&
				(event.target?.naturalWidth < minDimensions?.width ||
					event.target?.naturalHeight < minDimensions?.height)
			) {
				setMinDimensionsMet(false);
			} else {
				setMinDimensionsMet(true);
			}
			setIsImageLoaded(true);
		},
		[setIsImageLoaded, setMinDimensionsMet, minDimensions]
	);

	const isUploadInProgress = get(data, 'uploadStatus') === 'IN_PROGRESS';
	const isImageReady = isImageLoaded && !isUploadInProgress && minDimensionsMet;

	return data ? (
		<div
			style={{
				...style,
				paddingLeft: columnIndex === 0 ? '6px' : 0,
				paddingRight: columnIndex === columnCount - 1 ? '6px' : 0,
				paddingTop: rowIndex === 0 ? '6px' : 0,
			}}
			key={data.id}
		>
			<div
				className={classNames(styles.imagesListItem, {
					[styles.selectedImagesListItem]: selectedImageId === get(data, 'id'),
					[styles.disabled]: !isImageReady,
				})}
				onClick={() => {
					if (isImageReady) {
						onClick(data);
					}
				}}
			>
				{isUploadInProgress ? (
					<ProcessingImg text={GALLERY_UPLOAD_PROGRESS_TEXT} />
				) : (
					<ImageElement
						onLoad={onImageLoad}
						classes={{
							imageWrapper: classNames({ [styles.disabled]: !isImageReady }),
						}}
						title={
							isImageLoaded && !minDimensionsMet
								? GALLERY_IMAGE_DIMENSIONS_ERROR_TOOLTIP
								: ''
						}
						id={`image_${get(data, 'id')}`}
						src={get(data, 'cloudFrontUrl', '')}
					/>
				)}
			</div>
		</div>
	) : null;
};

const imageGallerySelector = (state: RootState): ImageGalleryReducerType =>
	get(state, 'imageGallery') as ImageGalleryReducerType;

const ImageGallery = () => {
	const {
		GALLERY_NO_IMAGES_FOUND,
		GALLERY_FAILED_TO_LOAD_IMAGES,
		GALLERY_CORRUPT_IMAGE_ERROR_MSG,
		GALLERY_NO_FILE_FOUND,
		GALLERY_MY_UPLOADS_KEY,
		GALLERY_AUDIO_PRE_REQUISITE,
		GALLERY_SLIDE_IMAGE_HEADER,
		GALLERY_SELECT_FROM_ORIGINAL_TEXT,
	} = Translations;
	const {
		config: {
			visible,
			onConfirm,
			onCancel,
			header,
			confirmButtonText,
			cancelButtonText,
			uploadButtonText,
			headerClassName,
			dialogClassName,
			classes,
			variant,
			subHeading,
			recommendedResolution,
			minDimensions,
			showSelectFromOriginal,
			videoDefaultTime,
		},
		imagesList,
		pagination,
		errors,
	} = useSelector(imageGallerySelector);

	const dispatch = useDispatch();
	const fileTypeState = useSelector((state) => get(state, 'imageGallery.fileType'));
	const categories = useSelector((state) => get(state, 'imageGallery.categories'));
	const imgUploadInputRef = useRef<HTMLInputElement>(null);
	const floSpaceId = useSelector(floSpaceSelector);
	const loader =
		useSelector((state) => get(state, 'loaders.imageGallery', 0) > 0) || !floSpaceId;
	const [containerWidth, setContainerWidth] = useState(0);
	const [selectedImage, setSelectedImage] = useState<GalleryImgType | undefined | null>();
	const [isUploadInProgress, setIsUploadInProgress] = useState(false);
	const [uploadImage, setUploadImage] = useState<{
		category: string;
		file: File;
		height?: number;
		width?: number;
		fileType: string;
	} | null>(null);
	const [columnCount, setColumnCount] = useState(0);
	const [screenshotTime, setScreenshotTime] = useState(0);
	const prevIsUploadInProgress = useRef(false);
	const [videoReady, setVideoReady] = useState(false);
	const [fileSelectInputKey, setFileSelectInputKey] = useState(uniqId());
	const [activeSubheading, setActiveSubheading] = useState(GALLERY_MY_UPLOADS_KEY);

	const [playingAudioId, setPlayingAudioId] = useState('');
	const [curAudioRef, setCurAudioRef] =
		useState<React.MutableRefObject<HTMLAudioElement> | null>(null);
	const galleryWrapperRef = useRef<HTMLDivElement>(null);
	const [videoPlayerRef, setVideoPlayerRef] = useState<HTMLVideoElement | undefined>();

	const handleSubheadingClick = useCallback(
		(subheading: string) => {
			if (playingAudioId) {
				setPlayingAudioId('');
				setCurAudioRef(null);
			}
			setActiveSubheading(subheading);
		},
		[playingAudioId, setActiveSubheading, setPlayingAudioId, setCurAudioRef]
	);

	useEffect(() => {
		if (showSelectFromOriginal) {
			setActiveSubheading('selectFromOriginal');
		}
	}, [showSelectFromOriginal]);

	const { isDiamondEnabled: isDiamondBgm, featureObject: featureBgm } =
		isFeatureEnabled('bgm_upload');
	const { isDiamondEnabled: isDiamondImg, featureObject: featureImg } =
		isFeatureEnabled('image_upload');

	const handleUploadClick = useCallback(
		(fileTypeState: string) => {
			if (isDiamondBgm && fileTypeState === 'audio') {
				dispatch(
					showDiamondFeaturePopup({
						featureInfo: featureBgm,
						buttonClicked: 'BGM_UPLOAD_CLICK',
					})
				);
			} else if (isDiamondImg && fileTypeState === 'image') {
				dispatch(
					showDiamondFeaturePopup({
						featureInfo: featureImg,
						buttonClicked: 'IMG_UPLOAD_CLICK',
					})
				);
			} else {
				imgUploadInputRef.current?.click();
			}
		},
		[imgUploadInputRef, isDiamondBgm, isDiamondImg, fileTypeState, featureImg, featureBgm]
	);

	const onImgSelect = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) {
				return;
			}
			const file = event.target.files[0];

			if (fileTypeState == 'image') {
				const fileSizeInMb = (file?.size || 0) / 1000000;
				if (fileSizeInMb > IMAGE_MAX_SIZE_IN_MB) {
					dispatch(
						setErrorToast({
							type: 'error',
							message: Translations.GALLERY_UPLOAD_FILE_SIZE_ERROR,
							canShow: true,
						})
					);
					return;
				}
				let img = new Image();
				img.src = window.URL.createObjectURL(file);
				img.onload = () => {
					setUploadImage({
						category: activeSubheading,
						file,
						width: img.naturalWidth,
						height: img.naturalHeight,
						fileType: fileTypeState,
					});
					dispatch(
						uploadGalleryFileAction({
							category: activeSubheading,
							file,
							width: img.naturalWidth,
							height: img.naturalHeight,
							fileType: fileTypeState,
						})
					);
				};
				img.onerror = () => {
					dispatch(
						setErrorToast({
							type: 'error',
							message: GALLERY_CORRUPT_IMAGE_ERROR_MSG,
							canShow: true,
						})
					);
				};
			} else {
				const file1 = event.target.files[0];
				const fileSizeInMb = (file?.size || 0) / 1000000;
				if (fileSizeInMb > AUDIO_MAX_SIZE_IN_MB) {
					dispatch(
						setErrorToast({
							type: 'error',
							message: Translations.GALLERY_UPLOAD_FILE_SIZE_ERROR_AUDIO,
							canShow: true,
						})
					);
					return;
				}

				if (!file) {
					console.log('Invalid file');
					return; // Exit if no file is selected
				}
				let audio = new Audio();
				audio.src = window.URL.createObjectURL(file);
				audio.oncanplaythrough = () => {
					setUploadImage({
						category: activeSubheading,
						file,
						fileType: fileTypeState,
					});
					dispatch(
						uploadGalleryFileAction({
							category: activeSubheading,
							file,
							fileType: fileTypeState,
						})
					);
				};
				audio.onerror = () => {
					dispatch(
						setErrorToast({
							type: 'error',
							message: GALLERY_CORRUPT_IMAGE_ERROR_MSG,
							canShow: true,
						})
					);
				};
			}
		},
		[setUploadImage, fileTypeState, activeSubheading]
	);

	const containerHeight =
		galleryWrapperRef.current?.offsetHeight -
		(activeSubheading === GALLERY_MY_UPLOADS_KEY ? 52 : 0);
	const handleClose = useCallback(() => {
		dispatch(hideFileGalleryAction(''));
		setActiveSubheading(GALLERY_MY_UPLOADS_KEY);
		onCancel?.();
	}, [onCancel, setActiveSubheading]);

	const handleConfirm = useCallback(
		throttle(
			() => {
				let finalImage = selectedImage;
				if (activeSubheading === 'selectFromOriginal') {
					const { dataUrl } = captureScreenshot(videoPlayerRef);
					finalImage = {
						dataUrl,
						id: uniqId(),
						extension: 'image/png',
						meta: {
							width: videoPlayerRef?.videoWidth || 0,
							height: videoPlayerRef?.videoHeight || 0,
						},
						customResource: true,
						manuallySetFrameTime: screenshotTime,
						category: 'selectFromOriginal',
					};
				}
				if (finalImage) {
					onConfirm?.(finalImage, activeSubheading);
				}
				dispatch(hideFileGalleryAction(''));
				setActiveSubheading(GALLERY_MY_UPLOADS_KEY);
			},
			1000,
			{ leading: true, trailing: false }
		),
		[
			dispatch,
			onConfirm,
			selectedImage,
			activeSubheading,
			setActiveSubheading,
			videoPlayerRef,
			screenshotTime,
		]
	);

	useEffect(() => {
		if (floSpaceId && visible && activeSubheading !== 'selectFromOriginal') {
			if (activeSubheading === GALLERY_MY_UPLOADS_KEY) {
				dispatch(
					getGalleryFilesAction({
						pageNo: 1,
						fileType: fileTypeState,
						category: activeSubheading,
					})
				);
			} else {
				dispatch(
					getGallerySystemFilesAction({
						pageNo: 1,
						fileType: fileTypeState,
						category: activeSubheading,
					})
				);
			}
		}
	}, [floSpaceId, visible, activeSubheading]);

	const retryButtonClick = useCallback(() => {
		if (activeSubheading === GALLERY_MY_UPLOADS_KEY) {
			dispatch(
				getGalleryFilesAction({
					pageNo: 1,
					fileType: fileTypeState,
					category: activeSubheading,
				})
			);
		} else {
			dispatch(
				getGallerySystemFilesAction({
					pageNo: 1,
					fileType: fileTypeState,
					category: activeSubheading,
				})
			);
		}
	}, [activeSubheading, fileTypeState]);

	const onResize = useCallback(() => {
		if (visualViewport.width) {
			const availableWidth = (min([visualViewport.width - 60, 1200]) || 0) - 208;
			const numberOfImagesInRow = Math.floor(
				((availableWidth || 0) - 42 - scrollbarSize() - 12) / (168 + 24)
			);
			const newContainerWidth =
				Math.floor((numberOfImagesInRow - 1) * (168 + 24) + 168) + scrollbarSize() + 24;
			setContainerWidth(newContainerWidth);
			setColumnCount(numberOfImagesInRow);
		}
	}, [visualViewport.width, setContainerWidth, setColumnCount, activeSubheading]);

	useEffect(() => {
		onResize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		onResize();
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	const rowCount = Math.ceil(imagesList[activeSubheading]?.length / columnCount);

	const getRowHeight = useCallback(
		({ index }: { index: number }) => {
			if (fileTypeState === 'image') {
				if (index === 0) {
					return 168 + 24 + 6; // Height for the first image
				} else if (index === rowCount - 1) {
					return 168 + 6; // Height for the last image
				}
				return 168 + 24; // Default height for other images
			} else {
				if (index === 0) {
					return 40 + 16 + 6; // Height for the first audio
				} else if (index === rowCount - 1) {
					return 40 + 6; // Height for the last audio
				}
				return 40 + 16; // Default height for other audios
			}
		},
		[rowCount, fileTypeState] // Add fileTypeState to dependencies if it can change
	);

	const getColumnWidth = useCallback(
		({ index }: { index: number }) => {
			if (index === columnCount - 1) {
				return 168 + 6;
			} else if (index === 0) {
				return 168 + 24 + 6;
			}
			return 168 + 24;
		},
		[columnCount]
	);

	const onItemClick = useCallback(
		(img: GalleryImgType) => {
			const image = document.querySelector(`#image_${img.id}`);
			const meta = { width: 0, height: 0 };
			if (image) {
				meta.width = get(image, 'naturalWidth') || get(img, 'imageWidth');
				meta.height = get(image, 'naturalHeight') || get(img, 'imageHeight');
			}
			setSelectedImage({
				...img,
				meta,
			});
		},
		[setSelectedImage]
	);

	useEffect(() => {
		if (imagesList[activeSubheading]?.length) {
			const isUploadInProgress = some(imagesList[activeSubheading], [
				'uploadStatus',
				'IN_PROGRESS',
			]);
			setIsUploadInProgress(isUploadInProgress);
			if (prevIsUploadInProgress.current && !isUploadInProgress) {
				if (
					!minDimensions ||
					(get(uploadImage, 'width', 0) > minDimensions?.width &&
						get(uploadImage, 'height', 0) > minDimensions?.height)
				) {
					onItemClick(imagesList[activeSubheading][0]);
				}
				setFileSelectInputKey(uniqId());
				setUploadImage(null);
			}
			prevIsUploadInProgress.current = isUploadInProgress;
		}
	}, [
		imagesList,
		onItemClick,
		setIsUploadInProgress,
		setUploadImage,
		prevIsUploadInProgress,
		setSelectedImage,
		setFileSelectInputKey,
		minDimensions,
		uploadImage,
		activeSubheading,
	]);

	const loadNextPage = useCallback(
		(params: { startIndex: number; stopIndex: number }) => {
			const pageSize = fileTypeState === 'audio' ? 50 : 30;
			const pageNo = Math.floor((params.startIndex * columnCount) / pageSize) + 1;
			if (activeSubheading === GALLERY_MY_UPLOADS_KEY) {
				dispatch(
					getGalleryFilesAction({
						pageNo,
						fileType: fileTypeState,
						category: activeSubheading,
					})
				);
			} else {
				dispatch(
					getGallerySystemFilesAction({
						pageNo,
						fileType: fileTypeState,
						category: activeSubheading,
					})
				);
			}
			return new Promise((resolve) => resolve(false));
		},
		[floSpaceId, fileTypeState, activeSubheading, columnCount]
	);

	const onAudioEndedCb = useCallback(
		(audioId: string) => {
			if (playingAudioId === audioId && curAudioRef?.current) {
				curAudioRef.current.currentTime = 0;
				curAudioRef.current.play();
			}
		},
		[playingAudioId, curAudioRef]
	);

	const handlePlayPause = useCallback(
		(audioId: string, audioRef: React.MutableRefObject<HTMLAudioElement>) => {
			if (playingAudioId != audioId) {
				setCurAudioRef(audioRef);
				if (curAudioRef && !curAudioRef.current?.paused) {
					curAudioRef.current?.pause();
				}
			}
			if (audioRef.current.paused) {
				audioRef.current.currentTime = 0;
				audioRef.current.play();
				setPlayingAudioId(audioId);
			} else {
				audioRef.current.pause();
				setPlayingAudioId('');
			}
		},
		[setPlayingAudioId, setCurAudioRef, curAudioRef, playingAudioId]
	);

	useEffect(() => {
		if (!visible) {
			setSelectedImage(null);
			setIsUploadInProgress(false);
		}
	}, [visible, setContainerWidth, setSelectedImage, setIsUploadInProgress]);

	const totalRecords = get(pagination, 'totalRecords', 0);
	const addMoreRows = rowCount * columnCount < totalRecords;
	const isRowLoaded = ({ index }: { index: number }) => index < rowCount;
	const estimatedRowSize =
		(168 + 24) * Math.ceil(imagesList[activeSubheading]?.length / columnCount);
	const disableConfirm =
		!get(selectedImage, 'id') &&
		!(activeSubheading === 'selectFromOriginal' && videoReady);

	if (!visible) return <span />;

	return (
		<Dialog
			open={true}
			scroll={'paper'}
			classes={{
				paper: classNames(styles.container, classes?.container || '', {
					[styles.small]: variant === 'small',
				}),
				root: classNames(styles.dialogRoot, dialogClassName),
			}}
			onClose={handleClose}
			componentsProps={{
				backdrop: {
					// @ts-ignore
					classes: {
						root: styles.positionAbsolute,
					},
				},
			}}
		>
			{header && (
				<DialogTitle
					id="responsive-dialog-title"
					className={classNames(styles.title, headerClassName)}
				>
					{fileTypeState === 'image' && (
						<>
							{minDimensions ? GALLERY_SLIDE_IMAGE_HEADER : header}
							{recommendedResolution && (
								<div className={styles.subTitle}>
									Recommended resolution: {recommendedResolution}
								</div>
							)}
							{minDimensions && (
								<div className={styles.subTitle}>
									Minimum resolution: {minDimensions.width}x{minDimensions.height}
								</div>
							)}
						</>
					)}

					{fileTypeState === 'audio' && (
						<>
							Select audio
							<div className={styles.subTitle}>{GALLERY_AUDIO_PRE_REQUISITE}</div>
						</>
					)}
				</DialogTitle>
			)}
			<input
				type="file"
				key={fileSelectInputKey}
				accept={fileTypeState === 'image' ? '.png,.jpg,.jpeg,.gif' : '.mp3,.aac'}
				onChange={onImgSelect}
				ref={imgUploadInputRef}
				className={styles.imgUploadInput}
			/>

			<div className={styles.galleryWrapper} ref={galleryWrapperRef}>
				<div className={styles.leftPanel}>
					{showSelectFromOriginal && (
						<div
							key={'selectFromOriginal'}
							className={classNames(styles.subHeading, {
								[styles.active]: activeSubheading === 'selectFromOriginal',
							})}
							onClick={() => handleSubheadingClick('selectFromOriginal')}
						>
							<span>{GALLERY_SELECT_FROM_ORIGINAL_TEXT}</span>
						</div>
					)}
					{Object.keys(imagesList).map((categoryName: string) => {
						const categoryLabel =
							categoryName === GALLERY_MY_UPLOADS_KEY
								? 'My uploads'
								: categories.find((cat: Category) => cat.name === categoryName)?.label ||
								  categoryName;

						return (
							<>
								<div
									key={categoryName}
									className={classNames(styles.subHeading, {
										[styles.active]: activeSubheading === categoryName,
									})}
									onClick={() => handleSubheadingClick(categoryName)}
								>
									<span>{categoryLabel}</span>
								</div>
								{categoryName === GALLERY_MY_UPLOADS_KEY && (
									<div className={styles.subLine}></div>
								)}
							</>
						);
					})}
				</div>
				<DialogContent className={styles.dialogContent} style={{ width: containerWidth }}>
					{activeSubheading === 'selectFromOriginal' ? (
						<div className={styles.videoScreenCaptureWrapper}>
							<VideoScreenCapture
								setVideoPlayerRef={setVideoPlayerRef}
								onVideoTimeChange={setScreenshotTime}
								setVideoReady={setVideoReady}
								videoDefaultTime={videoDefaultTime}
							/>
						</div>
					) : (
						<>
							{loader && get(imagesList[activeSubheading], 'length', 0) === 0 ? (
								<div className={styles.loaderWrapper}>
									<Loader nodelay />
								</div>
							) : (
								<>
									{activeSubheading === GALLERY_MY_UPLOADS_KEY && (
										<div className={styles.subHeadingWrapper}>
											<div className={styles.uploadButtonWrapper}>
												<div className={styles.maxFileSize}>
													Max file size:{' '}
													{fileTypeState === 'image'
														? `${IMAGE_MAX_SIZE_IN_MB}MB`
														: `${AUDIO_MAX_SIZE_IN_MB}MB`}
												</div>
												<Button
													autoFocus
													disableFocusRipple
													variant="contained"
													onClick={
														isUploadInProgress
															? noop
															: () => handleUploadClick(fileTypeState)
													}
													className={classNames(
														styles.uploadButton,
														get(classes, 'uploadButton'),
														{
															[styles.disabled]: isUploadInProgress,
														}
													)}
												>
													<IconUpload className={styles.uploadButtonIcon} size={16} />
													<span className={styles.uploadButtonText}>
														{translate(uploadButtonText, uploadButtonText)}
													</span>

													{(fileTypeState === 'audio' && isDiamondBgm) ||
													(fileTypeState === 'image' && isDiamondImg) ? (
														<DiamondIcon
															className={styles.diamondIcon}
															image="https://zeus.floik.com/system/artworks/subscription/diamond_image.svg"
														/>
													) : null}
												</Button>
											</div>
										</div>
									)}

									{get(imagesList[activeSubheading], 'length') > 0 && (
										<div className={styles.imagesListWrapper}>
											<InfiniteLoader
												isRowLoaded={isRowLoaded}
												// @ts-ignore
												loadMoreRows={loadNextPage}
												rowCount={rowCount + (addMoreRows ? 1 : 0)}
											>
												{({ onRowsRendered, registerChild }) => {
													return (
														<Grid
															ref={(grid) => {
																registerChild(grid);
															}}
															width={containerWidth + 12}
															height={containerHeight || 0}
															estimatedColumnSize={containerWidth}
															estimatedRowSize={estimatedRowSize}
															rowCount={rowCount}
															rowHeight={getRowHeight}
															onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
																onRowsRendered({
																	startIndex: rowStartIndex,
																	stopIndex: rowStopIndex,
																});
															}}
															columnWidth={getColumnWidth}
															columnCount={columnCount}
															overscanColumnCount={0}
															overscanRowCount={0}
															cellRenderer={(props: GridCellProps) => {
																const data = get(
																	imagesList[activeSubheading],
																	`[${props.rowIndex * columnCount + props.columnIndex}]`,
																	null
																);
																return fileTypeState === 'image' ? (
																	<ImageItem
																		{...props}
																		minDimensions={minDimensions}
																		rowCount={rowCount}
																		columnCount={columnCount}
																		selectedImageId={get(selectedImage, 'id')}
																		onClick={onItemClick}
																		// addMoreRows={addMoreRows}
																		data={data}
																	/>
																) : (
																	<AudioItem
																		{...props}
																		playingAudioId={playingAudioId}
																		handlePlayPause={handlePlayPause}
																		onAudioEndedCb={onAudioEndedCb}
																		minDimensions={minDimensions}
																		rowCount={rowCount}
																		columnCount={columnCount}
																		selectedImageId={get(selectedImage, 'id')}
																		onClick={onItemClick}
																		// addMoreRows={addMoreRows}
																		data={data}
																	/>
																);
															}}
														></Grid>
													);
												}}
											</InfiniteLoader>
											{loader && <Loader nodelay />}
										</div>
									)}

									{!loader &&
										get(imagesList[activeSubheading], 'length', 0) === 0 &&
										(get(errors, 'getGalleryImages') ? (
											<div className={classNames(styles.emptyMessage, 'flex-column')}>
												{GALLERY_FAILED_TO_LOAD_IMAGES}
												<div className={styles.retryWrapper}>
													<Button
														className={styles.cancelButton}
														onClick={retryButtonClick}
													>
														<IconReload size={18}></IconReload>
														<div className={styles.cancelButtonText}>Retry</div>
													</Button>
												</div>
											</div>
										) : activeSubheading === GALLERY_MY_UPLOADS_KEY ? (
											<div className={styles.emptyMessage}>{GALLERY_NO_IMAGES_FOUND}</div>
										) : (
											<div className={styles.emptyMessage}>{GALLERY_NO_FILE_FOUND}</div>
										))}
								</>
							)}
						</>
					)}
					<canvas
						id="floikScreenCaptureCanvas"
						style={{
							display: 'none',
						}}
					></canvas>
				</DialogContent>
			</div>

			<DialogActions
				classes={{
					root: classNames(styles.actions),
				}}
			>
				<Button
					autoFocus
					disableFocusRipple
					onClick={handleClose}
					className={classNames(styles.cancelButton, get(classes, 'cancelButton'))}
				>
					<span className={styles.cancelButtonText}>
						{translate(cancelButtonText, cancelButtonText)}
					</span>
				</Button>

				<Button
					disableFocusRipple
					variant="contained"
					className={classNames(styles.confirmButton, get(classes, 'confirmButton'), {
						[styles.disabled]: disableConfirm,
					})}
					disabled={disableConfirm}
					onClick={handleConfirm}
					autoFocus
				>
					{translate(confirmButtonText, confirmButtonText)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageGallery;
