import { get, includes, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import videojs from 'video.js';
import { floTrackType } from '../Pages/Flo/Flo.types';

export function useOffClick(
	cb: Function,
	refs: React.MutableRefObject<HTMLElement>[] | undefined,
	capture?: boolean
) {
	const windowClickListener = useCallback(
		(event: Event) => {
			const allowedEl = refs?.some((ref) => {
				const el = ref?.current as HTMLDivElement;
				const target = event.target as Node;
				return !el || el.contains(target) || el === target;
			});
			if (!allowedEl) {
				cb?.(event);
			}
		},
		[cb, refs]
	);

	useEffect(() => {
		window.addEventListener('click', windowClickListener, capture);
		return () => {
			window.removeEventListener('click', windowClickListener, capture);
		};
	}, [windowClickListener, capture]);
}

export default useOffClick;

export function useCurrentFloTracks() {
	const tracks = useSelector((state) => get(state, 'floPage.tracks'));

	const [videoTrack, setVideoTrack] = useState({});
	const [audioTrack, setAudioTrack] = useState({});
	const [screenShareTrack, setScreenShareTrack] = useState({});
	const [hasAudioTrack, setHasAudioTrack] = useState(false);
	const [trackError, setTrackError] = useState('');

	useEffect(() => {
		const videoOptions: any = [];
		const screenShareOptions: any = [];
		const audioOptions: any = [];
		let subtitleTrack: any;
		let hasHls = false;
		let error = false;
		let hasAudioFile = false;
		map(tracks, (item: floTrackType) => {
			const { type, subtitleUrl, url, hlsURL, hasThumbnails, thumbnailsPath, hasAudio } =
				item;
			const enableHlsURL = process.env.REACT_APP_DISABLE_HSL !== 'true' && hlsURL;
			hasHls = hasHls || !!enableHlsURL;
			const enableHlsCredentials =
				process.env.REACT_APP_ENABLE_HLS_CREDENTIALS === 'true';
			const src = enableHlsURL ? hlsURL : url;

			const track = {
				src,
				withCredentials: enableHlsURL && enableHlsCredentials,
				hasThumbnails,
				isHls: enableHlsURL,
				thumbnailsPath,
				id: item.id,
				// @ts-ignore
				height: item.height,
				// @ts-ignore
				width: item.width,
			};
			const isMp4 = includes(src, '.mp4');
			const isMov = includes(src, '.mov');
			if (isMp4 || isMov) {
				track.type = 'video/mp4';
				track.isHls = false;
			}

			hasAudioFile = hasAudio || hasAudioFile;
			if (type === 'video') {
				if (src) videoOptions.push(track);
				else error = true;
			} else if (type === 'screenShare') {
				if (src) screenShareOptions.push(track);
				else error = true;
			} else if (src && type === 'audio') {
				track.src = url;
				// @ts-ignore
				track.type = 'audio/webm';
				audioOptions.push(track);
			}
		});

		const videoTracksOptions: videojs.PlayerOptions = {};
		const screenShareTracksOptions: videojs.PlayerOptions = {};
		if (screenShareOptions.length === 0) {
			setVideoTrack({
				sources: videoOptions,
				...videoTracksOptions,
			});
		} else {
			setVideoTrack({
				sources: videoOptions,
			});
		}
		setScreenShareTrack({
			sources: screenShareOptions,
			...screenShareTracksOptions,
		});
		setAudioTrack({ sources: audioOptions });
		setTrackError(
			error ? 'There was an error loading the video. Please refresh and try again.' : ''
		);
		setHasAudioTrack(hasAudioFile);
	}, [tracks]);
	return {
		videoTrack,
		screenShareTrack,
		audioTrack,
		hasAudioTrack,
		trackError,
	};
}
