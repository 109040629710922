import { Box, Slider } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formattedTime, formattedTimeToSeconds } from '../../Common/Common.utils';
import { enableSaveInteractiveCueEditor } from './../../Pages/Flo/Flo.actions';
import styles from './TimeSlider.module.css';

const TimeSlider = ({
	time,
	onChange,
	valueLabelFormat,
	currentTime,
	duration,
	enableMilliseconds,
	classes,
	setValueWithCurrentTime,
}: {
	time: string;
	enableMilliseconds?: boolean;
	onChange(value: number): void;
	valueLabelFormat(value: number): string;
	currentTime: number;
	duration: number;
	classes?: { [key: string]: string };
	setValueWithCurrentTime?: boolean;
}) => {
	const [value, setValue] = useState(formattedTimeToSeconds(time));
	const dispatch = useDispatch();
	const onChangeCallback = useCallback(
		(event: Event, _value: number | number[], activeThumb: number) => {
			onChange(_value as number);
			dispatch(enableSaveInteractiveCueEditor(true));
		},
		[setValue, onChange, value]
	);

	useEffect(() => {
		setValue(formattedTimeToSeconds(time));
		console.log('formattedTimeToSeconds >>', time, formattedTimeToSeconds(time));
	}, [setValue, time]);

	useEffect(() => {
		if (currentTime !== value) {
			if (setValueWithCurrentTime) {
				setValue(currentTime);
			} else {
				onChange(value);
			}
		}
	}, [currentTime, value]);

	return (
		<Box className={classNames(styles.wrapper, classes?.wrapper || '')}>
			<div className={styles.container}>
				<span className={classNames(styles.timeValue, classes?.timeValue || '')}>
					{formattedTime(currentTime, 'seconds')} / {formattedTime(duration, 'seconds')}
					{/*{currentTime} / {duration}*/}
				</span>
				<Slider
					classes={{
						colorPrimary: styles.colorPrimary,
						thumbColorPrimary: styles.thumbColorPrimary,
					}}
					componentsProps={{
						rail: {
							className: styles.rail,
						},
					}}
					// defaultValue={time}
					value={value}
					step={enableMilliseconds ? 1 / 100 : 1}
					track="normal"
					min={0}
					name={'slider1'}
					max={duration}
					// getAriaValueText={() => value}
					onChange={onChangeCallback}
				/>
			</div>
			<div
				className={classNames(styles.currentTime, {
					hide: currentTime < 0 || currentTime > duration,
				})}
				style={{ left: `${currentTime}%` }}
			></div>
		</Box>
	);
};

export default TimeSlider;
