import { createSlice } from '@reduxjs/toolkit';
import { get, includes, uniqueId } from 'lodash';
import { FloCreationTemplates } from '../../Common/Common.types';
import { AnnotationSnapshot } from '../../Components/Annotations/Annotations.types';
import { floTrackType } from '../Flo/Flo.types';
import {
	CropStateType,
	NewFlosReducerType,
	optionKeys,
	RecorderOptions,
	TemplateCategoryType,
	TrimStateType,
} from './NewFloContainer.types';

const initialState = Object.freeze({
	floStreams: [],
	templates: [],
	templateCategories: [],
	templateFilter: '',
	cacheTemplates: {},
	hasFailedAttempt: false,
	hasFailedMessage: '',
	isOnline: true,
	showPopup: true,
	elementId: null,
	noOfClicks: 0,
	floId: '',
	trackIds: {},
	videoFileID: uniqueId(),
	videoFileDuration: -1,
	videoFileMeta: {},
	viedoFileName: '',
	viedoFileSize: '',
	type: '',
	state: 'stopped', // stopped, creating, recording, saving,
	uploadSpeed: -1,
	uploadSpeedData: {},
	loading: 0,
	countDownTrack: '',
	canShowNewFlo: false,
	tracks: [],
	canDelete: true,
	duration: 0,
	chunkStats: {
		screenShareTotalSize: 0,
		screenShareUploadedSize: 0,
		videoTotalSize: 0,
		videoUploadedSize: 0,
	},
	captureSize: {
		video: {
			height: 0,
			width: 0,
		},
		screenShare: {
			height: 0,
			width: 0,
		},
	},
	postProcessConfigs: {
		crop: {
			enable: false,
			values: null,
			videoSize: {
				width: 0,
				height: 0,
			},
		},
		trim: {
			enable: false,
			values: [0, 100],
			rangeValues: [0, 100],
		},
	},
});

const newFloReducers = {
	showEditPopup(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				name: string;
			};
		}
	) {
		state.name = payload.name;
		state.state = 'stopped/save';
		state.showPopup = true;
		state.canShowNewFlo = true;
		state.canDelete = false;
		state.floId = payload.id;
	},
	setTrimData(state: NewFlosReducerType, { payload }: { payload: TrimStateType }) {
		state.postProcessConfigs.trim = payload;
	},
	getTemplates(state: NewFlosReducerType, { payload }: { payload: unknown }) {},
	getTemplateCategories(state: NewFlosReducerType, { payload }: { payload: unknown }) {},
	setTemplateCategories(
		state: NewFlosReducerType,
		{ payload }: { payload: TemplateCategoryType[] }
	) {
		state.templateCategories = payload;
	},
	setTemplates(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				data: FloCreationTemplates[];
				filter: string;
			};
		}
	) {
		state.templateFilter = payload.filter || 'user';
		state.templates = payload.data;
		state.cacheTemplates[payload.filter || 'user'] = payload.data;
	},
	setSelectedTemplate(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				template: FloCreationTemplates;
				showDetails?: boolean;
			};
		}
	) {
		state.template = payload.template;
		state.showTemplateDeviceSelection = !!payload.showDetails;
	},
	setCropData(state: NewFlosReducerType, { payload }: { payload: CropStateType }) {
		state.postProcessConfigs.crop = payload;
	},
	setCanShowNewFlo(state: NewFlosReducerType, { payload }: { payload: boolean }) {
		if (!payload) {
			state.template = undefined;
		}
		state.canDelete = true;
		state.canShowNewFlo = payload;
		state.videoFileUploadError = '';
	},
	getCountDownTrack(state: NewFlosReducerType, { payload }: { payload: unknown }) {},
	setCountDownTrack(state: NewFlosReducerType, { payload }: { payload: string }) {
		state.countDownTrack = payload;
	},
	setStopSoundTrack(state: NewFlosReducerType, { payload }: { payload: string }) {
		state.stopSoundTrack = payload;
	},
	resetState(
		state: NewFlosReducerType,
		{ payload }: { payload: { visible: boolean } | '' }
	) {
		if (get(payload, 'visible')) {
			return {
				...initialState,
				templateCategories: state.templateCategories,
				templateFilter: state.templateFilter,
				cacheTemplates: state.cacheTemplates,
				countDownTrack: state.countDownTrack,
				extensionRecordingInProgress: state.extensionRecordingInProgress,
				templates: state.templates,
				floId: state.floId,
				trackIds: state.trackIds,
				canShowNewFlo: true,
			};
		}
		return {
			...initialState,
			templates: state.templates,
			templateCategories: state.templateCategories,
			templateFilter: state.templateFilter,
			cacheTemplates: state.cacheTemplates,
			extensionRecordingInProgress: state.extensionRecordingInProgress,
			countDownTrack: state.countDownTrack,
		};
	},
	setUploadError(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {
		state.videoFileUploadError = payload;
		state.videoFileID = uniqueId();
	},
	createNewFlo(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				type: RecorderOptions;
				name?: string;
				settings: { [key: string]: MediaTrackSettings };
			};
		}
	) {
		state.customFlo = get(payload, 'customFlo');
		state.videoFileDuration = get(payload, 'duration');
		state.videoFileMeta = get(payload, 'settings.screenShare');
		state.viedoFileName = get(payload, 'fileName');
		state.viedoFileSize = get(payload, 'fileSize');
		state.videoFileUploadError = '';

		if (state.customFlo) return;
		state.state = 'creating';
		state.name = payload.name;
		state.type = payload.type;
		state.chunkStats = {
			screenShareTotalSize: 0,
			screenShareUploadedSize: 0,
			videoTotalSize: 0,
			videoUploadedSize: 0,
		};
	},
	uploadFileForCustomFlo(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {};
		}
	) {
		state.floId = '';
		state.videoFileUploadError = '';
	},
	setNewFloId(state: NewFlosReducerType, { payload }: { payload: string }) {
		state.floId = payload;
	},
	setFloState(
		state: NewFlosReducerType,
		{ payload }: { payload: 'stopped' | 'creating' | 'recording' | 'saving' }
	) {
		if (state.customFlo) return;
		state.state = payload;
	},
	createNewTrack(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				type: 'screenShare' | 'camera';
				floId: string;
				floSpaceId: string;
				height: number;
				width: number;
				original_work?: boolean;
				has_audio?: boolean;
				has_video?: boolean;
			};
		}
	) {
		if (payload.type === 'camera')
			state.captureSize.video = {
				width: payload.width,
				height: payload.height,
			};

		if (payload.type === 'screenShare')
			state.captureSize.screenShare = {
				width: payload.width,
				height: payload.height,
			};
	},
	setNewTrackId(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				[key: string]: string;
			};
		}
	) {
		if (payload.camera) {
			state.trackIds.camera = payload.camera;
			state.trackIds.multiCamera = payload['multi_camera'];
		} else {
			state.trackIds.screenShare = payload.screenShare;
			state.trackIds.multiScreenShare = payload['multi_screenShare'];
		}
		let available = false;
		if (
			[
				optionKeys.screenOnly,
				optionKeys.screen,
				optionKeys.screenCam,
				optionKeys.screenCamMic,
				// @ts-ignore
			].includes(get(state, 'type', ''))
		) {
			available = !!state.trackIds.screenShare;
		}
		if (
			[
				optionKeys.cameraOnly,
				optionKeys.camera,
				optionKeys.screenCam,
				optionKeys.screenCamMic,
				// @ts-ignore
			].includes(get(state, 'type', ''))
		) {
			available =
				!!state.trackIds.camera &&
				(includes([optionKeys.screenCam, optionKeys.screenCamMic], get(state, 'type', ''))
					? available
					: true);
		}
		if (state.customFlo) return;
		if (!payload.floEnded) {
			state.state = available ? 'recording' : 'creating';
		} else {
			state.state = 'stopped';
		}
	},
	setElementId(state: NewFlosReducerType, { payload }: { payload: string }) {
		state.elementId = payload;
	},
	setNewFloStreams(
		state: NewFlosReducerType,
		{ payload = [] }: { payload: MediaStream[] }
	) {
		state.floStreams = payload;
	},
	stopNewFloStreams(state: NewFlosReducerType) {
		state.floStreams.forEach(
			(stream) => stream && stream.getTracks().forEach((t) => t.stop())
		);
		state.floStreams = [];
		// SendMessageToExtension(resetStateExtension(''));
	},
	mergeQueueAction(state: NewFlosReducerType) {},
	sendChunk(state: NewFlosReducerType) {},
	toggleNewFloPopup(state: NewFlosReducerType) {
		state.showPopup = !state.showPopup;
		state.videoFileUploadError = '';
	},
	leaveNewFlosPage() {
		return initialState;
	},
	saveFlo(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				type: string;
				trimStartAt: number;
				trimRange: number;
				doTrim: boolean;
			};
		}
	) {
		state.videoFileID = uniqueId();
		if (state.customFlo) return;
		state.state = payload.type === 'discard' ? 'onDiscardSaving' : 'saving';
	},
	sendAnnotationComment(
		state: NewFlosReducerType,
		{ payload }: { payload: AnnotationSnapshot }
	) {},
	onScreenShareEndedAction(state: NewFlosReducerType) {},
	getNewFloTracks(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				floSpaceId?: string;
			};
		}
	) {},
	setNewFloTracks(state: NewFlosReducerType, { payload }: { payload: floTrackType[] }) {
		state.tracks = payload;
		// state.duration = Number(max(payload.map((i) => i.duration || 0)) || 0) / 1000;
	},
	setNewFloDuration(state: NewFlosReducerType, { payload }: { payload: number }) {
		state.duration = payload;
	},
	sendMouseEvent(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				time: number;
				uiId: string;
				type: string;
				uiRenderConfig: {
					closestElementBounds: {
						x: number;
						y: number;
						height: number;
						width: number;
					};
					x: number;
					y: number;
					screenX: number;
					clientX: number;
					screenY: number;
					clientY: number;
					windowSize: {
						height: number;
						width: number;
					};
				};
			};
		}
	) {},
	sendMouseEventScreenshot(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				time: number;
				uiId: string;
				type: string;
				file: string;
			};
		}
	) {},
	increaseMouseEventCount(state: NewFlosReducerType) {
		state.noOfClicks += 1;
	},
	setHasFailedAttempt(
		state: NewFlosReducerType,
		{ payload }: { payload: { value: boolean; message: string } }
	) {
		state.hasFailedAttempt = payload.value;
		state.hasFailedMessage = payload.message || '';
	},
	setIsOnline(state: NewFlosReducerType, { payload }: { payload: boolean }) {
		state.isOnline = payload;
	},
	setUploadSpeed(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				uploadSpeed: number;
				data: {
					[key: string]: [number, number];
				};
			};
		}
	) {
		state.uploadSpeed = payload.uploadSpeed;
		state.uploadSpeedData = {
			...state.uploadSpeedData,
			...payload.data,
		};
	},
	setChunkStats(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: {
				screenShareTotalSize: number;
				screenShareUploadedSize: number;
				videoTotalSize: number;
				videoUploadedSize: number;
			};
		}
	) {
		state.chunkStats = payload;
	},
	setExtensionRecording(
		state: NewFlosReducerType,
		{
			payload,
		}: {
			payload: boolean;
		}
	) {
		state.extensionRecordingInProgress = payload;
	},
};

export const newFloPopup = createSlice({
	name: 'newFlo',
	initialState,
	// @ts-ignore
	reducers: newFloReducers,
});

export default newFloPopup.reducer;

export const setNewFloStreamsAction = newFloPopup.actions.setNewFloStreams;
export const stopNewFloStreamsAction = newFloPopup.actions.stopNewFloStreams;
export const setFloStateAction = newFloPopup.actions.setFloState;
export const leaveNewFlosPageAction = newFloPopup.actions.leaveNewFlosPage;

export const createNewFloAction = newFloPopup.actions.createNewFlo;
export const getCountDownTrackAction = newFloPopup.actions.getCountDownTrack;
export const setCountDownTrack = newFloPopup.actions.setCountDownTrack;
export const setStopSoundTrack = newFloPopup.actions.setStopSoundTrack;
export const resetStateAction = newFloPopup.actions.resetState;
export const setNewFloIdAction = newFloPopup.actions.setNewFloId;
export const createNewTrackAction = newFloPopup.actions.createNewTrack;
export const setElementId = newFloPopup.actions.setElementId;
export const setNewTrackIdAction = newFloPopup.actions.setNewTrackId;
export const mergeQueueAction = newFloPopup.actions.mergeQueueAction;
export const sendChunkAction = newFloPopup.actions.sendChunk;
export const saveFloAction = newFloPopup.actions.saveFlo;
export const setCanShowNewFlo = newFloPopup.actions.setCanShowNewFlo;
export const sendAnnotationComment = newFloPopup.actions.sendAnnotationComment;

export const getNewFloTracks = newFloPopup.actions.getNewFloTracks;
export const onScreenShareEndedAction = newFloPopup.actions.onScreenShareEndedAction;
export const setNewFloTracks = newFloPopup.actions.setNewFloTracks;
export const setTrimDataAction = newFloPopup.actions.setTrimData;
export const setCropDataAction = newFloPopup.actions.setCropData;
export const showEditPopupAction = newFloPopup.actions.showEditPopup;
export const sendMouseEvent = newFloPopup.actions.sendMouseEvent;
export const sendMouseEventScreenshot = newFloPopup.actions.sendMouseEventScreenshot;
export const newFloGetTemplates = newFloPopup.actions.getTemplates;
export const uploadFileForCustomFlo = newFloPopup.actions.uploadFileForCustomFlo;
export const getTemplateCategories = newFloPopup.actions.getTemplateCategories;
export const setTemplateCategories = newFloPopup.actions.setTemplateCategories;
export const newFloSetTemplates = newFloPopup.actions.setTemplates;
export const setSelectedTemplate = newFloPopup.actions.setSelectedTemplate;
export const increaseMouseEventCount = newFloPopup.actions.increaseMouseEventCount;
export const setNewFloDuration = newFloPopup.actions.setNewFloDuration;
export const setChunkStats = newFloPopup.actions.setChunkStats;
export const setHasFailedAttempt = newFloPopup.actions.setHasFailedAttempt;
export const setIsOnline = newFloPopup.actions.setIsOnline;
export const setUploadSpeed = newFloPopup.actions.setUploadSpeed;
export const setExtensionRecording = newFloPopup.actions.setExtensionRecording;
export const setUploadError = newFloPopup.actions.setUploadError;
