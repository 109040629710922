import { Tooltip } from '@mui/material';
import {
	IconPlayerPauseFilled,
	IconPlayerPlayFilled,
	IconVolume,
	IconVolume3,
	IconVolumeOff,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { filter, find, get, includes, isFunction, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formattedTimeToSeconds } from '../../../Common/Common.utils';
import Translations from '../../../Common/Translate.utils';
import {
	NO_VOICE_KEY,
	SYNTHESISED_AI_VOICE_KEY,
} from '../../../Pages/Flo/Components/VideoEditorElements/VoiceOverControlPanel/VoiceOverControlPanel.constants';
import {
	FLO_ACTIVE_TAB_EDIT,
	FLO_ELEMENT_CROP_TYPE,
	FLO_ELEMENT_INTERACTION_CUE_TYPE,
	FLO_ELEMENT_NEW_TRIM_TYPE,
	FLO_ELEMENT_VOICE_OVER_TYPE,
} from '../../../Pages/Flo/Flo.constants';
import { getEditorElementConfigSelector } from '../../../Pages/Flo/Flo.selectors';
import { elementBeanListType } from '../../../Pages/Flo/Flo.types';
import { RootState } from '../../../store';
import { BrandingDialogState } from '../../BrandingDialog/BrandingDialog.types';
import {
	CustomControlButtonsType,
	customControlsType,
	customProgressBarType,
} from '../VideoPlayer.type';
import styles from './CustomControls.module.css';

const DISABLE_PROGRESS_FOR_TYPE = [
	FLO_ELEMENT_INTERACTION_CUE_TYPE,
	FLO_ELEMENT_CROP_TYPE,
];

const CustomControls = ({
	children,
	style,
	className,
	hideProgressBar,
	onPlay,
	onPause,
	nodeRef,
	progressRef,
	onVolumeChange,
	durationRef,
	customControlsChildren,
	onClickProgressBar,
	volume,
	hasAudioTrack,
}: customControlsType) => {
	const CustomControlButtonsProps = {
		onPlay,
		onPause,
		nodeRef,
		onVolumeChange,
		durationRef,
		customControlsChildren,
		hasAudioTrack,
		hideProgressBar,
	};
	const customProgressBarProps = {
		progressRef,
		onClickProgressBar,
		hideProgressBar,
	};

	const trimDurations = useSelector((state) => get(state, 'floPage.trimSections'));
	const tempTrimArea = useSelector((state) =>
		get(state, 'floPage.activeEditElement.tempValues')
	);
	const [inTrimSection, setInTrimSection] = useState(false);
	const [disableProgressbar, setDisableProgressbar] = useState(false);

	const activeEditElement = useSelector((state) =>
		get(state, 'floPage.activeEditElement')
	);
	const showEditor = get(activeEditElement, 'show');
	const trimEnable =
		showEditor &&
		activeEditElement.type === FLO_ELEMENT_NEW_TRIM_TYPE &&
		activeEditElement.editingId;

	const trimConfigs = useSelector((state) =>
		get(state, 'floPage.postProcessConfigs.trim')
	);
	const showingEditCta = useSelector((state) =>
		get(state, 'floPage.activeEditElement.show')
	);
	const editElementType = useSelector((state) =>
		get(state, 'floPage.activeEditElement.type')
	);
	const editorsElementsConfig = useSelector((state) =>
		get(state, 'floPage.editorsElementsConfig', [])
	);
	const rangeSelectorEnabled = useSelector(
		(state) =>
			get(state, 'floPage.activeEditElement.editingId') &&
			get(state, 'floPage.activeEditElement.rangeSlider')
	);
	useEffect(() => {
		setDisableProgressbar(
			showingEditCta && includes(DISABLE_PROGRESS_FOR_TYPE, editElementType)
		);
	}, [showingEditCta, editElementType, setDisableProgressbar]);

	const onTogglePlay = useCallback(() => {
		if (nodeRef?.current) {
			// @ts-ignore
			const isPaused = !!nodeRef?.current?.paused;
			if (isPaused) {
				if (isFunction(onPlay)) onPlay();
			} else {
				if (isFunction(onPause)) onPause();
			}
		}
	}, [nodeRef?.current, onPlay, onPause]);

	useEffect(() => {
		const currentTime = trimConfigs.currentTimeSeconds;
		const enabled =
			trimEnable &&
			tempTrimArea &&
			currentTime >= formattedTimeToSeconds(tempTrimArea[0]) &&
			currentTime <= formattedTimeToSeconds(tempTrimArea[1]);

		const section = find(
			trimDurations,
			([startTime, endTime]) => currentTime >= startTime && currentTime <= endTime
		);
		setInTrimSection(enabled || !!section);
	}, [tempTrimArea, setInTrimSection, trimConfigs.currentTimeSeconds, trimDurations]);

	return (
		<div className={classNames(styles.customControls, className)} style={style}>
			{children}
			<div className={styles.overlay} onClick={onTogglePlay}></div>
			{inTrimSection && <div className={styles.overlayTrimmed}></div>}
			<div
				className={classNames(styles.customControlsContainer, {
					[styles.disable]: disableProgressbar,
					[styles.trimEnable]: trimEnable || rangeSelectorEnabled,
				})}
			>
				<CustomProgressBar {...customProgressBarProps} />
				<CustomControlButtons volume={volume} {...CustomControlButtonsProps} />
			</div>
		</div>
	);
};

const convertToTimeasSeconds = (value: string) => {
	const timeArray = (value || '').split(':');
	const formattedTime = `${timeArray[0] || 0}:${timeArray[1] || 0}:${timeArray[2] || 0}.${
		timeArray[3] || 0
	}`;
	return moment.duration(formattedTime).asSeconds();
};

const TrimmedArea = ({
	duration,
	startTime,
	endTime,
}: {
	duration: number;
	startTime: string;
	endTime: string;
}) => {
	startTime;
	const st = convertToTimeasSeconds(startTime);
	const et = convertToTimeasSeconds(endTime);
	const diff = et - st;
	const percentageWidth = 100 * (diff / duration);
	const percentageLeft = 100 * (st / duration);

	return (
		<div
			style={{
				left: `${percentageLeft}%`,
				width: `${percentageWidth}%`,
			}}
			className={styles.trimmedArea}
		></div>
	);
};

const _CustomProgressBar = ({
	progressRef,
	onClickProgressBar,
	hideProgressBar,
}: customProgressBarType) => {
	const trimConfigs = useSelector((state) =>
		get(state, 'floPage.postProcessConfigs.trim')
	);
	const trimEnabled = get(trimConfigs, 'enable', false);
	const originalLength = useSelector(
		(state) => get(state, 'floPage.duration') || get(trimConfigs, 'originalLength', 0)
	);
	const trimSections = useSelector((state) => {
		const configs = get(state, 'floPage.editorsElementsConfig');
		return filter(configs, ['editorElementName', FLO_ELEMENT_NEW_TRIM_TYPE]);
	});
	const { data: branding, status } = useSelector<unknown, BrandingDialogState>((state) =>
		get(state, 'brandingDialog')
	);
	return (
		<div
			className={classNames(styles.progressContainer, {
				[styles.hide]: trimEnabled,
				[styles.disabled]: hideProgressBar,
			})}
			id="floikProgressBar"
			onClick={(e) => !hideProgressBar && onClickProgressBar && onClickProgressBar(e)}
		>
			<div
				// @ts-ignore
				ref={progressRef}
				className={styles.progress}
			/>
			{map(trimSections, (section) => (
				<TrimmedArea
					duration={originalLength}
					startTime={get(section, 'properties.trimStartTime')}
					endTime={get(section, 'properties.trimEndTime')}
				></TrimmedArea>
			))}
		</div>
	);
};

const CustomProgressBar = React.memo(_CustomProgressBar);

const CustomControlButtons = ({
	onPlay,
	onPause,
	nodeRef,
	onVolumeChange,
	durationRef,
	customControlsChildren,
	volume,
	hasAudioTrack,
	hideProgressBar,
}: CustomControlButtonsType) => {
	const {
		FLO_PAGE_VIDEO_DURATION,
		FLO_PAGE_VIDEO_PLAY,
		FLO_PAGE_VIDEO_PAUSE,
		FLO_PAGE_VIDEO_VOLUME,
		FLO_PAGE_VIDEO_NO_AUDIO,
	} = Translations;

	// @ts-ignore
	const playing = !nodeRef?.current?.paused;
	const [isMuted, setMuted] = useState(!get(nodeRef, 'current.volume', 1));
	const isAiVoice = useSelector((state: RootState): boolean => {
		const elementsConfig: elementBeanListType[] = getEditorElementConfigSelector(state);
		const selectedElementsConfig = find(
			elementsConfig,
			(e) => get(e, 'editorElementName') === FLO_ELEMENT_VOICE_OVER_TYPE
		);
		const voiceOverProperties = selectedElementsConfig?.properties;
		return voiceOverProperties?.voiceOverMode === SYNTHESISED_AI_VOICE_KEY;
	});
	const isNoVoice = useSelector((state: RootState): boolean => {
		const elementsConfig: elementBeanListType[] = getEditorElementConfigSelector(state);
		const selectedElementsConfig = find(
			elementsConfig,
			(e) => get(e, 'editorElementName') === FLO_ELEMENT_VOICE_OVER_TYPE
		);
		const voiceOverProperties = selectedElementsConfig?.properties;
		return voiceOverProperties?.voiceOverMode === NO_VOICE_KEY;
	});
	const isEditing = useSelector((state: RootState): boolean => {
		return (
			(get(state, 'floPage.tabActive') || FLO_ACTIVE_TAB_EDIT) === FLO_ACTIVE_TAB_EDIT
		);
	});
	const hasPlaylist = useSelector(
		(state) =>
			get(
				state,
				`floPage.playlist.item['${get(state, 'floPage.floDetails.id')}'].length`,
				0
			) > 0
	);
	useEffect(() => {
		setMuted(volume === 0);
	}, [volume, setMuted]);

	const onTogglePlay = useCallback(() => {
		if (nodeRef?.current) {
			// @ts-ignore
			const isPaused = !!nodeRef?.current?.paused;
			if (isPaused) {
				if (isFunction(onPlay)) onPlay();
			} else {
				if (isFunction(onPause)) onPause();
			}
		}
	}, [nodeRef?.current, onPlay, onPause]);
	const noAudio = isNoVoice || (!isEditing || !isAiVoice ? !hasAudioTrack : !hasPlaylist);

	return (
		<div className={classNames(styles.buttonControllerContainer)}>
			<div className={styles.leftCustomContols}>
				<Tooltip
					arrow
					// title={playing ? FLO_PAGE_VIDEO_PAUSE : FLO_PAGE_VIDEO_PLAY}
					title={playing ? FLO_PAGE_VIDEO_PAUSE : FLO_PAGE_VIDEO_PLAY}
					placement="top"
				>
					<div className={classNames(styles['play-status'])} onClick={onTogglePlay}>
						{playing ? (
							<IconPlayerPauseFilled size={20} />
						) : (
							<IconPlayerPlayFilled size={20} />
						)}
					</div>
				</Tooltip>
				<Tooltip
					arrow
					title={noAudio ? FLO_PAGE_VIDEO_NO_AUDIO : FLO_PAGE_VIDEO_VOLUME}
					placement="top"
				>
					<div
						className={classNames(styles.sound, {
							// [styles.muted]: isMuted,
							[styles.disabled]: noAudio,
						})}
						onClick={() => {
							if (!noAudio && isFunction(onVolumeChange)) {
								onVolumeChange({
									isMuted: !isMuted,
									volume: !isMuted ? 0 : 1,
								});
								setMuted(!isMuted);
							}
						}}
					>
						{!noAudio && (isMuted ? <IconVolume3 size={20} /> : <IconVolume size={20} />)}
						{noAudio && <IconVolumeOff size={20} />}
					</div>
				</Tooltip>
				<Tooltip arrow title={FLO_PAGE_VIDEO_DURATION} placement="top">
					<div
						// @ts-ignore
						ref={durationRef}
						className={styles.duration}
					/>
				</Tooltip>
			</div>
			{customControlsChildren}
		</div>
	);
};

export default CustomControls;
