import { IconButton, Popover, Slider } from '@mui/material';
import {
	IconAdjustmentsHorizontal,
	IconChevronLeft,
	IconChevronRight,
	IconChevronsLeft,
	IconChevronsRight,
	IconClock,
	IconPlayerPauseFilled,
	IconPlayerPlayFilled,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { debounce, filter, get, isFinite, map, max, min } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentFloTracks } from '../../Common/Common.hooks';
import { timeFormatter, timeFormatterSlider } from '../../Common/Common.utils';
import Translations from '../../Common/Translate.utils';
import { RootState } from '../../store';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import TimeSlider from '../TimeSlider/TimeSlider';
import Video from '../VideoPlayer/Video';
import styles from './VideoScreenCapture.module.css';

const MILLISECONDS_INPUT_MAX = 999;
const MILLISECONDS_INPUT_MIN = 0;

const VideoScreenCapture = ({
	setVideoPlayerRef,
	onVideoTimeChange,
	setVideoReady,
	videoDefaultTime,
}: {
	setVideoPlayerRef(ref: HTMLVideoElement | null): void;
	onVideoTimeChange(time: number): void;
	setVideoReady?(val: boolean): void;
	videoDefaultTime?: number;
}) => {
	const dispatch = useDispatch();
	const screenShareNode = React.useRef<HTMLVideoElement>(null);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const [selectedClickTime, setSelectedClickTime] = useState(0);
	const [hasError, setError] = useState(false);
	const [iconButton, setIconButton] = useState<HTMLSpanElement>(null);
	const [milliseconds, setMilliseconds] = useState(0);
	const initialTimeArray = useRef<number>();

	const [playerDim, setPlayerDim] = useState({
		width: 0,
		height: 0,
		offsetWidth: 0,
		offsetHeight: 0,
	});
	const [videoSize, setVideoSize] = useState<
		{ height: number; width: number } | null | undefined
	>();
	const [clickOptions, setClickOptions] = useState([]);
	const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0);
	const [videoReady, setReady] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLSpanElement>(null);

	const defaultDuration = useSelector((state: RootState) =>
		get(state, 'floPage.duration')
	);
	const guideElements = useSelector((state: RootState) =>
		get(state, 'floPage.guide.elements')
	);
	const [timeSliderValue, setTimeSliderValue] = useState<string | undefined>('');

	const actualDuration = get(screenShareNode, 'current.duration', 0);
	const duration = isFinite(actualDuration) ? actualDuration : defaultDuration;

	const { videoTrack, screenShareTrack } = useCurrentFloTracks();

	useEffect(() => {
		const clicks = filter(guideElements, ['editorElementName', 'clicks']);
		setClickOptions(
			map(clicks, (click, index) => ({
				label: `Click ${index + 1}: ${get(click, 'properties.stepTitle', 0)}`,
				time: get(click, 'properties.time', 0),
			}))
		);
	}, [guideElements, setClickOptions]);

	const sliderValueLabelFormat = (value: number) => {
		if (!screenShareNode?.current) return;
		if (!isFinite(value)) return '00.00';
		const sliderTime = (value / 100) * duration;
		return timeFormatterSlider(sliderTime);
	};

	const onChangeTime = useCallback(() => {
		onVideoTimeChange?.(screenShareNode.current?.currentTime || 0);
		setCurrentTimeSeconds(screenShareNode.current?.currentTime || 0);
	}, [screenShareNode.current, onVideoTimeChange]);

	const onTrimProgressHandler = useCallback(
		(event: React.MouseEvent) => {
			return;
			const target = document.getElementById('floikScreenCaptureProgressBar');
			// @ts-ignore
			const { width } = target.getBoundingClientRect();
			const clientX = get(event, 'nativeEvent.offsetX', 0);
			if (!screenShareNode?.current) return;
			let newDuration = screenShareNode?.current.duration;
			const ratio = clientX / width || 0;
			const newCurrentTime = ratio * newDuration || 0;
			if (isFinite(newCurrentTime))
				screenShareNode.current.currentTime = newCurrentTime || 0;
		},
		[screenShareNode?.current]
	);

	const onInputChange = useCallback(
		// debounce(
		(event) => {
			const numString = get(event, 'target.value');
			// Handle empty input and prevent exceeding bounds
			const num = parseInt(numString, 10) || 0;
			if (num >= MILLISECONDS_INPUT_MIN && num <= MILLISECONDS_INPUT_MAX) {
				setMilliseconds(num);
				screenShareNode.current.currentTime = Number(
					`${get(initialTimeArray, 'current[0]', 0)}.${String(num).padStart(3, '0')}`
				);
			}
		},
		// 100),
		[screenShareNode.current, initialTimeArray]
	);

	const onChangeCallback = useCallback(
		(event: Event, _value: number, activeThumb: number) => {
			const num = _value || 0;
			if (num >= MILLISECONDS_INPUT_MIN && num <= MILLISECONDS_INPUT_MAX) {
				setMilliseconds(num);
				screenShareNode.current.currentTime = Number(
					`${get(initialTimeArray, 'current[0]', 0)}.${String(num).padStart(3, '0')}`
				);
			}
		},
		[screenShareNode.current, initialTimeArray]
	);

	const onUpdateMilliSeconds = useCallback(
		(increment: boolean, multiplier: number) => {
			let num = milliseconds || 0;
			const updateValue = multiplier * Translations.GALLERY_MILLISECONDS_MULTIPLIER;
			if (increment) {
				num = min([MILLISECONDS_INPUT_MAX, num + updateValue]);
			} else {
				num = max([MILLISECONDS_INPUT_MIN, num - updateValue]);
			}
			if (num >= MILLISECONDS_INPUT_MIN && num <= MILLISECONDS_INPUT_MAX) {
				setMilliseconds(num);
				screenShareNode.current.currentTime = Number(
					`${get(initialTimeArray, 'current[0]', 0)}.${String(num).padStart(3, '0')}`
				);
			}
		},
		[milliseconds, screenShareNode.current, initialTimeArray, setMilliseconds]
	);

	const setPlayerDimCallback = useCallback(
		(data: {
			height: number;
			width: number;
			offsetWidth: number;
			offsetHeight: number;
		}) => {
			setPlayerDim(data);
		},
		[setPlayerDim]
	);

	const onPause = useCallback(() => {
		if (screenShareNode.current && !screenShareNode.current.paused)
			screenShareNode.current.pause();
	}, [screenShareNode.current]);

	const onTimeSliderChange = useCallback(
		debounce((value: number) => {
			screenShareNode.current.currentTime = value;
			setTimeSliderValue(timeFormatter(value, true));
		}, 100),
		[screenShareNode?.current, duration, onPause]
	);

	const onPlay = useCallback(() => {
		screenShareNode.current?.play().catch(console.warn);
	}, [screenShareNode.current]);

	const onUpdate = useCallback(() => {
		if (screenShareNode?.current) {
			// @ts-ignore
			const paused = screenShareNode?.current?.paused;
			if (screenShareNode.current) {
				if (!paused) {
					onPlay();
				} else {
					onPause();
				}
			}
		}
		// @ts-ignore
	}, [onPlay, onPause, screenShareNode?.current?.paused]);

	const onPlayPause = useCallback(() => {
		if (screenShareNode.current?.paused) {
			screenShareNode.current?.play().catch(console.warn);
		} else {
			screenShareNode.current?.pause();
		}
	}, [screenShareNode.current]);

	const onSetReadyCallback = useCallback(
		(val: boolean) => {
			setReady(val);
			setVideoReady?.(val);
			if (val && screenShareNode.current) {
				screenShareNode.current.currentTime = videoDefaultTime || 0;
			}
		},
		[setReady, setVideoReady, videoDefaultTime, screenShareNode?.current]
	);

	useEffect(() => {
		if (videoReady) {
			setVideoSize({
				width: get(screenShareNode, 'current.videoWidth'),
				height: get(screenShareNode, 'current.videoHeight'),
			});
		} else {
			setVideoSize(null);
		}
	}, [videoReady, screenShareNode, setVideoSize]);

	const onJumpToStepSelect = useCallback(
		(value) => {
			if (screenShareNode.current) {
				screenShareNode.current.currentTime = value;
			}
			onChangeTime();
		},
		[onChangeTime, screenShareNode.current]
	);

	const hasScreen = !!get(screenShareTrack, 'sources[0].src');
	const mainPlayerOption = hasScreen ? screenShareTrack : videoTrack;

	let videoSizeObj = videoSize || {
		width:
			get(screenShareTrack, 'sources[0].width') || get(videoTrack, 'sources[0].width'),
		height:
			get(screenShareTrack, 'sources[0].height') || get(videoTrack, 'sources[0].height'),
	};

	const onPopoverClose = useCallback(() => {
		screenShareNode.current.currentTime = Number(
			`${get(initialTimeArray, 'current[0]', 0)}.${get(
				initialTimeArray,
				'current[1]',
				0
			)}`
		);
		setAnchorEl(null);
	}, [setAnchorEl, initialTimeArray.current, screenShareNode.current]);

	const onOpen = useCallback(
		(event: React.MouseEvent) => {
			const currentTime = screenShareNode?.current?.currentTime || 0;
			const splitTime = `${currentTime}`.split('.');
			initialTimeArray.current = splitTime;
			setMilliseconds(Number(splitTime[1].padEnd(3, '0')) || 0);
			setAnchorEl(iconButton || get(event, 'target'));
		},
		[setAnchorEl, iconButton]
	);

	const onPopoverCancel = useCallback(() => {
		onPopoverClose();
	}, [onPopoverClose]);

	const onPopoverConfirm = useCallback(() => {
		const newValue = Number(`${get(initialTimeArray, 'current[0]', 0)}.${String(milliseconds).padStart(3, '0')}`);
		if (screenShareNode.current) {
			screenShareNode.current.currentTime = newValue;
		}
		setTimeSliderValue(newValue);
		onChangeTime();
		setAnchorEl(null);
	}, [initialTimeArray.current, screenShareNode.current, onChangeTime, milliseconds]);

	const open = Boolean(anchorEl);

	console.log('showMillisecondsInput', open);

	return (
		<div className={styles.wrapper}>
			<div className={styles.videoContainer} ref={containerRef}>
				<Video
					// hasAudioTrack={hasAudioTrack}
					//@ts-ignore
					// setPlayerRef={setPlayerRef}
					//@ts-ignore
					nodeRef={screenShareNode}
					setPlayerRef={setVideoPlayerRef}
					addWrapper
					hideProgressBar
					offsetHeight={playerDim.offsetHeight || 0}
					offsetWidth={containerRef?.current?.offsetWidth || 0}
					fullHeight
					useVideoSizeForFit
					// mute={voiceOverMode === NO_VOICE_KEY}
					// volume={volume}
					setError={setError}
					// isAudioWaveAvailable={isAudioWaveAvailable}
					// onPlaybackRatesChange={onPlaybackRatesChange}
					containerRef={containerRef}
					options={mainPlayerOption}
					className="screenShareTrack"
					setPlayerDim={setPlayerDimCallback}
					// onPlay={onPlay}
					// onPause={onPause}
					setVideoReady={setReady}
					setReady={onSetReadyCallback}
					// offsetWidth={leftSeekPosition || 0}
					duration={duration}
					onUpdate={onUpdate}
					// onSeeked={onSeeked}
					// onSeeking={onSeeking}
					// onVolumeChange={onVolumeChange}
					onChangeTime={onChangeTime}
					videoSize={videoSizeObj}
				/>
			</div>
			<div className={styles.controlsWrapper}>
				<IconButton
					onClick={onPlayPause}
					disabled={!videoReady}
					className={classNames(styles.playButton, { [styles.disabled]: !videoReady })}
					disableRipple
				>
					{screenShareNode.current?.paused ? (
						<IconPlayerPlayFilled size={18} />
					) : (
						<IconPlayerPauseFilled size={18} />
					)}
				</IconButton>
				<div
					className={styles.timeSliderWrapper}
					onClick={onTrimProgressHandler}
					id={'floikScreenCaptureProgressBar'}
				>
					<TimeSlider
						time={timeSliderValue || ''}
						onChange={onTimeSliderChange}
						enableMilliseconds
						valueLabelFormat={sliderValueLabelFormat}
						currentTime={currentTimeSeconds}
						duration={duration || defaultDuration}
						classes={{
							wrapper: styles.timeSlider,
							timeValue: styles.timeSliderValue,
						}}
						setValueWithCurrentTime
					/>
				</div>
				<IconButton
					className={classNames(styles.iconButton, { [styles.disabled]: !videoReady })}
					onClick={onOpen}
					disabled={!videoReady}
					disableRipple
					//@ts-ignore
					ref={setIconButton}
				>
					<IconAdjustmentsHorizontal size={16} />
				</IconButton>
				<div className={styles.dropdownWrapper}>
					<Dropdown
						id={'jump_to_click'}
						label="Jump to"
						selected={selectedClickTime}
						labelKey="label"
						valueKey="time"
						onChange={onJumpToStepSelect}
						// @ts-ignore
						options={clickOptions}
						className={styles.dropdown}
						classes={{
							headText: styles.dropdownHeadText,
							container: styles.dropdownContainer,
							select: styles.dropdownSelect,
							menuContainer: styles.dropdownMenuContainer,
							menuItemLabel: styles.dropdownMenuItemLabel,
						}}
					/>
				</div>
			</div>
			<Popover
				anchorEl={anchorEl}
				classes={{
					paper: styles.popoverPaper,
					root: styles.popoverRoot,
				}}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={onPopoverClose}
			>
				<div className={styles.popupContainer}>
					<div className={styles.popupHeader}>
						{Translations.GALLERY_MILLISECOND_TUNE_TITLE}
					</div>
					<div className={styles.slider}>
						<Slider
							classes={{
								colorPrimary: styles.colorPrimary,
								thumbColorPrimary: styles.thumbColorPrimary,
							}}
							componentsProps={{
								rail: {
									className: styles.rail,
								},
							}}
							value={milliseconds}
							step={1}
							track="normal"
							name={'slider'}
							max={MILLISECONDS_INPUT_MAX}
							min={MILLISECONDS_INPUT_MIN}
							onChange={onChangeCallback}
						/>
					</div>
					<div className={styles.popupInputsWrapper}>
						<IconButton
							className={styles.iconButton}
							disableRipple
							onClick={() => onUpdateMilliSeconds(false, 2)}
						>
							<IconChevronsLeft size={18} />
						</IconButton>
						<IconButton
							className={styles.iconButton}
							disableRipple
							onClick={() => onUpdateMilliSeconds(false, 1)}
						>
							<IconChevronLeft size={18} />
						</IconButton>
						<div className={styles.popupInputContainer}>
							<input
								className={styles.popupInput}
								value={milliseconds}
								type="number"
								min={MILLISECONDS_INPUT_MIN}
								max={MILLISECONDS_INPUT_MAX}
								onChange={onInputChange}
							/>
							<IconClock size={16} className={styles.clockIcon} />
						</div>
						<IconButton
							className={styles.iconButton}
							disableRipple
							onClick={() => onUpdateMilliSeconds(true, 1)}
						>
							<IconChevronRight size={18} />
						</IconButton>
						<IconButton
							className={styles.iconButton}
							disableRipple
							onClick={() => onUpdateMilliSeconds(true, 2)}
						>
							<IconChevronsRight size={18} />
						</IconButton>
					</div>
					<div className={styles.buttonsWrapper}>
						<Button
							autoFocus
							disableFocusRipple
							onClick={onPopoverCancel}
							className={styles.cancelButton}
						>
							Cancel
						</Button>
						<Button
							disableFocusRipple
							variant="contained"
							className={styles.confirmButton}
							onClick={onPopoverConfirm}
							autoFocus
						>
							Confirm
						</Button>
					</div>
				</div>
			</Popover>
		</div>
	);
};

export default VideoScreenCapture;
