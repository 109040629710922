import { get, includes, map } from 'lodash';
import {
	FLO_ELEMENT_CTA_TYPE,
	FLO_ELEMENT_HOTSPOTS_TYPE,
	FLO_ELEMENT_INTERACTION_CUE_TYPE,
} from '../../Flo.constants';
import {
	elementsDataType,
	ElementVoiceResource,
	FloReducerType,
	PresenterDataType,
	PresenterSnippetDataType,
	Transcribe,
} from '../../Flo.types';

const validateTheTranscribe = (payload: Transcribe[]) => {
	const transcribe: Transcribe[] = [];
	if (payload.length > 0) {
		let currentSlot = {
			...payload[0],
			isOverlap: false,
			actualEndTime: get(payload, '[0].endTimeInMillis'),
		} as Transcribe;
		for (let i = 1; i < payload.length; i++) {
			let isOverlap = false;
			let endTime = currentSlot.endTimeInMillis;
			const nextSlot = payload[i] as Transcribe;
			if (nextSlot && nextSlot.startTimeInMillis < currentSlot.endTimeInMillis) {
				isOverlap = true;
				endTime = nextSlot.startTimeInMillis - 100;
			}
			transcribe.push({
				...currentSlot,
				isOverlap,
				actualEndTime: endTime,
			});
			currentSlot = nextSlot;
		}
		transcribe.push({
			...currentSlot,
			actualEndTime: currentSlot.endTimeInMillis,
		});
	}
	return transcribe;
};

const videoFloReducer = {
	getVoiceTranscribes(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				resourceGroupId: string;
				outputTypeId: string;
			};
		}
	) {},
	getSubtitles(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
				resourceGroupId: string;
				outputTypeId: string;
			};
		}
	) {},
	showBlurOrSpotlightElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
	showTextElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { id?: string; floId?: string };
		}
	) {},
	showCtaElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
	showHotspotElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { id?: string; floId?: string };
		}
	) {},
	showImageElements(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { id?: string; floId?: string };
		}
	) {},
	generateSubtitlesForVoice(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				floId: string;
			};
		}
	) {},
	showVoiceOver(state: FloReducerType, { payload }: { payload: boolean }) {
		state.voiceOver.show = payload;
	},
	showSubtitlesPanel(state: FloReducerType, { payload }: { payload: boolean }) {
		state.voiceOver.showSubtitles = payload;
	},
	setSubtitles(state: FloReducerType, { payload }: { payload: Transcribe[] }) {
		state.voiceOver.subtitles = validateTheTranscribe(payload);
	},
	setVoiceOverTranscribeScript(
		state: FloReducerType,
		{ payload }: { payload: Transcribe[] }
	) {
		state.voiceOver.transcribeScripts = validateTheTranscribe(payload);
	},
	addNewTranscribe(
		state: FloReducerType,
		{ payload }: { payload: { show: boolean; error: string } }
	) {
		state.voiceOver.newTranscribe = payload.show;
		state.voiceOver.newTranscribeError = payload.error;
		state.voiceOver.newTranscribePhrases = [];
		state.voiceOver.newTranscribePhrasesLoading = false;
	},

	setVoiceOverSubtitleStatus(
		state: FloReducerType,
		{ payload }: { payload: 'loading' | 'YET_TO_START' | 'error' | 'ready' | 'outdated' }
	) {
		state.voiceOver.transcribeStatus = payload;
	},

	setVoiceOverSubtitleError(state: FloReducerType, { payload }: { payload: string }) {
		state.voiceOver.subtitleError = payload;
	},

	setVoiceOverTranscribeError(state: FloReducerType, { payload }: { payload: string }) {
		state.voiceOver.error = payload;
	},

	setVoiceOverVoiceStatus(
		state: FloReducerType,
		{ payload }: { payload: 'loading' | 'YET_TO_START' | 'error' | 'ready' | 'outdated' }
	) {
		state.voiceOver.voiceStatus = payload;
	},
	updateVoiceOverTranscribe(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: Transcribe;
			};
		}
	) {
		state.voiceOver.subtitles = map(state.voiceOver.subtitles, (transcribe) =>
			payload.id === transcribe.id ? payload.data : transcribe
		);
	},
	rephraseVoiceOverTranscribe(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: string[];
			};
		}
	) {
		if (!payload.id) {
			state.voiceOver.newTranscribePhrasesLoading = true;
		} else {
			state.voiceOver.transcribeScripts = map(
				state.voiceOver.transcribeScripts,
				(transcribe) =>
					payload.id === transcribe.id
						? {
								...transcribe,
								phrasesLoading: true,
						  }
						: transcribe
			);
		}
	},
	rephraseVoiceOverTranscribeError(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
			};
		}
	) {
		if (!payload.id) {
			state.voiceOver.newTranscribePhrasesLoading = false;
		} else {
			state.voiceOver.transcribeScripts = map(
				state.voiceOver.transcribeScripts,
				(transcribe) =>
					payload.id === transcribe.id
						? {
								...transcribe,
								phrasesLoading: false,
						  }
						: transcribe
			);
		}
	},
	setRephraseVoiceOverTranscribe(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: string[];
			};
		}
	) {
		if (!payload.id) {
			state.voiceOver.newTranscribePhrasesLoading = false;
			state.voiceOver.newTranscribePhrases = payload.data;
		} else {
			state.voiceOver.transcribeScripts = map(
				state.voiceOver.transcribeScripts,
				(transcribe) =>
					payload.id === transcribe.id
						? {
								...transcribe,
								phrasesLoading: false,
								phrases: payload.data,
						  }
						: transcribe
			);
		}
	},
	toggleEnableVoiceOverTranscribe(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: Transcribe;
			};
		}
	) {
		state.voiceOver.subtitles = map(state.voiceOver.subtitles, (transcribe) =>
			payload.id === transcribe.id ? payload.data : transcribe
		);
	},
	toggleVoiceTranscribes(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: Transcribe;
			};
		}
	) {},
	toggleVoiceTranscribesSuccess(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: Transcribe;
			};
		}
	) {
		state.voiceOver.subtitles = map(state.voiceOver.subtitles, (transcribe) =>
			payload.id === transcribe.id ? payload.data : transcribe
		);
	},
	updateVoiceOverTranscribeError(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				data: Transcribe;
			};
		}
	) {
		state.voiceOver.transcribeScripts = map(
			state.voiceOver.transcribeScripts,
			(transcribe) => (payload.id === transcribe.id ? payload.data : transcribe)
		);
		state.voiceOver.subtitles = map(state.voiceOver.subtitles, (transcribe) =>
			payload.id === transcribe.id ? payload.data : transcribe
		);
	},
	setVoiceOverVTT(state: FloReducerType, { payload }: { payload: string }) {
		state.voiceOver.vttContent = payload;
	},
	resetTranscribe(state: FloReducerType, { payload }: { payload: string }) {},

	deleteTranscribe(state: FloReducerType, { payload }: { payload: string }) {},
	addHotspot(state: FloReducerType, { payload }: { payload: string }) {
		if (state.activeEditElement) {
			state.activeEditElement.show = false;
			state.activeEditElement.type = FLO_ELEMENT_HOTSPOTS_TYPE;
		}
	},
	getElementVoiceResource(state: FloReducerType, { payload }: { payload: string }) {},
	setElementVoiceResource(
		state: FloReducerType,
		{ payload }: { payload: { [key: string]: ElementVoiceResource } }
	) {
		// state.editorsElementsConfig = map(state.editorsElementsConfig, (editorElement) => {
		// 	if(get(editorElement, 'properties.voiceConfig.scriptId')) {
		// 		const key = `${get(editorElement, 'properties.voiceConfig.scriptId')}_${get(editorElement, 'properties.voiceConfig.voiceId')}`;
		//
		// 	}
		// 	return editorElement;
		// });
		state.elementVoiceResourceMap = payload;
	},
	generateVoiceForElement(
		state: FloReducerType,
		{ payload }: { payload: { [key: string]: any } }
	) {
		if (
			includes(
				[FLO_ELEMENT_INTERACTION_CUE_TYPE, FLO_ELEMENT_CTA_TYPE],
				get(state, 'activeEditElement.type')
			)
		) {
			state.activeEditElement.element.properties.values.voiceConfig = {
				...get(state, 'activeEditElement.element.properties.values.voiceConfig', {}),
				resourceDetails: { status: 'loading' },
			};
		} else if (get(state, 'activeEditElement.tempValues')) {
			// @ts-ignore
			state.activeEditElement.tempValues.voiceConfig = {
				...get(state, 'activeEditElement.tempValues.voiceConfig', {}),
				resourceDetails: { status: 'loading' },
			};
		}
	},
	generateVoiceForElementError(state: FloReducerType) {
		if (
			includes(
				[FLO_ELEMENT_INTERACTION_CUE_TYPE, FLO_ELEMENT_CTA_TYPE],
				get(state, 'activeEditElement.type')
			)
		) {
			state.activeEditElement.element.properties.values.voiceConfig = {
				...get(state, 'activeEditElement.element.properties.values.voiceConfig', {}),
				resourceDetails: { status: 'error' },
			};
		} else if (get(state, 'activeEditElement.tempValues')) {
			// @ts-ignore
			state.activeEditElement.tempValues.voiceConfig = {
				...get(state, 'activeEditElement.tempValues.voiceConfig', {}),
				resourceDetails: { status: 'error' },
			};
		}
	},
	generateVoiceForElementSuccess(
		state: FloReducerType,
		{ payload }: { payload: { scriptId: string } }
	) {
		if (
			includes(
				[FLO_ELEMENT_INTERACTION_CUE_TYPE, FLO_ELEMENT_CTA_TYPE],
				get(state, 'activeEditElement.type')
			)
		) {
			const currentConfig = get(
				state,
				'activeEditElement.element.properties.values.voiceConfig',
				{}
			);
			state.elementVoiceResourceMap = {
				...state.elementVoiceResourceMap,
				[`${payload.scriptId}_${currentConfig.voiceId}`]: payload.resourceDetails,
			};
			state.activeEditElement.element.properties.values.voiceConfig = {
				...currentConfig,
				...payload,
			};
		} else if (get(state, 'activeEditElement.tempValues')) {
			const currentConfig = get(state, 'activeEditElement.tempValues.voiceConfig', {});
			state.elementVoiceResourceMap = {
				...state.elementVoiceResourceMap,
				[`${payload.scriptId}_${currentConfig.voiceId}`]: payload.resourceDetails,
			};
			// @ts-ignore
			state.activeEditElement.tempValues.voiceConfig = {
				...currentConfig,
				...payload,
			};
		}
		state.activeEditElement.enableSave = true;
	},
	setElementVoiceResourceStatus(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: 'loading' | 'YET_TO_START' | 'error' | 'ready' | 'outdated';
		}
	) {
		state.elementVoiceResourceStatus = payload;
	},
	deleteTranscribeSuccess(
		state: FloReducerType,
		{ payload }: { payload: { id: string } }
	) {
		const transcribes = validateTheTranscribe(
			state.voiceOver.transcribeScripts.filter(
				(transcribe) => transcribe.id !== payload.id
			)
		);
		state.voiceOver.transcribeScripts = transcribes;
	},
	onInteractionItemClick(
		state: FloReducerType,
		{ payload }: { payload?: elementsDataType }
	) {
		state.selectedElement = payload;
	},
	availableCacheEntries(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				[key: string]: boolean;
			};
		}
	) {
		state.voiceOver.cachedItems = payload;
	},
	getPresenterData(state: FloReducerType) {},
	getPresenterPreviewData(
		state: FloReducerType,
		{ payload }: { payload: { id: string } }
	) {},
	savePresenterVideoBackground(
		state: FloReducerType,
		{ payload }: { payload: { id: string } }
	) {},
	setPresenterPreviewData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				videoUrl?: string;
				imageId?: string;
				imageName?: string;
				status?: string;
			};
		}
	) {
		state.presenterPreview = payload;
	},
	updatePresenterPreviewData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				videoUrl?: string;
				imageId?: string;
				imageName?: string;
				status?: string;
			};
		}
	) {
		state.presenterPreview = {
			...(state.presenterPreview || {}),
			...payload,
		};
	},
	togglePresenterVideo(state: FloReducerType, { payload }: { payload: boolean }) {},
	setEnablePresenterVideo(state: FloReducerType, { payload }: { payload: boolean }) {
		state.presenter.enable = payload;
	},
	setBGMusicData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				enabled: boolean;
				audioUrl?: string;
				volumeLevel?: number;
				bgmId?: string;
				fadeInFadeOut?: boolean;
				pauseOnInteraction?: boolean;
				fileType?: string;
			};
		}
	) {
		state.bgMusic = payload;
	},
	saveBGMusic(state: FloReducerType, { payload }: { payload: { id: string } }) {},
	saveText(state: FloReducerType, { payload }: { payload: { id: string } }) {},
	setPresenterData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: PresenterDataType;
		}
	) {
		state.presenter = payload;
	},
	updatePresenterData(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: PresenterDataType;
		}
	) {
		state.presenter = { ...state.presenter, ...payload };
	},
	setPreviewSnippets(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: PresenterSnippetDataType[];
		}
	) {
		state.presenterSnippets = payload;
	},
	showPresenterBackground(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
	showBGMusic(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {},
};

export default videoFloReducer;
