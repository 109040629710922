import { createSlice } from '@reduxjs/toolkit';
import { get, set } from 'lodash';
import { ReactNode } from 'react';
import uniqId from 'uniqid';
import { FloPagination } from '../../Pages/ManageFlos/ManageFlos.types';

export type GalleryImgType = {
	cloudFrontUrl: string;
	dataUrl?: string;
	id: string;
	extension?: string;
	name: string;
	fileSizeInBytes: string;
	uploadStatus?: string;
	meta?: {
		width: number;
		height: number;
	};
	category?: string;
	manuallySetFrameTime?: number;
};

export type Category = {
	name: string;
	label: string;
};

export interface ImageGalleryReducerType {
	config: {
		visible: boolean;
		header: ReactNode;
		id?: string;
		confirmButtonText: string;
		dialogClassName?: string;
		variant?: string;
		confirmButtonType?: string;
		cancelButtonText: string;
		onCancel?(): boolean | void;
		onConfirm?(val: GalleryImgType, category: string): boolean | void;
		headerClassName?: string;
		classes?: {
			confirmButton?: string;
			cancelButton?: string;
			imagesList?: string;
			container?: string;
		};
		subHeading: string;
		recommendedResolution?: string;
		uploadButtonText: string;
		minDimensions?: {
			width: number;
			height: number;
		};
		showSelectFromOriginal?: boolean;
		videoDefaultTime?: number;
	};
	fileType: string;
	imagesList: {
		[category: string]: GalleryImgType[];
	};
	categories: Category[];
	pagination: FloPagination;
	errors: { [key: string]: boolean };
}

const initialState = Object.freeze({
	imagesList: {
		uploaded: [],
	},
	config: {
		visible: false,
		header: 'Select image',
		id: '',
		onCancel: undefined,
		onConfirm: undefined,
		subHeading: 'GALLERY_SUBHEADING',
		cancelButtonText: 'GALLERY_CANCEL_BUTTON_TEXT',
		confirmButtonText: 'GALLERY_CONFIRM_BUTTON_TEXT',
		uploadButtonText: 'GALLERY_UPLOAD_BUTTON_TEXT',
	},
	fileType: 'image',
	pagination: {
		hasMore: false,
		pageNo: 1,
		pageSize: 30,
		totalRecords: 0,
	},
	errors: {},
	categories: [],
});

const imageGalleryReducers = {
	showFileGallery(
		state: ImageGalleryReducerType,
		{
			payload,
		}: { payload: Partial<ImageGalleryReducerType> & { fileType: 'image' | 'audio' } }
	) {
		return {
			...initialState,
			// subHeading: Translations.GALLERY_SUBHEADING,
			// cancelButtonText: Translations.GALLERY_CANCEL_BUTTON_TEXT,
			// confirmButtonText: Translations.GALLERY_CONFIRM_BUTTON_TEXT,
			// uploadButtonText: Translations.GALLERY_UPLOAD_BUTTON_TEXT,
			config: {
				...initialState.config,
				...payload,
				visible: true,
			},
			fileType: payload.fileType || initialState.fileType,
		};
	},
	hideFileGallery(state: ImageGalleryReducerType) {
		return {
			...initialState,
			// subHeading: Translations.GALLERY_SUBHEADING,
			// cancelButtonText: Translations.GALLERY_CANCEL_BUTTON_TEXT,
			// confirmButtonText: Translations.GALLERY_CONFIRM_BUTTON_TEXT,
			// uploadButtonText: Translations.GALLERY_UPLOAD_BUTTON_TEXT,
		};
	},
	uploadGalleryFile(state: ImageGalleryReducerType, { payload }: { payload: any }) {
		return set(state, `imagesList.${payload.category}`, [
			{ uploadStatus: 'IN_PROGRESS', id: uniqId() },
			...get(state, `imagesList.${payload.category}`),
		]);
	},
	uploadGalleryFileError(state: ImageGalleryReducerType, { payload }: { payload: any }) {
		return set(
			state,
			`imagesList.${payload.category}`,
			get(state, `imagesList.${payload.category}`).slice(1)
		);
	},
	uploadGalleryFileSuccess(
		state: ImageGalleryReducerType,
		{ payload }: { payload: GalleryImgType }
	): ImageGalleryReducerType {
		state = set(state, `imagesList.${payload.category}`, [
			payload,
			...get(state, `imagesList.${payload.category}`).slice(1),
		]);
		state = set(
			state,
			'pagination.totalRecords',
			get(state, 'pagination.totalRecords') + 1
		);
		return state;
	},
	getGalleryFiles() {},
	getGalleryFilesSuccess(
		state: ImageGalleryReducerType,
		{
			payload,
		}: {
			payload: {
				data: GalleryImgType[];
				pageNo?: number;
				pagination: FloPagination;
				category: string;
			};
		}
	): ImageGalleryReducerType {
		state = set(state, 'pagination', payload.pagination);
		state = set(state, 'errors.getGalleryImages', false);
		const category = payload.category;
		if (payload.pageNo === 1) {
			return set(state, `imagesList.${category}`, payload.data);
		}
		return set(state, `imagesList.${category}`, [
			...get(state, `imagesList.${category}`),
			...payload.data,
		]);
	},
	getGalleryFilesError(
		state: ImageGalleryReducerType,
		{ payload }: { payload: { pageNo?: number } }
	): ImageGalleryReducerType {
		if (payload.pageNo === 1) {
			state = set(state, 'errors.getGalleryImages', true);
		}
		return state;
	},
	getGallerySystemFiles() {},
	getGallerySystemFilesSuccess(
		state: ImageGalleryReducerType,
		{
			payload,
		}: {
			payload: {
				data: GalleryImgType[];
				pageNo?: number;
				pagination: FloPagination;
				category: string;
			};
		}
	): ImageGalleryReducerType {
		state = set(state, 'pagination', payload.pagination);
		state = set(state, 'errors.getGalleryImages', false);
		const category = payload.category;
		if (payload.pageNo === 1) {
			return set(state, `imagesList.${category}`, payload.data);
		}
		return set(state, `imagesList.${category}`, [
			...get(state, `imagesList.${category}`),
			...payload.data,
		]);
	},
	getGallerySystemFilesError(
		state: ImageGalleryReducerType,
		{ payload }: { payload: { pageNo?: number } }
	): ImageGalleryReducerType {
		if (payload.pageNo === 1) {
			state = set(state, 'errors.getGalleryImages', true);
		}
		return state;
	},
	getGalleryCategories() {},
	getGalleryCategoriesSuccess(
		state: ImageGalleryReducerType,
		{
			payload,
		}: {
			payload: {
				data: Category[];
				fileType: string;
			};
		}
	): ImageGalleryReducerType {
		state = set(state, 'errors.getGalleryImages', false);
		state = set(state, 'categories', payload.data);
		payload.data.forEach((category) => {
			if (!state.imagesList[category.name]) {
				state = set(state, `imagesList.${category.name}`, []);
			}
		});
		state = set(state, 'categories', payload.data);
		return state;
	},
	getGalleryCategoriesError(
		state: ImageGalleryReducerType,
		{ payload }: { payload: { pageNo?: number } }
	): ImageGalleryReducerType {
		if (payload.pageNo === 1) {
			state = set(state, 'errors.getGalleryImages', true);
		}
		return state;
	},
};

export const imageGalleryReducer = createSlice({
	name: 'imageGallery',
	initialState,
	// @ts-ignore
	reducers: imageGalleryReducers,
});

export default imageGalleryReducer.reducer;

export const showFileGalleryAction = imageGalleryReducer.actions.showFileGallery;
export const hideFileGalleryAction = imageGalleryReducer.actions.hideFileGallery;
export const uploadGalleryFileAction = imageGalleryReducer.actions.uploadGalleryFile;
export const uploadGalleryFileErrorAction =
	imageGalleryReducer.actions.uploadGalleryFileError;
export const uploadGalleryFileSuccessAction =
	imageGalleryReducer.actions.uploadGalleryFileSuccess;
export const getGalleryFilesSuccessAction =
	imageGalleryReducer.actions.getGalleryFilesSuccess;
export const getGalleryFilesErrorAction =
	imageGalleryReducer.actions.getGalleryFilesError;
export const getGalleryFilesAction = imageGalleryReducer.actions.getGalleryFiles;
export const getGallerySystemFilesAction =
	imageGalleryReducer.actions.getGallerySystemFiles;
export const getGallerySystemFilesSuccessAction =
	imageGalleryReducer.actions.getGallerySystemFilesSuccess;
export const getGallerySystemFilesErrorAction =
	imageGalleryReducer.actions.getGallerySystemFilesError;
export const getGalleryCategoriesAction =
	imageGalleryReducer.actions.getGalleryCategories;
export const getGalleryCategoriesSuccessAction =
	imageGalleryReducer.actions.getGalleryCategoriesSuccess;
export const getGalleryCategoriesErrorAction =
	imageGalleryReducer.actions.getGalleryCategoriesError;
