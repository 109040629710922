import { get, isArray, merge, reject, replace, set } from 'lodash';
import uniqId from 'uniqid';
import { ActiveEditElement, FloReducerType } from '../../Flo.types';

export const initialInteractiveElementObject = {
	show: false,
	type: '',
	hideDelete: false,
	element: null,
	enableSave: false,
	hideFooter: false,
	showBackButton: false,
	useFooterButtonAsDiscard: false,
	config: null,
	editingId: null,
	errors: {},
	tempValues: null,
	enableTimeSlider: false,
	timeSliderProperty: '',
};

const InteractiveCueReducer = {
	toggleInteractiveWithCta(state: FloReducerType, { payload }: { payload: string }) {},
	toggleInteractiveEditor(
		state: FloReducerType,
		{ payload }: { payload: ActiveEditElement }
	) {
		state.prevActiveEditElement = state.activeEditElement;

		state.activeEditElement = payload;
	},
	clearPrevEditorElement(state: FloReducerType, { payload }: { payload: unknown }) {
		state.prevActiveEditElement = null;
	},
	showInteractiveCueEditor(
		state: FloReducerType,
		{ payload }: { payload: ActiveEditElement }
	) {
		if (payload.show === false) {
			state.activeEditElement = initialInteractiveElementObject;
		} else {
			state.activeEditElement = payload;
		}
	},
	enableSaveInteractiveCueEditor(
		state: FloReducerType,
		{ payload }: { payload: boolean }
	) {
		if (state.activeEditElement) state.activeEditElement.enableSave = payload;
	},
	toggleInteractiveCueEditorFooter(
		state: FloReducerType,
		{ payload }: { payload: boolean }
	) {
		if (state.activeEditElement) state.activeEditElement.hideFooter = payload;
	},
	setEditingId(
		state: FloReducerType,
		{
			payload,
		}: {
			payload?: {
				id: string;
				value: unknown;
				index?: number;
				enableTimeSlider?: boolean;
				timeSliderProperty?: string;
			};
		}
	) {
		if (state.activeEditElement) {
			state.activeEditElement.addNew = false;
			state.activeEditElement.editingId = get(payload, 'id');
			state.activeEditElement.tempValues = get(payload, 'value');
			state.activeEditElement.index = get(payload, 'index');
			state.activeEditElement.enableTimeSlider = get(payload, 'enableTimeSlider', false);
			state.activeEditElement.timeSliderProperty = get(payload, 'timeSliderProperty', '');
		}
	},
	setTempValue(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: unknown;
		}
	) {
		if (state.activeEditElement) {
			state.activeEditElement.tempValues = payload;
		}
	},
	updateTempValue(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				key: string;
				value: unknown;
			};
		}
	) {
		if (state.activeEditElement) {
			state.activeEditElement.tempValues[payload.key] = payload.value;
		}
	},
	setTimeSliderValue(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: string;
		}
	) {
		if (state.activeEditElement?.tempValues) {
			state.activeEditElement.tempValues[state.activeEditElement.timeSliderProperty] =
				payload;
		}
	},
	saveImage(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { floId: string };
		}
	) {},
	resetStepImage(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { floId: string };
		}
	) {},
	addButtonToActiveEditElement(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				name?: string;
				value?: string | number | { [key: string]: string | undefined };
			};
		}
	) {
		// @ts-ignore
		state.activeEditElement.element = set(
			// @ts-ignore
			state.activeEditElement.element,
			`properties.values['${payload.name}']`,
			// @ts-ignore
			get(state.activeEditElement.element, `properties.values['${payload.name}']`) || []
		);
		if (
			isArray(
				get(state.activeEditElement, `element.properties.values['${payload.name}']`)
			)
		) {
			// @ts-ignore
			state.activeEditElement.element.properties.values[payload.name].push(
				payload.value || {
					uId: uniqId(),
				}
			);
		}
	},
	removeButtonFromActiveEditElement(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				index: number;
				name: string;
			};
		}
	) {
		if (
			isArray(
				// @ts-ignore
				get(state.activeEditElement, `element.properties.values['${payload.name}']`)
			)
		) {
			// @ts-ignore
			state.activeEditElement.element.properties.values[payload.name] = reject(
				// @ts-ignore
				state.activeEditElement.element.properties.values[payload.name],
				(a, index: number) => index === payload.index
			);
		}
	},
	updateEditorValue(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				value: string;
				parentPath?: string;
			};
		}
	) {
		const path = (payload.parentPath || '') + (payload.name || '');
		// @ts-ignore
		state.activeEditElement.element = set(
			// @ts-ignore
			state.activeEditElement.element,
			`properties.values.${path}`,
			payload.value
		);
	},
	setEditorError(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				id: string;
				message: string;
			};
		}
	) {
		// @ts-ignore
		state.activeEditElement.errors = state.activeEditElement.errors || {};
		// @ts-ignore
		state.activeEditElement.errors[get(payload, 'id', '')] = get(payload, 'message');
	},
	mergeEditorValue(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				name: string;
				value: string;
				parentPath?: string;
			};
		}
	) {
		const path = replace((payload.parentPath || '') + (payload.name || ''), /\.$/, '');

		const obj = merge(
			// @ts-ignore
			get(state.activeEditElement.element, `properties.values.${path}`),
			payload.value
		);
		// @ts-ignore
		state.activeEditElement.element = set(
			// @ts-ignore
			state.activeEditElement.element,
			`properties.values.${path}`,
			obj
		);
	},
	rearrangeHotspot(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: {
				from: number;
				to: number;
				fromId: string;
				toid: string;
				fromTime: string;
				toTime: string;
			};
		}
	) {},
	saveBlur(
		state: FloReducerType,
		{
			payload,
		}: {
			payload: { floId: string };
		}
	) {},
};

export default InteractiveCueReducer;
